import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Collapse, Fab, Snackbar, useMediaQuery, Zoom } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { compose } from "ramda";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { AppRouter, AppRouters } from "../../config/AppRouter/AppRouters.menu";
import { NavigateType } from "../../config/AppRouter/NavigateState";
import { useAlert } from "../../contexts/alert.context";
import { useAuthoize } from "../../contexts/authorize.context";
import { ColorModeContext } from "../../contexts/color.context";
import { useScreen } from "../../contexts/screen.context";
import Authenticate from "../Authenticate/Authenticate";
import Alert from "../Common/Alert/Alert";
import AppBar from "../Common/AppBar";
import Avatar from "../Common/Avatar/Avatar";
import Drawer from "../Common/Drawer/Drawer";
import DrawerHeader from "../Common/Drawer/DrawerHeader";
import Link from "../Common/Link";
import ListItemButton from "../Common/ListItemButton";
import ListItemIcon from "../Common/ListItemIcon";
import ListItemText from "../Common/ListItemText";
import ThumbProfile from "../Common/Profile/ThumbProfile";
import ProtectedComponent from "../Common/ProtectedComponent/ProtectedComponent";
import withAuthorized from "../HoC/withAuthorized";
import MobileMenu from "./MobileMenu/MobileMenu";
import tempImg from "./tempImg";

const OutletBodyBoxStyled = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    paddingTop: "64px",
    // [theme.breakpoints.down("sm")]: {
    //     paddingTop: "56px",
    // },
}));

export type TemplateOption = {
    open: boolean;
    loader: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

const Template = () => {
    const theme = useTheme();
    const { permissions, userProfile } = useAuthoize();
    const { screen } = useScreen();
    const { alert, setAlert } = useAlert();
    const isSM = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

    const [collapseUserManagementOpen, setCollapseUserManagementOpen] = React.useState(false);
    const [collapseE2EFunctionOpen, setCollapseE2EFunctionOpen] = React.useState(false);

    const colorMode = React.useContext(ColorModeContext);
    useMemo(() => console.debug({ colorMode }), [colorMode]);

    const [templateOption, setTemplateOption] = React.useState<TemplateOption>({
        open: isSM ? false : true,
        loader: useState<boolean>(false),
    });
    useEffect(() => {
        setTemplateOption((prev: TemplateOption) => ({ ...prev, open: !isSM }));
        setCollapseUserManagementOpen(isSM);
        setCollapseE2EFunctionOpen(isSM);
    }, [isSM]);

    const transitionDurationMemo = useMemo(
        () => ({
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen,
        }),
        [theme]
    );

    const handleDrawerChange = useCallback(() => setTemplateOption((p) => ({ ...p, open: !p.open })), []);

    const handleUserManagementChange = useCallback(() => setCollapseUserManagementOpen((p) => !p), []);

    const handleE2EFunctionChange = useCallback(() => setCollapseE2EFunctionOpen((p) => !p), []);

    const filterAppRouterType =
        (t: NavigateType) =>
        ({ type }: AppRouter) =>
            type === "management";

    const MenuList = useMemo(
        () => (
            <Fragment>
                <ProtectedComponent
                    permissions={AppRouters.filter(filterAppRouterType("management")).reduce<Array<string>>(
                        (prev, current) => {
                            if (!current.permissions) return prev;
                            if (current.displayOnMenu) {
                                if (Array.isArray(current.permissions)) prev.push(...current.permissions);
                                else prev.push(current.permissions);
                            }
                            return prev;
                        },
                        []
                    )}
                >
                    <List>
                        <ListItemButton onClick={handleUserManagementChange} disabled={isSM}>
                            <ListItemIcon>
                                <ManageAccountsIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText open={templateOption.open} primary="Management" data-testid="test-nav-mgt" />
                            {!isSM &&
                                (templateOption.open && collapseUserManagementOpen ? <ExpandLess /> : <ExpandMore />)}
                        </ListItemButton>

                        <Collapse in={templateOption.open && collapseUserManagementOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {AppRouters.filter(filterAppRouterType("management")).map(
                                    (
                                        { label, to, RouterIcon, testId, displayOnMenu, permissions }: AppRouter,
                                        index: number
                                    ) =>
                                        displayOnMenu && (
                                            <ProtectedComponent permissions={permissions} key={index}>
                                                <Link
                                                    data-testid={testId}
                                                    to={to}
                                                    key={index}
                                                    onClick={() => isSM && handleDrawerChange()}
                                                >
                                                    <ListItemButton>
                                                        {RouterIcon ? (
                                                            <RouterIcon open={templateOption.open} />
                                                        ) : (
                                                            <ListItemIcon />
                                                        )}
                                                        <ListItemText open={templateOption.open} primary={label} />
                                                    </ListItemButton>
                                                </Link>
                                            </ProtectedComponent>
                                        )
                                )}
                            </List>
                        </Collapse>
                    </List>
                </ProtectedComponent>
                <List>
                    <ListItemButton onClick={handleE2EFunctionChange} disabled={isSM}>
                        <ListItemIcon>
                            <DeveloperBoardIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                            open={templateOption.open}
                            primary="E2E Function"
                            data-testid="test-nav-e2e-func"
                        />
                        {!isSM && (templateOption.open && collapseE2EFunctionOpen ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemButton>

                    <Collapse in={templateOption.open && collapseE2EFunctionOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {AppRouters.filter(({ type }: AppRouter) => type === "e2e").map(
                                (
                                    {
                                        label,
                                        to,
                                        RouterIcon,
                                        testId,
                                        displayOnMenu: displaOnMenu,
                                        permissions,
                                    }: AppRouter,
                                    index: number
                                ) =>
                                    displaOnMenu && (
                                        <ProtectedComponent permissions={permissions} key={index}>
                                            <Link
                                                data-testid={testId}
                                                to={to}
                                                key={index}
                                                onClick={() => isSM && handleDrawerChange()}
                                            >
                                                <ListItemButton>
                                                    {RouterIcon ? (
                                                        <RouterIcon open={templateOption.open} />
                                                    ) : (
                                                        <ListItemIcon />
                                                    )}
                                                    <ListItemText open={templateOption.open} primary={label} />
                                                </ListItemButton>
                                            </Link>
                                        </ProtectedComponent>
                                    )
                            )}
                        </List>
                    </Collapse>
                </List>
                <Zoom
                    in={templateOption.open && isSM}
                    timeout={transitionDurationMemo}
                    unmountOnExit
                    style={{ transitionDelay: `${templateOption.open && isSM ? transitionDurationMemo.exit : 0}ms` }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: "10%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Fab
                            sx={{
                                position: "absolute",
                            }}
                            aria-label="close"
                            color="primary"
                            onClick={() => isSM && handleDrawerChange()}
                        >
                            <CloseIcon color="secondary" />
                        </Fab>
                    </Box>
                </Zoom>
            </Fragment>
        ),
        [
            collapseE2EFunctionOpen,
            collapseUserManagementOpen,
            handleE2EFunctionChange,
            handleUserManagementChange,
            templateOption.open,
            handleDrawerChange,
            isSM,
            transitionDurationMemo,
        ]
    );

    if (!permissions) return <Authenticate />;
    return (
        <Box sx={{ display: "flex" }}>
            <Snackbar
                open={!!alert}
                autoHideDuration={2000}
                onClose={() => setAlert(undefined)}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
                <Alert severity={alert?.status} sx={{ width: "100%" }} onClose={() => setAlert(undefined)}>
                    {alert?.message}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <MobileMenu
                open={templateOption.open && isSM}
                onClose={handleDrawerChange}
                onOpen={handleDrawerChange}
                userProfile={userProfile}
            >
                {MenuList}
            </MobileMenu>
            <AppBar position="fixed" open={templateOption.open && !isSM}>
                <Toolbar style={{ display: "flex", flex: 1 }}>
                    <IconButton
                        data-testid="test-menu"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerChange}
                        edge="start"
                        sx={{
                            marginRight: "36px",
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {screen && (
                        <b>
                            {screen?.code && <span>{screen?.code}</span>} {screen?.code && <span>{screen?.name}</span>}
                        </b>
                    )}
                    {!isSM && (
                        <div style={{ display: "flex", flex: 1, justifyContent: "end" }}>
                            <p>
                                Version&nbsp;
                                <b>
                                    <small>{process.env.REACT_APP_VERSION}</small>
                                </b>
                            </p>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={templateOption.open && !isSM}>
                <DrawerHeader />
                <ThumbProfile>
                    <Avatar
                        avatar={{
                            src: userProfile?.photo || tempImg,
                            name: userProfile?.fullName!,
                            email: userProfile?.email!,
                        }}
                    />
                </ThumbProfile>
                {MenuList}
                {/* <List>
                    <ListItem>
                        <IconButton onClick={colorMode.toggleColorMode} color="inherit">
                            {theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                    </ListItem>
                </List> */}
            </Drawer>
            <OutletBodyBoxStyled component="main" sx={{ flexGrow: 1 }}>
                {/**Display Component with Outlet */}
                <Outlet context={templateOption} />
            </OutletBodyBoxStyled>
        </Box>
    );
};

export const useTemplateOption = () => {
    return useOutletContext<TemplateOption>();
};

const TemplateWrapped = compose(withAuthorized);
export default TemplateWrapped(Template);
