import { styled } from "@mui/material/styles";

import Logo from "../../../assets/logo/e2e-blank-400x100px.png";
const drawerWidth = Number(process.env.REACT_APP_DRAWER_WIDTH || 240);
const logoRatio = Number(process.env.EACT_APP_LOGO_RATIO || 0.5);
const DrawerHeaderStyled = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  backgroundColor: theme.palette.primary.main,
}));

const DrawerHeader = () => {
  return (
    <DrawerHeaderStyled>
      <img data-testid="test-logo" src={Logo} alt="logo" style={{ width: drawerWidth * logoRatio }} />
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
