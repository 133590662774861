import { Box, BoxProps, duration, Fade, styled } from "@mui/material";
import { Fragment, PropsWithChildren } from "react";
import { TemplateOption } from "../../../Template/Template";

interface BodyStyledProps extends BoxProps {
    open: boolean;
}
const BodyStyled = styled(Box)<BodyStyledProps>(({ theme }) => ({
    // marginTop: "14px",
    backgroundColor: "transparent",
    // minHeight: "100%",
    borderRadius: theme.shape.borderRadius,
    // overflowX: "unset",
}));

type BodyProps = {
    templateOption: TemplateOption;
    fullMargin?: boolean;
};

const Body = ({ templateOption, children, fullMargin }: PropsWithChildren<BodyProps>) => {
    return (
        <Fragment>
            <Fade in timeout={{ enter: 600, exit: duration.leavingScreen, appear: duration.standard }}>
                <Fade in>
                    <BodyStyled
                        open={templateOption.open}
                        sx={{ marginLeft: 4, marginRight: 4, marginBottom: 4, marginTop: fullMargin ? 4 : "unset" }}
                    >
                        {children}
                    </BodyStyled>
                </Fade>
            </Fade>
        </Fragment>
    );
};

export default Body;
