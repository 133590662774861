import { Grow, Stack, useMediaQuery, useTheme } from "@mui/material";
import { styled, SxProps, Theme } from "@mui/material/styles";
import { Property } from "csstype/index";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Status from "../Status/Status";
import { Color } from "../types";
import Typography from "../Typography/Typography";
import ActionSlideComponent from "./ActionSlide/Action";
import "./dataTable.css";
import {
    CardContentWrapped,
    CardStyled,
    CardWithStatusContainerStyled,
    CardWithStatusStyled,
    GridStyled,
    TheadTypographyStyled,
} from "./dataTable.style";
import Loading from "./Loading/Loading";
import NoData from "./NoData/NoData";
import Pagination from "./Pagination";

type Cell = {
    row: 1 | 2 | 3;
    col: 1 | 2 | 3 | 4 | 5 | 6;
};
/**
 * @type {DataIndex}
 * @param actionSlide - use with render
 * @param actionOnCard - use with callback
 */
type DataIndex<T> = keyof T | "actionSlide" | "actionOnCard" | "select";

type Render = JSX.Element | Array<JSX.Element> | string | undefined;
type JustifyContent = Property.JustifyContent;
type TextAlign = "left" | "center" | "right";
export type Column<T> = {
    dataIndex: DataIndex<T>;
    label?: React.ReactNode;
    cell?: Cell;
    render?: (t: T) => Render;
    callback?: (t: T) => void;
    width?: number | string | "auto";
    exceptMobile?: boolean;
    exceptLaptop?: boolean;
    isCardStatus?: boolean;
    cardStatusLabelRender?: (t: T) => Render;
    ellipsis?: boolean;
    justifyContent?: JustifyContent;
    textAlign?: TextAlign;
    contentFullWidth?: boolean;
};
export type Columns<T> = Array<Column<T>>;

const columnCellSorting = <T extends unknown>(columnCompareA: Column<T>, columnCompareB: Column<T>) => {
    if (!columnCompareA.cell || !columnCompareB.cell) return 0;
    if (columnCompareA.cell.row === columnCompareB.cell.row) {
        return columnCompareA.cell.col < columnCompareB.cell.col ? -1 : 1;
    }
    return columnCompareA.cell.row < columnCompareB.cell.row ? -1 : 1;
};

type PaginationOption = {
    totalRecord?: number;
    currentPage?: number;
    onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
};
type DataTableContainerProps = {};
const DataTableContainer = styled("div")<DataTableContainerProps>(() => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
}));

type DataTableProps<T> = {
    datasource: Array<T>;
    direction?: "horizontal" | "vertical";
    columns: Columns<T>;
    fixedHeader?: boolean;
    withHeader?: boolean;
    withPagination?: boolean;
    loading?: boolean;
    paginationOption?: PaginationOption;
};
const limit = Number(process.env.REACT_APP_PAGINATION_LIMIT) || 10;
const getPageCount = (totalRecord: number) => Math.abs(Math.ceil(totalRecord / limit));

const DataTable = <T extends unknown>({
    direction = "horizontal",
    datasource,
    columns,
    withHeader,
    withPagination = true,
    fixedHeader,
    loading,
    paginationOption,
}: DataTableProps<T>) => {
    const [datasourceState, setDatasourceState] = useState<Array<JSX.Element>>([]);
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    // const [datasourceTHeadBackup, setDatasourceTHead] = useState<JSX.Element>();
    const paginationCurrentPage = useMemo<number>(() => paginationOption?.currentPage || 1, [paginationOption]);
    const paginationTotalRecordMemo = useMemo<number>(() => paginationOption?.totalRecord || 0, [paginationOption]);
    const actionSlideMemo: Column<T> | undefined = useMemo(
        () => columns.find((column) => column.dataIndex === "actionSlide" && column.render),
        [columns]
    );
    const actionOnCardMemo: Column<T> | undefined = useMemo(
        () => columns.find((column) => column.dataIndex === "actionOnCard" && column.callback),
        [columns]
    );
    const isCardStatusMemo: Column<T> | undefined = useMemo(
        () => (isSM ? undefined : columns.find((column) => column.isCardStatus && column.render)),
        [columns, isSM]
    );
    const statusWithLabelMemo = useMemo(
        () => isCardStatusMemo && isCardStatusMemo.cardStatusLabelRender,
        [isCardStatusMemo]
    );
    const statusWidthMemo = useMemo(() => (statusWithLabelMemo ? "6rem" : "3rem"), [statusWithLabelMemo]);
    const cardStatusSideEffectSx: SxProps<Theme> = useMemo(
        () => (isCardStatusMemo ? { marginLeft: statusWidthMemo } : {}),
        [isCardStatusMemo, statusWidthMemo]
    );
    const onPageChangeCallback = useCallback(
        (event: React.ChangeEvent<unknown>, page: number) => {
            if (paginationOption?.onChange) {
                paginationOption?.onChange(event, page);
            }
        },
        [paginationOption]
    );
    const datasourceTheadMemo = useMemo(() => {
        type THead = Pick<Column<T>, "label" | "width" | "justifyContent" | "textAlign">;
        const thead: Array<THead> = columns
            .filter((f) => !f.isCardStatus && f.dataIndex !== "actionOnCard")
            .reduce((prev: Array<THead>, current: Column<T>) => {
                prev.push({
                    label: current.label || <></>,
                    width: current.width,
                    justifyContent: current.justifyContent,
                    textAlign: current.textAlign,
                });
                return prev;
            }, []);
        const cardStatusSideEffectSx: SxProps<Theme> = isCardStatusMemo ? { marginLeft: statusWidthMemo } : {};
        return (
            <CardStyled sx={{ marginBottom: 2, position: "relative" }} type="thead" elevation={3}>
                <CardContentWrapped type="thead" sx={cardStatusSideEffectSx}>
                    {thead.map((head: THead, columnIndex: number) => (
                        <GridStyled
                            item
                            xs={head.width ? false : true}
                            key={`thead_${columnIndex}`}
                            width={head.width}
                            type="thead"
                        >
                            <Stack sx={{ minHeight: 25 }}>
                                <Typography fontWeight="500" textAlign={head.textAlign || "unset"}>
                                    {head.label}
                                </Typography>
                            </Stack>
                        </GridStyled>
                    ))}
                </CardContentWrapped>
            </CardStyled>
        );
    }, [columns, isCardStatusMemo, statusWidthMemo]);
    const datasourceMemo: Array<JSX.Element> | undefined = useMemo(() => {
        try {
            return datasource.map((source: any, sourceIndex: number) => {
                columns.sort(columnCellSorting);
                const columnsGroup = new Map<number, Columns<T>>();
                columns.forEach((column: Column<T>) => {
                    if (!column.cell) return;
                    const row: number = column.cell.row;
                    if (columnsGroup.has(row)) {
                        const mapColumns: Columns<T> = columnsGroup.get(row) ?? [];
                        mapColumns.push(column);
                        columnsGroup.set(row, mapColumns);
                    } else {
                        const mapColumns: Columns<T> = [];
                        mapColumns.push(column);
                        columnsGroup.set(row, mapColumns);
                    }
                });
                let cardContent: Array<JSX.Element> = [];
                columnsGroup.forEach((columns: Columns<T>, row: number) => {
                    let columnsFilter = isSM ? columns.filter((column) => !column.exceptMobile) : columns;
                    columnsFilter = !isSM ? columnsFilter.filter((column) => !column.exceptLaptop) : columnsFilter;
                    cardContent.push(
                        <CardContentWrapped key={row} type="tbody" sx={cardStatusSideEffectSx}>
                            {columnsFilter
                                .filter((f) => !f.isCardStatus)
                                .map((column, columnIndex) => {
                                    const dataIndex: DataIndex<T> = column.dataIndex;
                                    const label: React.ReactNode = column.label;
                                    const render = column.render;
                                    const width: string | number | undefined = column.width;
                                    if (
                                        dataIndex === "actionOnCard" ||
                                        dataIndex === "actionSlide" ||
                                        dataIndex === "select"
                                    ) {
                                        return undefined;
                                    }
                                    if (Array.isArray(source[dataIndex]) && !render) {
                                        throw new Error(
                                            "Objects are not valid as a React child. If you meant to render a collection of children, use an array or DataTable render function instead."
                                        );
                                    }
                                    return (
                                        <GridStyled
                                            item
                                            key={columnIndex}
                                            xs={isSM ? (column.contentFullWidth ? 12 : 6) : width ? false : true}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: column.justifyContent || "unset",
                                                width,
                                            }}
                                            type="tbody"
                                        >
                                            <Stack
                                                direction={{
                                                    xs: "column",
                                                    sm: direction === "vertical" ? "row" : "column",
                                                }}
                                                sx={{
                                                    ...(column.ellipsis && {
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                    }),
                                                }}
                                                spacing={0}
                                                width={column.contentFullWidth ? "100%" : "unset"}
                                            >
                                                {(isSM || !withHeader) && label && (
                                                    <Stack sx={{ minHeight: 15, opacity: 1 }}>
                                                        <TheadTypographyStyled
                                                            variant="caption"
                                                            fontWeight="600"
                                                            textAlign={column.textAlign}
                                                        >
                                                            {label}
                                                        </TheadTypographyStyled>
                                                    </Stack>
                                                )}
                                                <Stack sx={{ minHeight: 25, justifyContent: "center" }}>
                                                    {render ? (
                                                        render(source)
                                                    ) : (
                                                        <Typography
                                                            variant="body2"
                                                            noWrap={column.ellipsis}
                                                            ellipsis={column.ellipsis}
                                                        >
                                                            {source[dataIndex]}
                                                        </Typography>
                                                    )}
                                                </Stack>
                                            </Stack>
                                        </GridStyled>
                                    );
                                })}
                        </CardContentWrapped>
                    );
                });
                const actionOnCard = actionOnCardMemo ? "actionOnCard" : undefined;
                let actionOnCardProps = {};
                if (actionOnCardMemo?.callback) {
                    const callback = actionOnCardMemo?.callback;
                    if (!callback) throw new Error("actionOnCard need callback function");
                    actionOnCardProps = {
                        onClick: () => callback(source),
                    };
                }
                return (
                    <Grow in key={sourceIndex} timeout={sourceIndex * 400}>
                        {isCardStatusMemo && isCardStatusMemo.render ? (
                            <CardWithStatusContainerStyled sx={{ marginBottom: 2 }}>
                                <CardStyled
                                    className="card-item"
                                    type="tbody"
                                    elevation={2}
                                    action={actionOnCard}
                                    {...actionOnCardProps}
                                >
                                    {cardContent}
                                    {actionSlideMemo && actionSlideMemo.render && (
                                        <ActionSlideComponent render={actionSlideMemo.render} source={source} />
                                    )}
                                    {actionOnCardMemo && <div className="action-on-card"></div>}
                                </CardStyled>
                                <CardWithStatusStyled
                                    color={isCardStatusMemo.render(source) as Color}
                                    width={statusWidthMemo}
                                    elevation={0}
                                    sx={{
                                        marginBottom: 2,
                                        justifyContent: statusWithLabelMemo ? "unset" : "center",
                                        paddingLeft: statusWithLabelMemo ? "1rem" : "unset",
                                    }}
                                >
                                    <Status
                                        label={
                                            isCardStatusMemo.cardStatusLabelRender &&
                                            isCardStatusMemo.cardStatusLabelRender(source)?.toString()
                                        }
                                        status={isCardStatusMemo.render(source) as Color}
                                    ></Status>
                                </CardWithStatusStyled>
                            </CardWithStatusContainerStyled>
                        ) : (
                            <CardStyled
                                className="card-item"
                                sx={{ marginBottom: 2, padding: 1 }}
                                type="tbody"
                                elevation={2}
                                action={actionOnCard}
                                {...actionOnCardProps}
                            >
                                {cardContent}
                                {actionSlideMemo && actionSlideMemo.render && (
                                    <ActionSlideComponent render={actionSlideMemo.render} source={source} />
                                )}
                                {actionOnCardMemo && (
                                    <div className="action-on-card">
                                        {/* <span className="icon">
                                        <SearchIcon />
                                    </span> */}
                                    </div>
                                )}
                            </CardStyled>
                        )}
                    </Grow>
                );
            });
        } catch (error: unknown) {
            console.error((error as Error).message);
            throw error;
        }
    }, [
        datasource,
        columns,
        direction,
        isSM,
        withHeader,
        actionSlideMemo,
        actionOnCardMemo,
        isCardStatusMemo,
        statusWithLabelMemo,
        statusWidthMemo,
        cardStatusSideEffectSx,
    ]);
    useEffect(() => {
        if (datasourceMemo) {
            setDatasourceState(datasourceMemo);
        }
    }, [datasourceMemo]);

    useEffect(() => {
        console.debug({ datasourceState });
    }, [datasourceState]);

    return (
        <DataTableContainer>
            <Fragment>
                {!isSM && withHeader && datasourceTheadMemo}
                {loading ? <Loading /> : datasource.length ? datasourceMemo : <NoData />}
                {withPagination && (
                    <Pagination
                        page={paginationCurrentPage}
                        count={getPageCount(paginationTotalRecordMemo) || 1}
                        color="primary"
                        onChange={onPageChangeCallback}
                        disabled={loading}
                    />
                )}
            </Fragment>
        </DataTableContainer>
    );
};

export default DataTable;
