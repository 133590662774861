import { Box, BoxProps } from "@mui/material";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import { TemplateOption } from "../../../Template/Template";

const drawerWidth = Number(process.env.REACT_APP_DRAWER_WIDTH || 240);
const openedMixin = (theme: Theme): CSSObject => ({
    left: drawerWidth,
    transition: theme.transitions.create("left", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("left", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    left: 0,
});

interface HeaderCardContainerStyledProps {
    open: boolean;
    top?: string;
}
const HeaderCardContainerStyled = styled("div")<HeaderCardContainerStyledProps>(({ theme, open, top }) => ({
    height: "200px",
    position: "absolute",
    right: 0,
    top: top || "64px",
    overflow: "hidden",
    pointerEvents: "none",
    zIndex: -9,
    ...(open && {
        ...openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
    }),
    [theme.breakpoints.down("sm")]: {
        // top: "",
    },
    animation: "1s ease-out 0s 1 bounceInLeft",
}));

interface HeaderStyledProps extends BoxProps {
    open: boolean;
}
const HeaderCardStyled = styled(Box)<HeaderStyledProps>(({ theme, open }) => ({
    ...theme.mixins.toolbar,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    height: "500px",
    overflow: "hidden",
    transform: "skew(-50deg)",
    left: "-30%",
    [theme.breakpoints.down("md")]: {
        transform: "skew(-30deg)",
        left: "-10%",
    },
    [theme.breakpoints.down("sm")]: {
        transform: "unset",
        left: 0,
    },
    [theme.breakpoints.down("xs")]: {
        transform: "unset",
        left: 0,
    },
}));

type HeaderCardProps = {
    templateOption: TemplateOption;
    top?: string;
};

const HeaderCard = ({ templateOption, top }: HeaderCardProps) => {
    return (
        <HeaderCardContainerStyled open={templateOption.open} top={top}>
            <HeaderCardStyled open={templateOption.open} component="main" sx={{ height: 200 }} />
        </HeaderCardContainerStyled>
    );
};

export default HeaderCard;
