import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/system";
import Typography from "../../Typography/Typography";

const NoDataStyled = styled(Stack)(({ theme }) => ({
    marginTop: "10rem",
    width: "100%",
    backgroundColor: "#FFFFFF05",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));

const IconContainer = styled("div")(({ theme }) => ({
    padding: 4,
    width: "8rem",
    height: "8rem",
    backgroundColor: theme.palette.primary.main + "30 !important",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));

const NoData = () => {
    return (
        <NoDataStyled spacing={2}>
            <IconContainer>
                <FolderRoundedIcon sx={{ fontSize: "6rem" }} />
            </IconContainer>
            <Typography>No results found.</Typography>
        </NoDataStyled>
    );
};

export default NoData;
