import PersonIcon from "@mui/icons-material/Person";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { compose } from "ramda";
import { ChangeEvent, FocusEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FindDataAutoComplete } from "../../../../classes/find-data-auto-complete.class";
import { FindDataPagination } from "../../../../classes/find-data-pagination.class";
import { ResponseAutoComplete } from "../../../../classes/response-auto-complete.class";
import { ResponsePagination } from "../../../../classes/response-pagination.class";
import { AppRouter, getAppRouterPath } from "../../../../config/AppRouter/AppRouters.menu";
import { NavigateStateType } from "../../../../config/AppRouter/NavigateState";
import { useAxios } from "../../../../contexts/axios.context";
import { useLoading } from "../../../../contexts/loading.context";
import { useScreen } from "../../../../contexts/screen.context";
import { checkStatusBooleanToValue, checkStatusValueToBoolean } from "../../../../utils/helper";
import AutoComplete from "../../../Common/AutoComplete/AutoComplete";
import AvatarGroup from "../../../Common/AvatarGroup/AvatarGroup";
import DataTable, { Columns } from "../../../Common/DataTable/DataTable";
import FormControl from "../../../Common/FormControl/FormControl";
import Body from "../../../Common/Page/Body/Body";
import Header from "../../../Common/Page/Header/Header";
import { Filters, HeaderFilterStatus } from "../../../Common/Page/Header/HeaderFilter";
import RadioGroup from "../../../Common/RadioGroup/RadioGroup";
import Status from "../../../Common/Status/Status";
import withAppRouter, { WithAppRouterProps } from "../../../HoC/withAppRouter";
import { useTemplateOption } from "../../../Template/Template";
import { RoleCriteriaDto } from "../RoleMgt/dto/role-criteria.dto";
import { RoleDto } from "../RoleMgt/dto/role.dto";
import { UserCriteriaDto } from "./dto/user-criteria.dto";
import { UserFilterDto } from "./dto/user-filter.dto";
import { UserDto } from "./dto/user.dto";

type UserMgtProps = WithAppRouterProps & {};
const UserMgt = ({ pageRouter }: UserMgtProps) => {
    const { post } = useAxios();
    const { setScreen } = useScreen();
    const templateOption = useTemplateOption();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    const { loading } = useLoading();
    const navigate = useNavigate();
    const initialFiltersMemo: UserFilterDto = useMemo(() => ({ isActive: "", roles: [] }), []);
    const [finder, setFinder] = useState<FindDataPagination<UserCriteriaDto>>(
        new FindDataPagination<UserCriteriaDto>(undefined, {
            filters: [
                {
                    key: "isActive",
                    label: "Status",
                    value: "",
                    render: (status) => <HeaderFilterStatus status={status.value} />,
                },
            ],
        })
    );
    const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
    const [datasource, setDatasource] = useState<Array<UserDto>>([]);
    const [datasourceIsLoading, setDatasourceIsLoading] = useState<boolean>(false);
    const [totalRecord, setTotalRecord] = useState<number>(0);

    useEffect(() => {
        setScreen({ code: "CSMGT01001", name: "User Management" });
        return () => setScreen(undefined);
    }, [setScreen]);
    const getUsers = useCallback(async () => {
        setDatasourceIsLoading(true);
        const response = await post<ResponsePagination<Array<UserDto>>>("/user/findByCriteria", finder);
        setDatasource(response.data.datasource);
        setTotalRecord(response.data.totalRecord);
        setDatasourceIsLoading(false);
    }, [post, finder, setDatasource, setTotalRecord]);
    useEffect(() => {
        getUsers();
    }, [finder, getUsers]);

    const [roleOptions, setRoleOptions] = useState<Array<RoleDto>>([]);
    const [roleOptionsIsLoading, setRoleOptionsIsLoading] = useState<boolean>(false);
    const [roleFinder, setRoleFinder] = useState<FindDataAutoComplete<RoleCriteriaDto>>(
        new FindDataAutoComplete<RoleCriteriaDto>()
    );

    useEffect(() => {
        if (!loading) setRoleOptionsIsLoading(false);
    }, [loading]);

    const roleOptionsOnSearch = useCallback(({ target: { value: searchString } }: ChangeEvent<HTMLInputElement>) => {
        setRoleFinder((p) => ({
            ...p,
            limit: Number(process.env.REACT_APP_AUTO_COMPLETE_LIMIT) || 10,
            criteria: { searchString },
        }));
    }, []);
    const getRoleOptionsCallback = useCallback(async () => {
        setRoleOptionsIsLoading(true);
        const response = await post<ResponseAutoComplete<Array<RoleDto>>>("/role/findAutoComplete", roleFinder);
        setRoleOptions(response.data.datasource);
    }, [post, roleFinder]);

    useMemo(
        async () => openFilterDialog && (await getRoleOptionsCallback()),
        [getRoleOptionsCallback, openFilterDialog]
    );

    const onFilterFormSubmit = (values: UserFilterDto, { setSubmitting }: FormikHelpers<UserFilterDto>) => {
        const isActive = checkStatusValueToBoolean(values.isActive);
        const updateFilters: Filters<UserFilterDto> = [
            {
                key: "isActive",
                value: isActive,
                label: "Status",
                render: (status) => <HeaderFilterStatus status={status.value} />,
            },
        ];
        if (values.roles?.length) {
            updateFilters.push({
                key: "roles",
                value: values.roles,
                label: "Roles",
                canDelete: true,
                render: (roles) => {
                    const rolesValue = roles.value as Array<RoleDto>;
                    return rolesValue.map((role, index: number) => (
                        <Chip
                            variant="filled"
                            key={index}
                            size="small"
                            label={role.name}
                            color="success"
                            sx={{ marginLeft: 0.5 }}
                        />
                    ));
                },
            });
        }
        setFinder((p) => ({
            pagination: { ...p.pagination!, currentPage: 1 },
            criteria: {
                ...p.criteria,
                filters: updateFilters,
            },
        }));
        setSubmitting(false);
        setOpenFilterDialog(false);
    };

    const onFilterFormReset = () => {
        const filters = finder.criteria?.filters;
        const captureFilter: UserFilterDto = {
            ...initialFiltersMemo,
            ...filters?.reduce<UserFilterDto>(
                (prev, { key, value }) => ({
                    ...prev,
                    [key]: key === "isActive" ? checkStatusBooleanToValue(value) : value,
                }),
                {}
            ),
        };
        filterFormik.resetForm({ values: captureFilter });
        setOpenFilterDialog(false);
    };

    const filterFormik = useFormik<UserFilterDto>({
        initialValues: initialFiltersMemo,
        onSubmit: onFilterFormSubmit,
    });

    const columns = useMemo<Columns<UserDto>>(
        () => [
            {
                dataIndex: "isActive",
                render: (user: UserDto) => (user.isActive ? "success" : "default"),
                label: "Status",
                exceptMobile: true,
                isCardStatus: true,
                cardStatusLabelRender: (user: UserDto) => (user.isActive ? "Active" : "Inactive"),
            },
            {
                dataIndex: "email",
                cell: { col: 2, row: 1 },
                render: (user: UserDto) => {
                    return (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {isSM && <Status status={user.isActive ? "success" : "default"}></Status>}
                            <Typography variant="body2">{user.email}</Typography>
                        </Stack>
                    );
                },
                label: "Email",
                width: 200,
            },
            {
                dataIndex: "fullName",
                cell: { col: 3, row: 1 },
                render: (user: UserDto) => {
                    return <Typography variant="body2">{user.fullName}</Typography>;
                },
                label: "Name",
            },
            {
                dataIndex: "roles",
                cell: { col: 5, row: 1 },
                render: (user: UserDto) => {
                    const roles: Array<RoleDto> | undefined = user.roles;
                    if (roles?.length) {
                        const count = Number(process.env.REACT_APP_CHIP_COUNT) || 2;
                        const activeRoles = roles.filter((role: RoleDto) => role.isActive);
                        return (
                            <Stack direction="row" spacing={2} alignItems="center">
                                <>
                                    {activeRoles
                                        .filter((_: RoleDto, rowIndex: number) => rowIndex < count)
                                        .map((role: RoleDto, rowIndex: number) => (
                                            <Chip label={role.name} key={rowIndex} size="small" />
                                        ))}
                                    {activeRoles.length > count && (
                                        <AvatarGroup total={roles?.length - count}></AvatarGroup>
                                    )}
                                </>
                            </Stack>
                        );
                    }
                    return undefined;
                },
                label: "Roles",
            },
            {
                dataIndex: "actionOnCard",
                callback: (user: UserDto) => {
                    const { id, fullName } = user;
                    const navigateStateType: NavigateStateType = {
                        modules: "user",
                        type: "management",
                        screenType: "detail",
                        label: fullName,
                        update: { canAccess: true, permissions: ["CSMGT01004"] },
                        remove: { canAccess: true, permissions: ["CSMGT01005"] },
                    };
                    const path = getAppRouterPath(navigateStateType);
                    navigate(`${path}${id}`, { state: navigateStateType });
                },
            },
        ],
        [navigate, isSM]
    );

    const onSearchString = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setFinder({
            ...finder,
            pagination: { ...finder.pagination!, currentPage: 1 },
            criteria: { ...finder.criteria, searchString: value },
        });
    };

    const onBlur = ({ target: { value } }: FocusEvent<HTMLInputElement>) => {};

    const onPageChange = useCallback(
        (page: number) => setFinder((f) => ({ ...f, pagination: { ...f.pagination!, currentPage: page } })),
        [setFinder]
    );

    const pageRouterMemo: AppRouter | undefined = useMemo(() => {
        if (!pageRouter) return undefined;
        return {
            ...pageRouter,
            search: { canAccess: true, permissions: ["CSMGT01001"] },
            filter: {
                canAccess: true,
                permissions: ["CSMGT01001"],
                onClick: () => setOpenFilterDialog(true),
                filters: finder.criteria?.filters || [],
                onDeleteFilter: async (key) => {
                    const currentFilters = finder.criteria?.filters?.filter((filter) => filter.key !== key);
                    const isActive = currentFilters?.find((filter) => filter.key === "isActive")?.value;
                    const roles = currentFilters?.find((filter) => filter.key === "roles")?.value || [];
                    await filterFormik.setValues({ roles, isActive: checkStatusBooleanToValue(isActive) });
                    setFinder((p) => ({
                        ...p,
                        pagination: { ...p.pagination!, currentPage: 1 },
                        criteria: {
                            ...p.criteria,
                            filters: currentFilters,
                        },
                    }));
                },
            },
            create: {
                canAccess: true,
                permissions: ["CSMGT01003"],
                onClick: () => {
                    const navigateStateType: NavigateStateType = {
                        modules: "user",
                        type: "management",
                        screenType: "create",
                        label: "Create User",
                        create: { canAccess: true, permissions: ["CSMGT01003"] },
                    };
                    const path = getAppRouterPath(navigateStateType);
                    navigate(`${path}`, { state: navigateStateType });
                },
            },
        };
    }, [pageRouter, navigate, finder, filterFormik]);

    return (
        <Fragment>
            <Header
                templateOption={templateOption}
                icon={PersonIcon}
                pageRouter={pageRouterMemo}
                searchProps={{ onChange: onSearchString, onBlur, isSearching: !!finder.criteria?.searchString }}
            />
            <Body templateOption={templateOption}>
                <DataTable<UserDto>
                    withHeader={!isSM}
                    datasource={datasource}
                    columns={columns}
                    direction="horizontal"
                    loading={datasourceIsLoading}
                    paginationOption={{
                        totalRecord,
                        currentPage: finder.pagination?.currentPage,
                        onChange: (_, page) => onPageChange(page),
                    }}
                />
            </Body>

            <Dialog
                open={openFilterDialog}
                fullScreen={isSM}
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                    },
                }}
                PaperProps={{ sx: { minWidth: !isSM ? 500 : "unset", maxWidth: !isSM ? 500 : "unset" } }}
            >
                <form
                    onSubmit={async (formEvent) => {
                        formEvent.preventDefault();
                        filterFormik.handleSubmit(formEvent);
                    }}
                >
                    <DialogTitle>Filters</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel>Status</FormLabel>
                                    <RadioGroup
                                        row
                                        name="isActive"
                                        value={filterFormik.values.isActive}
                                        onChange={filterFormik.handleChange}
                                    >
                                        <FormControlLabel value="" control={<Radio size="small" />} label="All" />
                                        <FormControlLabel value="A" control={<Radio size="small" />} label="Active" />
                                        <FormControlLabel value="I" control={<Radio size="small" />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel>Roles</FormLabel>
                                    <AutoComplete
                                        textFieldPlaceholder="roles"
                                        id="roles"
                                        loading={roleOptionsIsLoading}
                                        onSearchString={roleOptionsOnSearch}
                                        options={roleOptions}
                                        finderCallback={setRoleFinder}
                                        getOptions={getRoleOptionsCallback}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={(option: RoleDto) => option.name!}
                                        getOptionDisabled={(option: RoleDto) => !option.isActive}
                                        renderTags={(value: Array<RoleDto>, getTagProps) =>
                                            value.map((option: RoleDto, index: number) => (
                                                <Chip
                                                    size="small"
                                                    variant={option.isActive ? "outlined" : "filled"}
                                                    label={option.name}
                                                    {...getTagProps({ index })}
                                                    color={option.isActive ? "success" : "error"}
                                                />
                                            ))
                                        }
                                        value={filterFormik.values.roles}
                                        onChange={(_e, v) => filterFormik.setFieldValue("roles", v)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onFilterFormReset}>Cancel</Button>
                        <Button type="submit" variant="contained">
                            Confirm
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    );
};

const UserMgtWrapped = compose(withAppRouter);
export default UserMgtWrapped(UserMgt);
