import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, BoxProps, Button, styled } from "@mui/material";
import { useMemo } from "react";
import { AppRouter } from "../../../../config/AppRouter/AppRouters.menu";

interface HeaderBoxSearchStyledProps extends BoxProps {
    open: boolean;
}

const HeaderBoxManagementStyled = styled(Box)<HeaderBoxSearchStyledProps>(({ theme, open }) => ({
    // animation: "1s ease-out 0s 1 bounceInRight",
}));

type HeaderCreateProps = {
    open: boolean;
    pageRouter?: AppRouter;
};
const HeaderCreate = ({ open, pageRouter, ...props }: HeaderCreateProps) => {
    const createMemo = useMemo(() => pageRouter?.create, [pageRouter]);
    return (
        <HeaderBoxManagementStyled open={open}>
            <Button
                type="button"
                variant="contained"
                startIcon={<AddCircleIcon />}
                color="primary"
                onClick={createMemo?.onClick}
            >
                {createMemo?.label ? createMemo.label : "Create"}
            </Button>
        </HeaderBoxManagementStyled>
    );
};

export default HeaderCreate;
