import { styled } from "@mui/material/styles";

const ThumbProfile = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: theme.spacing(4),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  //   backgroundImage: `linear-gradient(${theme.palette.primary.main} 50%, ${theme.palette.secondary.main} 50%)`,
  backgroundColor: theme.palette.primary.main,
}));

export default ThumbProfile;
