import SearchIcon from "@mui/icons-material/Search";
import {
    InputAdornment,
    StandardTextFieldProps as MuiStandardTextFieldProps,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useMemo } from "react";
import { INPUT_DELAY } from "../../../../../config/environment.default";
import TextField from "../../../TextField/TextField";

interface TextFieldStyledProps extends MuiStandardTextFieldProps {
    delay?: number;
}

const TextFieldStyled = styled(TextField)<TextFieldStyledProps>(({ theme }) => ({
    // backgroundColor: theme.palette.primary.main,
    filter: "brightness(1.2)",
    "& .MuiTextField-root": {
        // color: theme.palette.text.secondary,
    },
    "& .MuiInputAdornment-root": {
        // color: theme.palette.text.secondary,
    },
    "& input": {
        // color: theme.palette.text.secondary,
    },
    "& fieldset": {
        border: "none",
    },
    ":hover": {
        filter: "brightness(1.1)",
    },
}));
const SearchTextField = ({
    delay = Number(process.env.REACT_APP_INPUT_DELAY) || 500,
    ...props
}: TextFieldStyledProps) => {
    // const [inputValue, setInputValue] = useState("");
    // const [timer, setTimer] = useState<NodeJS.Timer | undefined>(undefined);
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    // const onChangeCallback = useCallback(
    //     (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    //         if (!event) return;
    //         setInputValue(event.target.value);
    //         clearTimeout(timer);
    //         const newTimer = setTimeout(() => {
    //             if (props.onChange && event) props.onChange(event);
    //         }, delay);
    //         setTimer(newTimer);
    //     },
    //     [setInputValue, setTimer, delay, props, timer]
    // );

    useMemo(() => console.debug(isSM), [isSM]);
    // return isSM ? (
    //     <Fab color="primary" aria-label="search">
    //         <SearchIcon />
    //     </Fab>
    // ) : (
    //     <TextFieldStyled
    //         fullWidth
    //         {...props}
    //         value={inputValue}
    //         onChange={onChangeCallback}
    //         size="small"
    //         placeholder="Search"
    //         InputProps={{
    //             startAdornment: (
    //                 <InputAdornment position="start">
    //                     <SearchIcon />
    //                 </InputAdornment>
    //             ),
    //         }}
    //     />
    // );
    return (
        <TextFieldStyled
            fullWidth
            {...props}
            delay={INPUT_DELAY}
            size="small"
            placeholder="Search"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchTextField;
