import { styled } from "@mui/material";
const AvatarBorder = styled("div")(({ theme }) => ({
  width: 80,
  height: 80,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
}));
export default AvatarBorder;
