import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import { useConfirm } from "material-ui-confirm";
import { useCallback, useState } from "react";
import { StatusType } from "../../../../config/type";
import { useAlert } from "../../../../contexts/alert.context";
import { useAxios } from "../../../../contexts/axios.context";
import { ModelDto } from "./dto/model.dto";

type ModelDownloaderType = {
    model: ModelDto;
};
const ModelCancelQueue = ({ model }: ModelDownloaderType) => {
    const { post } = useAxios();
    const confirm = useConfirm();
    const { setAlert } = useAlert();
    const [loading, setLoading] = useState<boolean>(false);
    const onCancelQueueModel = useCallback(
        async (queueModelId: number) => {
            confirm({ title: "Cancel Queue", description: "Are you sure?" })
                .then(async () => {
                    setLoading(true);
                    await post("/e2e-model/request/model/cancel", { queueModelId });
                    setAlert({ status: "success", statusCode: 200, message: "This queue has been cancelled" });
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false);
                });
        },
        [post, confirm, setAlert]
    );
    return (
        <LoadingButton
            variant="contained"
            color="error"
            loading={loading}
            disabled={(["ERROR", "CANCELLED"] as Array<StatusType>).includes(model.status!)}
            endIcon={<CancelIcon />}
            onClick={() => onCancelQueueModel(model.queueModelId!)}
            sx={{ textTransform: "none" }}
        >
            Cancel
        </LoadingButton>
    );
};

export default ModelCancelQueue;
