import {
    styled,
    SwipeableDrawer as MuiSwipeableDrawer,
    SwipeableDrawerProps as MuiSwipeableDrawerProps,
} from "@mui/material";
import { PropsWithChildren } from "react";
import { UserProfile } from "../../../contexts/authorize.context";
import Avatar from "../../Common/Avatar/Avatar";
import DrawerHeader from "../../Common/Drawer/DrawerHeader";
import ThumbProfile from "../../Common/Profile/ThumbProfile";

const SwipeableDrawer = styled(MuiSwipeableDrawer)<MuiSwipeableDrawerProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 2,
    "& .MuiPaper-root": {
        height: "100vh",
    },
}));
type MobileMenuType = {
    open: boolean;
    onClose: () => void;
    onOpen: () => void;
    userProfile?: UserProfile;
};
const MobileMenu = ({ open, onClose, onOpen, userProfile, children }: PropsWithChildren<MobileMenuType>) => {
    return (
        <SwipeableDrawer anchor="top" open={open} onClose={onClose} onOpen={onOpen}>
            <DrawerHeader />
            <ThumbProfile>
                <Avatar
                    avatar={{
                        src: userProfile?.photo,
                        name: userProfile?.fullName!,
                        email: userProfile?.email!,
                    }}
                />
            </ThumbProfile>
            {children}
        </SwipeableDrawer>
    );
};

export default MobileMenu;
