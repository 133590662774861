import { useCallback, useEffect, useMemo, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../assets/logo/scg-logo.png";
import { useAuthoize } from "../../contexts/authorize.context";
import { useAxios } from "../../contexts/axios.context";
import { CredentialJWT } from "./dto/crendential-jwt.dto";
import { Credential } from "./dto/crendential.dto";
const texts: Array<string> = ["Verifying", "Authority"];
const morphTime: number = 1; //second
const cooldownTime: number = 0.25; //second
let time = new Date();
let textIndex = texts.length - 1;
let morph = 0;
let cooldown = cooldownTime;

const Authenticate = () => {
    const navigate = useNavigate();
    const { post } = useAxios();
    const { setIsAuth, setPermissions, setUserProfile } = useAuthoize();
    const [searchParams] = useSearchParams();
    const credentialsMemo = useMemo<Credential>(() => ({ ssoToken: searchParams.get("access_token") }), [searchParams]);

    const textRef1 = useRef<HTMLElement>(null);
    const textRef2 = useRef<HTMLElement>(null);

    const setMorphFraction = useCallback(
        (fraction: number) => {
            if (!textRef1?.current || !textRef2?.current) return;
            textRef2.current.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
            textRef2.current.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
            fraction = 1 - fraction;
            textRef1.current.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
            textRef1.current.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

            textRef1.current.textContent = texts[textIndex % texts.length];
            textRef2.current.textContent = texts[(textIndex + 1) % texts.length];
        },
        [textRef1, textRef2]
    );

    const doMorph = useCallback(() => {
        morph -= cooldown;
        cooldown = 0;
        let fraction = morph / morphTime;
        if (fraction > 1) {
            cooldown = cooldownTime;
            fraction = 1;
        }
        setMorphFraction(fraction);
    }, [setMorphFraction]);

    const doCooldown = useCallback(() => {
        if (!textRef1?.current || !textRef2?.current) return;
        morph = 0;

        textRef2.current.style.filter = "";
        textRef2.current.style.opacity = "100%";

        textRef1.current.style.filter = "";
        textRef1.current.style.opacity = "0%";
    }, [textRef1, textRef2]);

    const animation = useCallback(() => {
        requestAnimationFrame(animation);
        let newTime = new Date();
        let shouldIncrementIndex = cooldown > 0;
        let dt = (newTime.getTime() - time.getTime()) / 1000;
        time = newTime;

        cooldown -= dt;

        if (cooldown <= 0) {
            if (shouldIncrementIndex) {
                textIndex++;
            }
            doMorph();
        } else {
            doCooldown();
        }
    }, [doMorph, doCooldown]);

    useEffect(() => animation(), [animation]);

    const splashCallBack = useCallback(
        () => (
            <div className="refreshing">
                <img src={Logo} alt="scg-logo" />
                {/* {Verifying Authority} */}
                <div className="verify-authority-container">
                    <span ref={textRef1} className="text1"></span>
                    <span ref={textRef2} className="text2"></span>
                </div>

                <svg id="filters">
                    <defs>
                        <filter id="threshold">
                            <feColorMatrix
                                in="SourceGraphic"
                                type="matrix"
                                values="1 0 0 0 0
                                        0 1 0 0 0
                                        0 0 1 0 0
                                        0 0 0 255 -140"
                            />
                        </filter>
                    </defs>
                </svg>
            </div>
        ),
        []
    );
    useEffect(() => {
        if (credentialsMemo?.ssoToken) {
            const authorize = async () => {
                const response = await post<CredentialJWT>("/auth/login", credentialsMemo);
                const token = response.data.token;
                if (token) {
                    localStorage.setItem("Authorization", token);
                    navigate("/", { replace: true });
                }
            };
            authorize();
        }
    }, [credentialsMemo, searchParams, post, navigate, setPermissions, setIsAuth, setUserProfile]);

    return splashCallBack();
};

export default Authenticate;
