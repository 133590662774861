import DescriptionIcon from "@mui/icons-material/Description";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingButton } from "@mui/lab";
import {
    Badge,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Tooltip,
    useMediaQuery,
    useTheme,
    Zoom,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import dayjs from "dayjs";
import { EventSourcePolyfill } from "event-source-polyfill";
import { FormikHelpers, useFormik } from "formik";
import { compose } from "ramda";
import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { FindDataPagination } from "../../../../classes/find-data-pagination.class";
import { ResponsePagination } from "../../../../classes/response-pagination.class";
import { AppRouter, getAppRouterPath } from "../../../../config/AppRouter/AppRouters.menu";
import { NavigateStateType } from "../../../../config/AppRouter/NavigateState";
import { StatusType } from "../../../../config/type";
import { useAxios } from "../../../../contexts/axios.context";
import { useLoading } from "../../../../contexts/loading.context";
import { useScreen } from "../../../../contexts/screen.context";
import { getColorOfStatusType, getStatusTypeLabel } from "../../../../utils/helper";
import DataTable, { Columns } from "../../../Common/DataTable/DataTable";
import FormControl from "../../../Common/FormControl/FormControl";
import Body from "../../../Common/Page/Body/Body";
import Header from "../../../Common/Page/Header/Header";
import { Filters } from "../../../Common/Page/Header/HeaderFilter";
import Typography from "../../../Common/Typography/Typography";
import withAppRouter, { WithAppRouterProps } from "../../../HoC/withAppRouter";
import { useTemplateOption } from "../../../Template/Template";
import { TemplateRevisionDto } from "../Template/dto/template-revision.dto";
import ChipDownloadTemplateRevision from "./ChipDownloadTemplateRevision";
import { ModelCriteriaDto } from "./dto/model-criteria.dto";
import { ModelEventDto } from "./dto/model-event.dto";
import { ModelFilterDto } from "./dto/model-filter.dto";
import { ModelStepDto } from "./dto/model-step.dto";
import { ModelDto } from "./dto/model.dto";
import ModelCancelQueue from "./ModelCancelQueue";
import ModelDownloader from "./ModelDownloader";
import ModelProgress from "./ModelProgress";

type ModelProps = WithAppRouterProps & {};
const Model = ({ pageRouter }: ModelProps) => {
    const { post } = useAxios();
    const { setScreen } = useScreen();
    const templateOption = useTemplateOption();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    const { loading } = useLoading();
    const navigate = useNavigate();
    const initialFiltersMemo: ModelFilterDto = useMemo(() => ({ statuses: [], modelTypes: [] }), []);
    const [finder, setFinder] = useState<FindDataPagination<ModelCriteriaDto>>(
        new FindDataPagination<ModelCriteriaDto>(undefined, {
            filters: [
                {
                    key: "modelTypes",
                    value: [],
                    label: "Model",
                    render: () => (
                        <Chip variant="filled" size="small" label={"All"} color="success" sx={{ marginLeft: 0.5 }} />
                    ),
                },
                {
                    key: "statuses",
                    value: [],
                    label: "Statuses",
                    render: () => (
                        <Chip variant="filled" size="small" label={"All"} color="success" sx={{ marginLeft: 0.5 }} />
                    ),
                },
            ],
        })
    );
    const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
    const [datasource, setDatasource] = useState<Array<ModelDto>>([]);
    const [datasourceIsLoading, setDatasourceIsLoading] = useState<boolean>(false);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [eventSource, setEventSource] = useState<EventSourcePolyfill | null>(null);
    const [pageHasChanged, setPageHasChanged] = useState<boolean>(false);
    const [searchStringHasChanged, setSearchStringHasChanged] = useState<boolean>(false);
    const [filterHasChanged, setFilterHasChanged] = useState<boolean>(false);
    const [modelOptions, setModelOptions] = useState<Array<ModelDto>>([]);
    const [modelOptionsIsLoading, setModelOptionsIsLoading] = useState<boolean>(false);
    const statusOptions = useMemo<Array<StatusType>>(
        () => ["WAITING", "IN_PROGRESS", "COMPLETED", "ERROR", "CANCELLED"],
        []
    );
    const [openProgres, setOpenProgress] = useState<boolean>(false);
    const [progresSteps, setProgressSteps] = useState<Array<ModelStepDto> | null | undefined>(null);
    const [refreshDatasource, setRefreshDatasource] = useState<boolean>(false);
    const [refreshAlertBadge, setRefreshAlertBadge] = useState<boolean>(false);
    const axiosSource = useMemo(() => axios.CancelToken.source(), []);

    useEffect(() => {
        setScreen({ code: "DSMGT02001", name: "Model Function" });
        return () => setScreen(undefined);
    }, [setScreen]);

    const getModels = useCallback(async () => {
        const response = await post<ResponsePagination<Array<ModelDto>>>("/e2e-model/findByCriteria", finder, {
            cancelToken: axiosSource.token,
        });
        setDatasource(response.data.datasource);
        setTotalRecord(response.data.totalRecord);
        setRefreshDatasource(false);
        setDatasourceIsLoading(false);
    }, [post, finder, setDatasource, axiosSource]);

    useEffect(() => {
        setDatasourceIsLoading(true);
        getModels();
    }, [finder, getModels]);

    useEffect(() => {
        if (refreshDatasource) {
            if (finder.pagination?.currentPage === 1) {
                getModels();
            } else setRefreshAlertBadge(true);
        }
    }, [getModels, refreshDatasource, finder.pagination?.currentPage]);

    useEffect(() => {
        if (pageHasChanged || searchStringHasChanged || filterHasChanged) {
            setDatasourceIsLoading(true);
            setPageHasChanged(false);
            setSearchStringHasChanged(false);
            setFilterHasChanged(false);
        }
    }, [pageHasChanged, searchStringHasChanged, filterHasChanged]);

    useEffect(() => {
        if ("EventSource" in window) {
            const jwt = localStorage.getItem("Authorization");
            const url = `${process.env.REACT_APP_API}/e2e-model/find/events`;
            setEventSource(
                new EventSourcePolyfill(url, { headers: { Authorization: `Bearer ${jwt}` }, heartbeatTimeout: 300000 })
            );
        }
    }, []);

    useEffect(() => {
        if (eventSource) {
            eventSource.onmessage = (e) => {
                const refresh = (JSON.parse(e.data) as ModelEventDto).refresh || false;
                setRefreshDatasource(refresh);
            };
            eventSource.onerror = () => {
                // eventSource.close();
            };
        }

        return () => eventSource?.close();
    }, [eventSource]);
    useEffect(() => {
        return () => axiosSource && axiosSource.cancel();
    }, [axiosSource]);

    useEffect(() => {
        if (!loading) {
        }
    }, [loading]);

    const onFilterFormSubmit = (values: ModelFilterDto, { setSubmitting }: FormikHelpers<ModelFilterDto>) => {
        const updateFilters: Filters<ModelFilterDto> = [
            {
                key: "modelTypes",
                value: values.modelTypes,
                label: "Model",
                render: (modelTypes) => {
                    const modelTypesValue = modelTypes.value as Array<number>;
                    if (modelOptions.length === modelTypesValue.length || modelTypesValue.length === 0) {
                        return (
                            <Chip
                                variant="filled"
                                size="small"
                                label={"All"}
                                color="success"
                                sx={{ marginLeft: 0.5 }}
                            />
                        );
                    }
                    return modelTypesValue.map((modelType, index: number) => {
                        const modelOption = modelOptions.find((option) => Number(option.modelId) === Number(modelType));
                        return (
                            <Chip
                                variant="filled"
                                key={index}
                                size="small"
                                label={modelOption?.modelName}
                                color="success"
                                sx={{ marginLeft: 0.5 }}
                            />
                        );
                    });
                },
            },
            {
                key: "statuses",
                value: values.statuses,
                label: "Statuses",
                render: (statuses) => {
                    const statusesValue = statuses.value as Array<StatusType>;
                    if (statusOptions.length === statusesValue.length || statusesValue.length === 0) {
                        return (
                            <Chip
                                variant="filled"
                                size="small"
                                label={"All"}
                                color="success"
                                sx={{ marginLeft: 0.5 }}
                            />
                        );
                    }
                    return statusesValue.map((status, index: number) => {
                        return (
                            <Chip
                                variant="filled"
                                key={index}
                                size="small"
                                label={getStatusTypeLabel(status)}
                                color="success"
                                sx={{ marginLeft: 0.5 }}
                            />
                        );
                    });
                },
            },
        ];
        setFinder((p) => ({
            pagination: { ...p.pagination!, currentPage: 1 },
            criteria: {
                ...p.criteria,
                filters: updateFilters,
            },
        }));
        setSubmitting(false);
        setOpenFilterDialog(false);
        setFilterHasChanged(true);
    };

    const onFilterFormReset = () => {
        const filters = finder.criteria?.filters;
        const captureFilter: ModelFilterDto = {
            ...initialFiltersMemo,
            ...filters?.reduce<ModelFilterDto>(
                (prev, { key, value }) => ({
                    ...prev,
                    [key]: value,
                }),
                {} as ModelFilterDto
            ),
        };
        filterFormik.resetForm({ values: captureFilter });
        setOpenFilterDialog(false);
    };

    const filterFormik = useFormik<ModelFilterDto>({
        initialValues: initialFiltersMemo,
        onSubmit: onFilterFormSubmit,
    });

    const getModelOptions = useCallback(async () => {
        setModelOptionsIsLoading(true);
        const response = await post<Array<ModelDto>>("/e2e-model/find/model/options", null, {
            cancelToken: axiosSource.token,
        });
        setModelOptions(response.data);
        setModelOptionsIsLoading(false);
    }, [post, setModelOptionsIsLoading, setModelOptions, axiosSource]);
    useMemo(() => getModelOptions(), [getModelOptions]);

    const columns = useMemo<Columns<ModelDto>>(
        () => [
            {
                justifyContent: isSM ? "center" : "unset",
                dataIndex: "modelName",
                cell: { col: 1, row: 1 },
                render: (model: ModelDto) => {
                    return <Typography>{model.modelName}</Typography>;
                },
            },
            {
                dataIndex: "createdBy",
                label: "Requester",
                textAlign: isSM ? "center" : "left",
                justifyContent: isSM ? "center" : "unset",
                cell: { col: 2, row: 1 },
                render: (model: ModelDto) => (
                    <Typography variant="caption" sx={{ margin: 0, padding: 0 }}>
                        {model.createdBy}
                    </Typography>
                ),
            },
            {
                dataIndex: "createdAt",
                label: "Requested Date ",
                cell: { col: 3, row: 1 },
                textAlign: isSM ? "center" : "left",
                justifyContent: isSM ? "center" : "unset",
                render: (model: ModelDto) => (
                    <Typography variant="caption" sx={{ margin: 0, padding: 0 }}>
                        {dayjs(model.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                    </Typography>
                ),
            },
            {
                dataIndex: "queueEstimatedRuntime",
                label: "Estimated Complete",
                cell: { col: 4, row: 1 },
                width: 160,
                textAlign: "center",
                justifyContent: "center",
                render: (model: ModelDto) => {
                    const estimatedCompletedDateTime = dayjs(model.updatedAt)
                        .add(model?.queueEstimatedRuntime || 0, "millisecond")
                        .format("DD/MM/YYYY HH:mm");
                    return (
                        <Typography variant="caption" sx={{ margin: 0, padding: 0 }} textAlign="center">
                            {model?.queueEstimatedRuntime && model.status !== "CANCELLED"
                                ? estimatedCompletedDateTime
                                : "-"}
                        </Typography>
                    );
                },
            },
            {
                dataIndex: "status",
                cell: { col: 5, row: 1 },
                textAlign: "center",
                justifyContent: "center",
                width: 160,
                ellipsis: true,
                render: (model: ModelDto) => {
                    let color = getColorOfStatusType(theme, model.status);
                    return model.status !== "CANCELLED" ? (
                        <Typography color={color} fontWeight={600}>
                            {getStatusTypeLabel(model.status)}
                        </Typography>
                    ) : (
                        <Tooltip title={`By ${model.updatedBy}`}>
                            <Stack spacing={1} justifyContent="center" alignItems="center" direction="row">
                                <Typography color={color} fontWeight={600}>
                                    {getStatusTypeLabel(model.status)}
                                </Typography>
                                <PersonSearchIcon color="error" />
                            </Stack>
                        </Tooltip>
                    );
                },
            },
            {
                dataIndex: "templatesSelected",
                cell: { col: 1, row: 2 },
                contentFullWidth: true,
                render: (model: ModelDto) => {
                    return (
                        <Grid container spacing={4} rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                            {model.templatesSelected?.map((template: TemplateRevisionDto) => (
                                <Grid item xs={isSM ? 6 : 3} key={template.submitTemplateId}>
                                    <ChipDownloadTemplateRevision
                                        templateRevision={template}
                                        key={template.submitTemplateId}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    );
                },
            },
            {
                dataIndex: "queueModelId",
                cell: { col: 2, row: 2 },
                width: 160,
                textAlign: "center",
                justifyContent: "center",
                contentFullWidth: true,
                render: (model: ModelDto) => {
                    return (
                        <LoadingButton
                            variant="contained"
                            color="warning"
                            disabled={model.status === "WAITING" || !model.steps?.length}
                            endIcon={<DescriptionIcon />}
                            onClick={() => {
                                setProgressSteps(model.steps);
                                setOpenProgress(true);
                            }}
                            sx={{ textTransform: "none" }}
                        >
                            Progress
                        </LoadingButton>
                    );
                },
                // exceptMobile: true,
            },
            {
                dataIndex: "queueModelId",
                cell: { col: 3, row: 2 },
                width: 160,
                textAlign: "center",
                justifyContent: "center",
                contentFullWidth: true,
                render: (model: ModelDto) => {
                    return (["ERROR", "WAITING", "IN_PROGRESS", "CANCELLED"] as Array<StatusType>).includes(
                        model.status!
                    ) ? (
                        <ModelCancelQueue model={model} />
                    ) : (
                        <ModelDownloader model={model} />
                    );
                },
                // exceptMobile: true,
            },
            // {
            //     dataIndex: "queueModelId",
            //     cell: { col: 1, row: 3 },
            //     textAlign: "center",
            //     justifyContent: "center",
            //     render: (model: ModelDto) => {
            //         return (
            //             <LoadingButton
            //                 variant="contained"
            //                 color="warning"
            //                 disabled={model.status === "WAITING" || !model.steps?.length}
            //                 endIcon={<DescriptionIcon />}
            //             >
            //                 Progress
            //             </LoadingButton>
            //         );
            //     },
            //     exceptLaptop: true,
            // },
            // {
            //     dataIndex: "queueModelId",
            //     cell: { col: 2, row: 3 },
            //     textAlign: "center",
            //     justifyContent: "center",
            //     render: (model: ModelDto) => {
            //         return (
            //             <LoadingButton
            //                 variant="contained"
            //                 color="success"
            //                 disabled={model.status !== "COMPLETED"}
            //                 sx={{ textTransform: "none" }}
            //                 endIcon={<DownloadIcon />}
            //             >
            //                 Download
            //             </LoadingButton>
            //         );
            //     },
            //     exceptLaptop: true,
            // },
        ],
        [theme, isSM]
    );
    const onSearchString = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setSearchStringHasChanged(true);
        setFinder({
            ...finder,
            pagination: { ...finder.pagination!, currentPage: 1 },
            criteria: { ...finder.criteria, searchString: value },
        });
    };

    const onPageChange = useCallback(
        (page: number) => {
            setFinder((f) => ({ ...f, pagination: { ...f.pagination!, currentPage: page } }));
            if (page === 1) setRefreshAlertBadge(false);
        },
        [setFinder]
    );

    const pageRouterMemo: AppRouter | undefined = useMemo(() => {
        if (!pageRouter) return undefined;
        return {
            ...pageRouter,
            search: { canAccess: true, permissions: ["DSMGT02001"] },
            filter: {
                canAccess: true,
                permissions: ["DSMGT02001"],
                onClick: () => setOpenFilterDialog(true),
                filters: finder.criteria?.filters || [],
            },
            create: {
                canAccess: true,
                permissions: ["DSDRQ02008"],
                label: "Request",
                onClick: () => {
                    const navigateStateType: NavigateStateType = {
                        modules: "model",
                        type: "e2e",
                        screenType: "create",
                        label: "Model Runner",
                        create: { canAccess: true, permissions: ["DSDRQ02008"], label: "Request Runner" },
                    };
                    const path = getAppRouterPath(navigateStateType);
                    navigate(`${path}`, { state: navigateStateType });
                },
            },
        };
    }, [pageRouter, navigate, finder]);

    return (
        <Fragment>
            <Header
                templateOption={templateOption}
                pageRouter={pageRouterMemo}
                searchProps={{
                    onChange: onSearchString,
                    onBlur: () => {},
                    isSearching: !!finder.criteria?.searchString,
                }}
            />
            <Body templateOption={templateOption}>
                <Zoom in={refreshAlertBadge}>
                    <Box
                        sx={{ marginBottom: 2 }}
                        justifyContent="center"
                        alignItems="center"
                        display={refreshAlertBadge ? "flex" : "none"}
                    >
                        <IconButton
                            sx={{
                                "& :hover": {
                                    "& svg": { animation: "lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite" },
                                },
                            }}
                            onClick={() => onPageChange(1)}
                        >
                            <Badge variant="dot" color="error">
                                <RefreshIcon />
                            </Badge>
                        </IconButton>
                    </Box>
                </Zoom>
                <DataTable<ModelDto>
                    datasource={datasource}
                    columns={columns}
                    direction="horizontal"
                    loading={datasourceIsLoading}
                    paginationOption={{
                        totalRecord,
                        currentPage: finder.pagination?.currentPage,
                        onChange: (_, page) => {
                            onPageChange(page);
                            setPageHasChanged(true);
                        },
                    }}
                />
            </Body>

            <Dialog
                open={openFilterDialog}
                fullScreen={isSM}
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                    },
                }}
                PaperProps={{ sx: { minWidth: !isSM ? 500 : "unset", maxWidth: !isSM ? 500 : "unset" } }}
            >
                <form
                    onSubmit={async (formEvent) => {
                        formEvent.preventDefault();
                        filterFormik.handleSubmit(formEvent);
                    }}
                >
                    <DialogTitle>Filters</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel sx={{ marginBottom: 1 }}>Model Type</FormLabel>
                                    <Select
                                        size="small"
                                        displayEmpty
                                        multiple
                                        id="modelTypes"
                                        name="modelTypes"
                                        value={filterFormik.values.modelTypes}
                                        onChange={async ({ target: { value } }) => {
                                            filterFormik.setFieldValue("modelTypes", value);
                                        }}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return (
                                                    <Typography
                                                        color={theme.palette.primary.main}
                                                        sx={{ opacity: 0.5 }}
                                                    >
                                                        <em>Model Type</em>
                                                    </Typography>
                                                );
                                            }
                                            return (
                                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                    {selected.map((value) => {
                                                        const modelOption = modelOptions.find(
                                                            (option) => option.modelId === value
                                                        );
                                                        return (
                                                            <Chip
                                                                key={value}
                                                                label={modelOption?.modelName}
                                                                size="small"
                                                            />
                                                        );
                                                    })}
                                                </Box>
                                            );
                                        }}
                                        inputProps={{ "aria-label": "Without label" }}
                                        disabled={modelOptionsIsLoading}
                                    >
                                        <MenuItem disabled value="">
                                            <Typography variant="caption" color={theme.palette.primary.main}>
                                                <em>Model Type</em>
                                            </Typography>
                                        </MenuItem>
                                        {modelOptions.map((option) => (
                                            <MenuItem value={option.modelId} key={option.modelId}>
                                                {option.modelName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel sx={{ marginBottom: 1 }}>Statuses</FormLabel>
                                    <Select
                                        size="small"
                                        displayEmpty
                                        multiple
                                        id="statuses"
                                        name="statuses"
                                        value={filterFormik.values.statuses}
                                        onChange={async ({ target: { value } }) => {
                                            filterFormik.setFieldValue("statuses", value);
                                        }}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return (
                                                    <Typography
                                                        color={theme.palette.primary.main}
                                                        sx={{ opacity: 0.5 }}
                                                    >
                                                        <em>Statuses</em>
                                                    </Typography>
                                                );
                                            }
                                            return (
                                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                    {selected.map((value, index) => {
                                                        return (
                                                            <Chip
                                                                key={index}
                                                                label={getStatusTypeLabel(value)}
                                                                size="small"
                                                            />
                                                        );
                                                    })}
                                                </Box>
                                            );
                                        }}
                                        inputProps={{ "aria-label": "Without label" }}
                                        disabled={modelOptionsIsLoading}
                                    >
                                        <MenuItem disabled value="">
                                            <Typography variant="caption" color={theme.palette.primary.main}>
                                                <em>Statuses</em>
                                            </Typography>
                                        </MenuItem>
                                        {statusOptions.map((option, index) => (
                                            <MenuItem value={option} key={index}>
                                                {getStatusTypeLabel(option)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onFilterFormReset}>Cancel</Button>
                        <Button type="submit" variant="contained">
                            Confirm
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <ModelProgress
                open={openProgres}
                onClose={() => {
                    setOpenProgress(false);
                    setProgressSteps(null);
                }}
                steps={progresSteps}
            />
        </Fragment>
    );
};

const ModelWrapped = compose(withAppRouter);
export default ModelWrapped(Model);
