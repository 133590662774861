import { PaletteMode } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ConfirmProvider } from "material-ui-confirm";
import React, { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Authenticate from "./components/Authenticate/Authenticate";
import Unauthorized from "./components/pages/Common/Unauthorized";
import Template from "./components/Template/Template";
import { AppRouters } from "./config/AppRouter/AppRouters.menu";
import { AlertProvider } from "./contexts/alert.context";
import { AuthorizeProvider } from "./contexts/authorize.context";
import { AxiosInstanceProvider } from "./contexts/axios.context";
import { ColorModeContext } from "./contexts/color.context";
import { LoadingProvider } from "./contexts/loading.context";
import { ScreenProvider } from "./contexts/screen.context";
import { getDesignTokens } from "./themes";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const App = () => {
    const [mode, setMode] = React.useState<PaletteMode>("light");
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) => (prevMode === "light" ? "dark" : "light"));
            },
        }),
        []
    );

    const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    return (
        <LoadingProvider>
            <ColorModeContext.Provider value={colorMode}>
                <AlertProvider>
                    <ScreenProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <ThemeProvider theme={theme}>
                                <ConfirmProvider>
                                    <AuthorizeProvider>
                                        <BrowserRouter basename={"/e2e-system"}>
                                            <AxiosInstanceProvider config={{ baseURL: process.env.REACT_APP_API }}>
                                                <Routes>
                                                    <Route
                                                        path={process.env.REACT_APP_AUTHENTICATE_PAGE}
                                                        element={<Authenticate />}
                                                    />
                                                    <Route path="/" element={<Template />}>
                                                        {AppRouters.map((router, index) => (
                                                            <Fragment key={index}>
                                                                <Route path={router.to} element={router.Component} />
                                                            </Fragment>
                                                        ))}
                                                        <Route
                                                            path={process.env.REACT_APP_UNAUTHORIZED_PAGE}
                                                            element={<Unauthorized />}
                                                        />
                                                    </Route>
                                                </Routes>
                                            </AxiosInstanceProvider>
                                        </BrowserRouter>
                                    </AuthorizeProvider>
                                </ConfirmProvider>
                            </ThemeProvider>
                        </LocalizationProvider>
                    </ScreenProvider>
                </AlertProvider>
            </ColorModeContext.Provider>
        </LoadingProvider>
    );
};

export default App;
