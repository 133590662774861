import { AxiosRequestConfig } from "axios";
import { ComponentType, useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router";
import { NavigateModule, NavigateType } from "../../config/AppRouter/NavigateState";
import { useAxios } from "../../contexts/axios.context";
import SourceLoading from "../Common/Loading/Loading";
import { WithAppRouterProps } from "./withAppRouter";

type Request = {
    url: string;
    config?: AxiosRequestConfig;
    keys?: Array<string>;
    navigateModule?: NavigateModule;
    navigateType?: NavigateType;
};

export type DatasourceHoCPropsType<T> = WithAppRouterProps & {
    datasource?: T;
    isCreate?: boolean;
    request?: Request;
    navigate?: () => void;
};

const withDatasource =
    (request: Request) =>
    <T extends {}>(Component: ComponentType<DatasourceHoCPropsType<T>>) =>
    (props: DatasourceHoCPropsType<T>) => {
        const [datasource, setDatasource] = useState<T>();
        const location = useLocation();
        const params = useParams();
        const { get } = useAxios();
        const pathMemo = useMemo(() => location.pathname.substring(location.pathname.lastIndexOf("/") + 1), [location]);

        if (!props.pageRouter || !props.pageRouter?.label) {
            return <Navigate to={`/${request?.navigateType}/${request?.navigateModule}`} replace />;
        }
        if (pathMemo === "create" || !request.url) return <Component {...props} datasource={datasource} isCreate />;

        const getDataCallback = useCallback(
            async (req: Request) => {
                let url = req.url;
                let parameters = !req.keys?.length ? params : undefined;
                req.keys?.forEach((key) => {
                    url = params.hasOwnProperty(key) ? url.replace(`:${key}`, params[key]!) : url;
                });
                const response = await get<T>(url, { ...req.config, params: parameters });
                setDatasource(response.data);
            },
            [get, params]
        );
        useEffect(() => {
            if (request && request.url) getDataCallback(request);
        }, [getDataCallback]);
        if (!datasource && request) {
            return <SourceLoading />;
        }
        return <Component {...props} datasource={datasource} />;
    };

export default withDatasource;
