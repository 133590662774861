import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { createContext, PropsWithChildren, useContext, useMemo, useRef } from "react";
import { ResponseError } from "../classes/response-error.class";
import { HttpStatusCode } from "../config/Enum/HttpStatusCode";
import { Factory } from "../utils/Factory";
import { useAlert } from "./alert.context";
import { useLoading } from "./loading.context";
axios.create();
type AxiosInstanceType = {
    config: AxiosRequestConfig;
    children: any;
};

export const AxiosContext = createContext<AxiosInstance | null>(null);
export const AxiosInstanceProvider = ({ config = {}, children }: PropsWithChildren<AxiosInstanceType>) => {
    const { setLoading } = useLoading();
    const { setAlert } = useAlert();
    const instanceRef = useRef(axios.create(config)).current;
    const currentInstance = useMemo(() => {
        const reqInterceptor = (request: AxiosRequestConfig) => {
            setLoading(true);
            const jwt = localStorage.getItem("Authorization");
            if (jwt) {
                request.headers = {
                    ...request.headers,
                    Authorization: `Bearer ${jwt}`,
                };
            }
            return request;
        };
        const reqErrInterceptor = (error: AxiosError) => {
            console.error({ requestClientError: error });
            let responseErrorFactory = new Factory<ResponseError>(ResponseError).getFactory(error.response?.data);
            setAlert(responseErrorFactory);
            setLoading(false);
            return Promise.reject(error);
        };

        const resInterceptor = (response: AxiosResponse) => {
            setLoading(false);
            // const KEY_ACCESS_TOKEN = (process.env.REACT_APP_HEADERS_ACCESS_TOKEN || "X-Access-Token").toLowerCase();
            // const token = response.headers[KEY_ACCESS_TOKEN];
            // if (token) localStorage.setItem("Authorization", token);
            return response;
        };
        const resErrInterceptor = (error: AxiosError) => {
            // if()
            let responseErrorFactory = new Factory<ResponseError>(ResponseError).getFactory(error.response?.data);
            if (error.response?.data instanceof ArrayBuffer) {
                const data = JSON.parse(new TextDecoder().decode(error.response?.data));
                responseErrorFactory = new Factory<ResponseError>(ResponseError).getFactory(data);
            }
            setAlert(responseErrorFactory);
            // const KEY_ACCESS_TOKEN = (process.env.REACT_APP_HEADERS_ACCESS_TOKEN || "X-Access-Token").toLowerCase();
            // const token = error?.response?.headers[KEY_ACCESS_TOKEN];
            // if (token) {
            //     const homePage = process.env.REACT_APP_HOME_PAGE || "/";
            //     localStorage.setItem("Authorization", token);
            //     navigate(homePage, { replace: true });
            // }
            if (responseErrorFactory.statusCode === HttpStatusCode.Unauthorized) {
                // const unauthorizedPage = process.env.REACT_APP_UNAUTHORIZED_PAGE || "/unauthorized";
                const ssoLoginPage = process.env.REACT_APP_SSO_LOGIN!;
                window.location.replace(ssoLoginPage!);
            }
            setLoading(false);
            return Promise.reject(error);
        };

        instanceRef.interceptors.request.use(reqInterceptor, reqErrInterceptor);

        instanceRef.interceptors.response.use(resInterceptor, resErrInterceptor);
        return instanceRef;
    }, [instanceRef, setAlert, setLoading]);
    return <AxiosContext.Provider value={currentInstance}>{children}</AxiosContext.Provider>;
};

export const useAxios = () => {
    const context = useContext(AxiosContext);
    if (!context) throw new Error("Axios Hooks must be used within AxiosInstanceProvider");
    return context;
};
