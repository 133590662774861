import { Stack, styled } from "@mui/material";
import MuiAvatar from "@mui/material/Avatar";
import AvatarModel from "./Avatar.model";
import AvatarBorder from "./AvatarBorder";
import AvatarContainer from "./AvatarContainer";
import AvatarProfile from "./AvatarProfile";

const AvatarStyled = styled(MuiAvatar)(({ theme }) => ({
    width: 60,
    height: 60,
}));

type AvatarProps = {
    avatar: AvatarModel;
};

// eslint-disable-next-line
const hexToHSL = (hex: string) => {
    let result: RegExpExecArray | null = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) return;
    let r: number = parseInt(result[1], 16);
    let g: number = parseInt(result[2], 16);
    let b: number = parseInt(result[3], 16);
    r /= 255;
    g /= 255;
    b /= 255;
    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let h: number = 0;
    let s: number = 0;
    let l: number = (max + min) / 2;
    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }
    let HSL: { h: number; s: number; l: number } = {
        h: Math.round(360 * h),
        s: Math.round(100 * s),
        l: Math.round(100 * l),
    };
    return HSL;
};

// eslint-disable-next-line
const stringToColor = (string: string) => {
    let hash = 0;

    for (let i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    return color;
};

const stringAvatar = (name: string) => {
    //   const hsl = hexToHSL(stringToColor(name));
    return {
        sx: {
            color: "black",
            bgcolor: "white", //hsl?.h ? `hsl(${hsl?.h}, 70%, 80%)` : `hsl(${Math.round(Math.random() * 360)}, 70%, 80%)`,
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
};

const Avatar = ({ avatar }: AvatarProps) => {
    const getAvatar = () => {
        if (avatar.src) {
            return <AvatarStyled alt={avatar.name} src={avatar.src} />;
        } else {
            return <AvatarStyled {...stringAvatar(avatar.name)} />;
        }
    };
    return (
        <Stack direction="column" width="100%" spacing={2} justifyContent="center" alignItems="center">
            <AvatarProfile name={avatar.name} email={avatar.email} />
            <AvatarContainer>
                <AvatarBorder>{getAvatar()}</AvatarBorder>
            </AvatarContainer>
        </Stack>
    );
};
export default Avatar;
