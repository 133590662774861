import { styled } from "@mui/material";
import MuiListItemIcon, { ListItemIconProps as MuiListItemIconProps } from "@mui/material/ListItemIcon";

interface ListItemIconProps extends MuiListItemIconProps {
  open?: boolean;
}

const ListItemIcon = styled(MuiListItemIcon, { shouldForwardProp: (prop) => prop !== "open" })<ListItemIconProps>(
  ({ theme, open }) => ({
    marginLeft: 0,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(!open && {
      marginLeft: 8,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

export default ListItemIcon;
