import AutorenewIcon from "@mui/icons-material/Autorenew";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Chip, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useState } from "react";
import { useAxios } from "../../../../contexts/axios.context";
import { downloadFile } from "../../../../utils/helper";
import { TemplateRevisionDto } from "../Template/dto/template-revision.dto";
type ChipDownloadTemplateRevisionType = { templateRevision: TemplateRevisionDto };
const ChipDownloadTemplateRevision = ({ templateRevision }: ChipDownloadTemplateRevisionType) => {
    const theme = useTheme();
    const { post } = useAxios();
    const [loading, setLoading] = useState<boolean>(false);
    const onDownloadTemplateRevision = useCallback(
        async (templateRevision: TemplateRevisionDto) => {
            setLoading(true);
            const response = await post("/e2e-template/download/revision", templateRevision, {
                responseType: "arraybuffer",
            });
            const filename = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
            downloadFile(response.data, filename);
            setLoading(false);
        },
        [post]
    );
    return (
        <Chip
            sx={{ width: "100%", justifyContent: "space-between" }}
            variant="outlined"
            avatar={
                <Box
                    sx={{
                        width: 30,
                        height: 30,
                        borderRadius: "50%",
                        backgroundColor: theme.palette.primary.main + "20",
                        display: "flex",
                    }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography
                        sx={{ textAlign: "center" }}
                        color={theme.palette.primary.main}
                        variant="caption"
                        fontWeight={600}
                    >
                        {templateRevision.revision}
                    </Typography>
                </Box>
            }
            key={templateRevision.submitTemplateId}
            label={templateRevision.template?.templateName}
            deleteIcon={
                !loading ? (
                    <DownloadForOfflineIcon />
                ) : (
                    <AutorenewIcon
                        sx={{
                            "&": { animation: "lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite" },
                            cursor: "progress",
                        }}
                    />
                )
            }
            onDelete={async () => !loading && (await onDownloadTemplateRevision(templateRevision))}
        />
    );
};

export default ChipDownloadTemplateRevision;
