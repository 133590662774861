import { styled, Tab as MuiTab, TabProps as MuiTabProps } from "@mui/material";
import { NavigateModule } from "../../../../config/AppRouter/NavigateState";

const Tab = styled(
    MuiTab,
    {}
)<MuiTabProps>(({ theme }) => ({
    color: theme.palette.primary.main,
    // ".Mui-selected": {
    //     color: "white !important",
    // },
}));

export const a11yProps = (modules: NavigateModule, index: number) => {
    return {
        id: `${modules}-tab-${index}`,
        "aria-controls": `${modules}-tabpanel-${index}`,
    };
};

export default Tab;
