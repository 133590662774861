import { ComponentType, useMemo } from "react";
import { useLocation } from "react-router";
import { AppRouter, AppRouters, getAppRouter } from "../../config/AppRouter/AppRouters.menu";
import { NavigateState, NavigateStateType } from "../../config/AppRouter/NavigateState";
import { Factory } from "../../utils/Factory";

export type WithAppRouterProps = {
    pageRouter?: AppRouter;
};
const withAppRouter = (Component: ComponentType<WithAppRouterProps>) => (props: WithAppRouterProps) => {
    const { pathname, state } = useLocation();
    const navigateStateTypeMemo: NavigateStateType | undefined = useMemo(() => {
        if (!state) return undefined;
        let navigateStateFactory = new Factory<NavigateState>(NavigateState).getFactory(state);
        return navigateStateFactory;
    }, [state]);
    const pageRouter: AppRouter | undefined = useMemo(() => {
        if (navigateStateTypeMemo) {
            let appRouter: AppRouter | undefined = getAppRouter(navigateStateTypeMemo);
            if (!appRouter) return undefined;
            return { ...appRouter, ...navigateStateTypeMemo };
        }
        return AppRouters.find(({ to }: AppRouter) => pathname === to);
    }, [pathname, navigateStateTypeMemo]);
    return <Component {...props} pageRouter={pageRouter} />;
};

export default withAppRouter;
