import {styled, InputLabel as MuiInputLabel, InputLabelProps as MuiInputLabelProps} from "@mui/material";

const InputLabel = styled(
    MuiInputLabel,
    {}
)<MuiInputLabelProps>(({theme}) => ({
    color: theme.palette.primary.main,
    "& label": {
        color: theme.palette.primary.main,
        fontWeight: 300,
    },
}));

export default InputLabel;
