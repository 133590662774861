import { ReactNode } from "react";
import { Permissions } from "../../contexts/authorize.context";
import { ScreenType } from "./AppRouters.menu";

export type NavigateType = "management" | "dashboard" | "e2e";
export type NavigateModule = "user" | "role" | "template" | "model";
export type Action = {
    canAccess?: boolean;
    permissions?: Permissions
    label?: ReactNode;
    onClick?: () => void;
}
export type FilterAction = Action & {
    filters?: Array<any>;
    onDeleteFilter?: <T>(key: keyof T) => void;
}
export type NavigateStateType = {
    type: NavigateType;
    modules: NavigateModule;
    screenType: ScreenType;
    label?: string;

    search?: Action
    create?: Action;
    update?: Action;
    remove?: Action;
    filter?: FilterAction;
};

interface NavigateStateConstructor { }

export class NavigateState implements NavigateStateType, NavigateStateConstructor {
    type: NavigateType;
    modules: NavigateModule;
    screenType: ScreenType;

    constructor() {
        this.type = "management";
        this.modules = "user";
        this.screenType = "main";
    }

    NavigateState(type: NavigateType, modules: NavigateModule, screenType: ScreenType) {
        this.type = type;
        this.modules = modules;
        this.screenType = screenType;
    }
}
