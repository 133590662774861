import { styled, RadioGroup as MuiRadioGroup, RadioGroupProps as MuiRadioGroupProps } from "@mui/material";

const RadioGroup = styled(
    MuiRadioGroup,
    {}
)<MuiRadioGroupProps>(({ theme }) => ({
    "& .MuiRadio-root ": {
        color: theme.palette.text.primary,
    },
    "& .Mui-checked ": {
        color: theme.palette.primary.main,
    },
}));

export default RadioGroup;
