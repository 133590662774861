import {styled, Select as MuiSelect, SelectProps as MuiSelectProps} from "@mui/material";

const Select = styled(
    MuiSelect,
    {}
)<MuiSelectProps>(({theme}) => ({
    color: theme.palette.primary.main,
    "& label": {
        color: theme.palette.primary.main,
        fontWeight: 300,
    },
}));

export default Select;
