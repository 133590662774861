import { styled, AvatarGroup as MuiAvatarGroup, AvatarGroupProps as MuiAvatarGroupProps } from "@mui/material";

const AvatarGroup = styled(
    MuiAvatarGroup,
    {}
)<MuiAvatarGroupProps>(({ theme }) => ({
    ".MuiAvatarGroup-avatar": {
        width: 30,
        height: 30,
        fontSize: "0.7rem",
    },
}));

export default AvatarGroup;
