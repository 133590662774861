import { styled } from "@mui/material";
import MuiListItemText, { ListItemTextProps as MuiListItemTextProps } from "@mui/material/ListItemText";

interface ListItemTextProps extends MuiListItemTextProps {
  open?: boolean;
}

const ListItemText = styled(MuiListItemText, {
  shouldForwardProp: (prop) => prop !== "open",
})<ListItemTextProps>(({ theme, open }) => ({
  marginLeft: 120,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  color: theme.palette.text.primary,
  ...(open && {
    marginLeft: 0,
    width: `calc(100% - ${0}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  "& .MuiTypography-root": { fontWeight: 500 },
}));

// transition: ${theme.transitions.create(['background-color', 'transform'], {
//     duration: theme.transitions.duration.standard,
//   })};
//   backgroundColor: theme.palette.primary.main,
//   transition: theme.transitions.create(["background-color", "transform"], {
//     duration: theme.transitions.duration.standard,
//   }),
//   ":hover": {
//     backgroundColor: theme.palette.secondary.main,
//     transform: "scale(1.3)",
//   },
export default ListItemText;
