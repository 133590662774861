import PersonIcon from "@mui/icons-material/Person";
import { Chip, Grid, ListItemText } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { useConfirm } from "material-ui-confirm";
import { ChangeEvent, Fragment, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { FindDataAutoComplete } from "../../../../../classes/find-data-auto-complete.class";
import { ResponseAutoComplete } from "../../../../../classes/response-auto-complete.class";
import { AppRouter } from "../../../../../config/AppRouter/AppRouters.menu";
import { useAlert } from "../../../../../contexts/alert.context";
import { useAxios } from "../../../../../contexts/axios.context";
import { useLoading } from "../../../../../contexts/loading.context";
import { Shape } from "../../../../../utils/ValidateUtils";
import AutoComplete from "../../../../Common/AutoComplete/AutoComplete";
import Loading from "../../../../Common/Loading/Loading";
import Body from "../../../../Common/Page/Body/Body";
import Header from "../../../../Common/Page/Header/Header";
import Status from "../../../../Common/Status/Status";
import Tab, { a11yProps } from "../../../../Common/Tabs/Tab/Tab";
import TabPanel from "../../../../Common/Tabs/TabPanel/TabPanel";
import Tabs from "../../../../Common/Tabs/Tabs";
import TextField from "../../../../Common/TextField/TextField";
import TransferList from "../../../../Common/TransferList/TransferList";
import withAppRouter from "../../../../HoC/withAppRouter";
import withDatasource, { DatasourceHoCPropsType } from "../../../../HoC/withDatasource";
import { useTemplateOption } from "../../../../Template/Template";
import { PermissionDto } from "../../PermissionMgt/dto/permission.dto";
import { UserCriteriaDto } from "../../UserMgt/dto/user-criteria.dto";
import { UserDto } from "../../UserMgt/dto/user.dto";
import { RoleDto } from "../dto/role.dto";

const validationSchema = yup.object<Shape<RoleDto>>({
    name: yup.string().trim().required("Enter role name").max(30, "Role name cannot be longer than 30 characters."),
    description: yup
        .string()
        .trim()
        .required("Enter description")
        .max(500, "Role description cannot be longer than 500 characters."),
});

type RoleMgtDetailProps = DatasourceHoCPropsType<RoleDto> & {
    datasource?: RoleDto;
    isCreate?: boolean;
};

const RoleMgtDetail = ({ pageRouter, datasource, isCreate }: RoleMgtDetailProps) => {
    const templateOption = useTemplateOption();
    const navigate = useNavigate();
    const { setAlert } = useAlert();
    const { loading } = useLoading();
    const { get, post, patch, delete: requestDelete } = useAxios();
    const [value, setValue] = useState(0);
    const confirm = useConfirm();
    const [userOptions, setUserOptions] = useState<Array<UserDto>>([]);
    const [userOptionsIsLoading, setUserOptionsIsLoading] = useState<boolean>(false);
    const [userFinder, setUserFinder] = useState<FindDataAutoComplete<UserCriteriaDto>>(
        new FindDataAutoComplete<UserCriteriaDto>()
    );
    const [permissionOptions, setPermissionOptions] = useState<Array<PermissionDto>>([]);
    const [permissionOptionsIsLoading, setPermissionOptionsIsLoading] = useState<boolean>(false);
    const datasourceMemo = useMemo(() => datasource!, [datasource]);

    useEffect(() => {
        if (!loading) {
            setUserOptionsIsLoading(false);
            setPermissionOptionsIsLoading(false);
        }
    }, [loading]);

    const userOptionsOnSearch = useCallback(({ target: { value: searchString } }: ChangeEvent<HTMLInputElement>) => {
        setUserFinder((p) => ({
            ...p,
            limit: Number(process.env.REACT_APP_AUTO_COMPLETE_LIMIT) || 10,
            criteria: { searchString },
        }));
    }, []);
    const getUserOptionsCallback = useCallback(async () => {
        setUserOptionsIsLoading(true);
        const response = await post<ResponseAutoComplete<Array<UserDto>>>("/user/findAutoComplete", userFinder);
        setUserOptions(response.data.datasource);
    }, [post, userFinder]);

    const getPermissionOptionsCallback = useCallback(async () => {
        setPermissionOptionsIsLoading(true);
        const response = await get<Array<PermissionDto>>("/permission");
        setPermissionOptions(response.data);
    }, [get]);

    useMemo(async () => {
        await getUserOptionsCallback();
        await getPermissionOptionsCallback();
    }, [getUserOptionsCallback, getPermissionOptionsCallback]);

    useEffect(() => console.log({ roleOptions: userOptions, datasource }), [userOptions, datasource]);

    const onFormSubmit = async (values: RoleDto, { setSubmitting }: FormikHelpers<RoleDto>) => {
        if (datasource?.id) {
            await patch(`/role/${datasource.id}`, values);
        } else {
            await post("/role", values);
        }
        setSubmitting(false);
        setAlert({ message: datasource?.id ? "Role Updated!" : "Role Created!", status: "success", statusCode: 200 });
        navigate("/management/role");
    };

    const formik = useFormik<RoleDto>({
        initialValues: {
            name: datasourceMemo?.name || "",
            description: datasourceMemo?.description || "",
            users: datasourceMemo?.users || [],
            isActive: isCreate ? true : datasourceMemo?.isActive,
            permissions: datasourceMemo?.permissions || [],
        },
        onSubmit: onFormSubmit,
        validationSchema,
    });

    const onRemoveCallback = useCallback(async () => {
        confirm({ title: "Remove", description: "Are you sure?" })
            .then(async () => {
                formik.setSubmitting(true);
                await requestDelete(`/role/${datasource?.id}`);
                formik.setSubmitting(false);
                setAlert({ status: "success", statusCode: 200, message: "Remove Success!" });
                navigate("/management/role");
            })
            .catch((error) => {
                formik.setSubmitting(false);
            });
    }, [requestDelete, datasource, confirm, navigate, formik, setAlert]);

    const handleChange = (_: SyntheticEvent, newValue: number) => setValue(newValue);
    const pageRouterMemo: AppRouter | undefined = useMemo(() => {
        if (pageRouter) {
            return {
                ...pageRouter,
                remove: {
                    ...pageRouter.remove,
                    canAccess: datasource?.canDelete,
                    onClick: onRemoveCallback,
                },
            };
        }
    }, [pageRouter, onRemoveCallback, datasource]);

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Header
                    withHeaderContent
                    withManagementTools
                    templateOption={templateOption}
                    icon={PersonIcon}
                    pageRouter={pageRouterMemo}
                    top="16px"
                    loading={formik.isSubmitting}
                />
                <Body templateOption={templateOption}>
                    <Fragment>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="user detail tabs"
                            sx={{ borderBottom: 1, borderColor: "divider" }}
                        >
                            <Tab label="Role Detail" {...a11yProps("role", 0)} />
                            <Tab label="Permissions" {...a11yProps("role", 1)} />
                        </Tabs>

                        <TabPanel value={value} index={0} modules="role">
                            {formik.isSubmitting ? (
                                <Loading />
                            ) : (
                                <Fragment>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="name"
                                                name="name"
                                                label="Role Name"
                                                size="small"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                error={formik.touched.name && Boolean(formik.errors.name)}
                                                helperText={formik.touched.name && formik.errors.name}
                                                InputProps={{
                                                    endAdornment: (
                                                        <Status
                                                            onClick={() =>
                                                                formik.setFieldValue(
                                                                    "isActive",
                                                                    !formik.values.isActive
                                                                )
                                                            }
                                                            status={formik.values.isActive ? "success" : "default"}
                                                            label={formik.values.isActive ? "Active" : "Inactive"}
                                                        />
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="description"
                                                name="description"
                                                label="Role Description"
                                                size="small"
                                                value={formik.values.description}
                                                onChange={formik.handleChange}
                                                error={formik.touched.description && Boolean(formik.errors.description)}
                                                helperText={formik.touched.description && formik.errors.description}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AutoComplete
                                                textFieldLabel="Users"
                                                textFieldPlaceholder="users"
                                                id="users"
                                                loading={userOptionsIsLoading}
                                                onSearchString={userOptionsOnSearch}
                                                options={userOptions}
                                                finderCallback={setUserFinder}
                                                getOptions={getUserOptionsCallback}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={(option: UserDto) => option.email!}
                                                getOptionDisabled={(option: UserDto) => !option.isActive}
                                                renderTags={(value: Array<UserDto>, getTagProps) =>
                                                    value.map((option: UserDto, index: number) => (
                                                        <Chip
                                                            size="small"
                                                            variant={option.isActive ? "outlined" : "filled"}
                                                            label={option.email}
                                                            {...getTagProps({ index })}
                                                            color={option.isActive ? "success" : "error"}
                                                        />
                                                    ))
                                                }
                                                value={formik.values.users}
                                                onChange={(_e, v) => formik.setFieldValue("users", v)}
                                                error={formik.touched.users && Boolean(formik.errors.users)}
                                                helperText={formik.touched.users && formik.errors.users}
                                            />
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}
                        </TabPanel>
                        <TabPanel value={value} index={1} modules="role">
                            {formik.isSubmitting ? (
                                <Loading />
                            ) : (
                                <Fragment>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TransferList
                                                keyIndex="id"
                                                searchKey={["description", "code"]}
                                                datasource={permissionOptions}
                                                selected={formik.values.permissions}
                                                onChange={async (selected: Array<PermissionDto>) =>
                                                    await formik.setFieldValue("permissions", selected)
                                                }
                                                loading={permissionOptionsIsLoading}
                                                renderOption={({ id, code, description }: PermissionDto) => (
                                                    <ListItemText
                                                        id={`${id}`}
                                                        primary={
                                                            <Fragment>
                                                                <b>{code}</b> - <span>{description}</span>
                                                            </Fragment>
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}
                        </TabPanel>
                    </Fragment>
                </Body>
            </form>
        </Fragment>
    );
};
export default withAppRouter(
    withDatasource({ url: "/role/:id", keys: ["id"], navigateModule: "role", navigateType: "management" })(
        RoleMgtDetail
    )
);
