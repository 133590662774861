import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from "@mui/lab";
import { useCallback, useState } from "react";
import { useAxios } from "../../../../contexts/axios.context";
import { downloadFile } from "../../../../utils/helper";
import { ModelDto } from "./dto/model.dto";

type ModelDownloaderType = {
    model: ModelDto;
};
const ModelDownloader = ({ model }: ModelDownloaderType) => {
    const { post } = useAxios();
    const [loading, setLoading] = useState<boolean>(false);
    const onDownloadModelOutput = useCallback(
        async (queueModelId: number) => {
            setLoading(true);
            const response = await post(
                "/e2e-model/download",
                { queueModelId },
                {
                    responseType: "arraybuffer",
                }
            );
            const filename = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
            downloadFile(response.data, filename, "applicaiton/zip");
            setLoading(false);
        },
        [post]
    );
    return (
        <LoadingButton
            loading={loading}
            variant="contained"
            color="success"
            disabled={model.status !== "COMPLETED"}
            sx={{ textTransform: "none" }}
            endIcon={<DownloadIcon />}
            onClick={() => onDownloadModelOutput(model.queueModelId!)}
        >
            Download
        </LoadingButton>
    );
};

export default ModelDownloader;
