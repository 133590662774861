import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
    Box,
    BoxProps,
    Chip,
    ChipProps as MuiChipProps,
    Divider,
    IconButton,
    Stack,
    StandardTextFieldProps as MuiStandardTextFieldProps,
    styled,
    Typography,
} from "@mui/material";
import { ReactNode, useMemo } from "react";
import { AppRouter } from "../../../../config/AppRouter/AppRouters.menu";
import { checkStatusLabel } from "../../../../utils/helper";
import { Color } from "../../types";
type FilterRender<T> = (t: Filter<T>) => ReactNode;
export type Filter<T> = {
    key: keyof T;
    label: string;
    value: T | any;
    render: FilterRender<T> | ReactNode;
    color?: Color;
    canDelete?: boolean;
};
export type Filters<T> = Array<Filter<T>>;

const FiltersContainerStyled = styled("div")(({ theme }) => ({
    overflowX: "auto",
    display: "flex",
}));
const FilterItemsContainerStyled = styled("div")(({ theme }) => ({
    flex: "10 0 auto",
    flexWrap: "nowrap",
}));
const FilterItemStyled = styled(Chip)(({ theme }) => ({
    "&:not(:first-of-type)": {
        marginLeft: 2,
    },
}));
const HeaderBoxFilterStyled = styled(Box)<HeaderBoxFilterStyledProps>(({ theme, open, filtering }) => ({
    width: "100%",
}));

interface HeaderBoxFilterStyledProps extends BoxProps {
    open: boolean;
    filtering?: "onFilter";
}
type HeaderFilterProps<T> = MuiStandardTextFieldProps & {
    open: boolean;
    isFiltering?: boolean;
    filters?: Filters<T>;
    pageRouter?: AppRouter;
};
const HeaderFilter = <T extends Object>({
    open,
    isFiltering,
    filters = [],
    pageRouter,
    ...props
}: HeaderFilterProps<T>) => {
    const filterMemo = useMemo(() => pageRouter?.filter, [pageRouter]);

    return (
        <HeaderBoxFilterStyled open={open} filtering={isFiltering ? "onFilter" : undefined}>
            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                <Typography sx={{ marginLeft: 2, fontWeight: "bold" }} variant="overline">
                    Filter
                </Typography>
                <Divider orientation="vertical" flexItem />
                <FiltersContainerStyled>
                    <FilterItemsContainerStyled>
                        {filters.map((filter) => (
                            <FilterItemStyled
                                key={filter.key as string}
                                variant="outlined"
                                size="medium"
                                label={
                                    <>
                                        <b>{filter.label}&nbsp;:&nbsp;</b>
                                        {typeof filter.render === "function" ? filter.render(filter) : filter.render}
                                    </>
                                }
                                color="success"
                                onDelete={
                                    filter.canDelete
                                        ? () => filterMemo?.onDeleteFilter && filterMemo.onDeleteFilter(filter.key)
                                        : undefined
                                }
                            />
                        ))}
                    </FilterItemsContainerStyled>
                </FiltersContainerStyled>
                <IconButton color="warning" sx={{ margin: "0px !important" }} onClick={filterMemo?.onClick}>
                    <AddCircleIcon />
                </IconButton>
            </Stack>
        </HeaderBoxFilterStyled>
    );
};

type HeaderFilterStatusType = MuiChipProps & {
    status?: boolean | "";
    activeLabel?: string;
    inactiveLabel?: string;
};

export const HeaderFilterStatus = ({ status, activeLabel, inactiveLabel }: HeaderFilterStatusType) => {
    return (
        <Chip
            variant="filled"
            size="small"
            label={checkStatusLabel(status, activeLabel, inactiveLabel)}
            color="success"
        />
    );
};

export default HeaderFilter;
