import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PersonIcon from "@mui/icons-material/Person";
import { Chip, CircularProgress, Grid, Stack } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { useConfirm } from "material-ui-confirm";
import { ChangeEvent, Fragment, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { FindDataAutoComplete } from "../../../../../classes/find-data-auto-complete.class";
import { ResponseAutoComplete } from "../../../../../classes/response-auto-complete.class";
import { AppRouter } from "../../../../../config/AppRouter/AppRouters.menu";
import { INPUT_DELAY } from "../../../../../config/environment.default";
import { useAlert } from "../../../../../contexts/alert.context";
import { useAxios } from "../../../../../contexts/axios.context";
import { useLoading } from "../../../../../contexts/loading.context";
import { Shape } from "../../../../../utils/ValidateUtils";
import AutoComplete from "../../../../Common/AutoComplete/AutoComplete";
import Loading from "../../../../Common/Loading/Loading";
import Body from "../../../../Common/Page/Body/Body";
import Header from "../../../../Common/Page/Header/Header";
import Status from "../../../../Common/Status/Status";
import Tab, { a11yProps } from "../../../../Common/Tabs/Tab/Tab";
import TabPanel from "../../../../Common/Tabs/TabPanel/TabPanel";
import Tabs from "../../../../Common/Tabs/Tabs";
import TextField from "../../../../Common/TextField/TextField";
import withAppRouter from "../../../../HoC/withAppRouter";
import withDatasource, { DatasourceHoCPropsType } from "../../../../HoC/withDatasource";
import { useTemplateOption } from "../../../../Template/Template";
import { RoleCriteriaDto } from "../../RoleMgt/dto/role-criteria.dto";
import { RoleDto } from "../../RoleMgt/dto/role.dto";
import { UserVerificationDto } from "../dto/user-verification.dto";
import { UserDto } from "../dto/user.dto";

type UserMgtDetailProps = DatasourceHoCPropsType<UserDto> & {
    datasource?: UserDto;
    isCreate?: boolean;
};

const UserMgtDetail = ({ pageRouter, datasource, isCreate, navigate: navigateProtector }: UserMgtDetailProps) => {
    const templateOption = useTemplateOption();
    const navigate = useNavigate();
    const { setAlert } = useAlert();
    const { loading } = useLoading();
    const { post, patch, delete: requestDelete } = useAxios();
    const [value, setValue] = useState(0);
    const confirm = useConfirm();
    const [roleOptions, setRoleOptions] = useState<Array<RoleDto>>([]);
    const [roleOptionsIsLoading, setRoleOptionsIsLoading] = useState<boolean>(false);
    const [roleFinder, setRoleFinder] = useState<FindDataAutoComplete<RoleCriteriaDto>>(
        new FindDataAutoComplete<RoleCriteriaDto>()
    );
    const [emailIsVerifying, setEmailIsVerifying] = useState<boolean>(false);
    const [emailIsPassed, setEmailIsPassed] = useState<boolean>(isCreate ? false : true);

    useMemo(() => navigateProtector && navigateProtector(), [navigateProtector]);

    const datasourceMemo = useMemo(() => datasource!, [datasource]);

    useEffect(() => {
        if (!loading) setRoleOptionsIsLoading(false);
    }, [loading]);

    const roleOptionsOnSearch = useCallback(({ target: { value: searchString } }: ChangeEvent<HTMLInputElement>) => {
        setRoleFinder((p) => ({
            ...p,
            limit: Number(process.env.REACT_APP_AUTO_COMPLETE_LIMIT) || 10,
            criteria: { searchString },
        }));
    }, []);
    const getRoleOptionsCallback = useCallback(async () => {
        setRoleOptionsIsLoading(true);
        const response = await post<ResponseAutoComplete<Array<RoleDto>>>("/role/findAutoComplete", roleFinder);
        setRoleOptions(response.data.datasource);
    }, [post, roleFinder]);

    useMemo(async () => {
        await getRoleOptionsCallback();
    }, [getRoleOptionsCallback]);

    useEffect(() => console.log({ roleOptions, datasource }), [roleOptions, datasource]);

    const validationSchema = yup.object<Shape<UserDto>>({
        email: yup.string().trim().required("Enter your email").email("Not a proper email."),
        firstName: yup.string().trim().required("Enter your first name"),
        lastName: yup.string().trim().required("Enter your last name"),
        roles: yup.array().test({
            message: "Some role has been inactive.",
            test: (roles) => roles?.filter((role: RoleDto) => !role.isActive).length === 0,
        }),
    });

    const onFormSubmit = async (values: UserDto, { setSubmitting }: FormikHelpers<UserDto>) => {
        if (datasource?.id) {
            await patch(`/user/${datasource.id}`, values);
        } else {
            await post("/user", values);
        }
        setSubmitting(false);
        setAlert({ message: datasource?.id ? "User Updated!" : "User Created!", status: "success", statusCode: 200 });
        navigate("/management/user");
    };

    const formik = useFormik<UserDto>({
        initialValues: {
            firstName: datasourceMemo?.firstName || "",
            lastName: datasourceMemo?.lastName || "",
            email: datasourceMemo?.email || "",
            roles: datasourceMemo?.roles || [],
            isActive: isCreate ? true : datasourceMemo?.isActive,
        },
        onSubmit: onFormSubmit,
        validationSchema,
    });

    const onRemoveCallback = useCallback(async () => {
        confirm({ title: "Remove", description: "Are you sure?" })
            .then(async () => {
                formik.setSubmitting(true);
                await requestDelete(`/user/${datasource?.id}`);
                formik.setSubmitting(false);
                setAlert({ status: "success", statusCode: 200, message: "Remove Success!" });
                navigate("/management/user");
            })
            .catch((error) => {
                console.log(error);
            });
    }, [requestDelete, datasource, confirm, navigate, formik, setAlert]);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const pageRouterMemo: AppRouter | undefined = useMemo(() => {
        if (pageRouter) {
            return {
                ...pageRouter,
                remove: {
                    ...pageRouter.remove,
                    onClick: onRemoveCallback,
                },
            };
        }
    }, [pageRouter, onRemoveCallback]);

    const validateEmailCallback = useCallback(
        async (email?: string) => {
            await formik.setFieldValue("email", email, true);
            const emailIsValid = validationSchema.pick(["email"]).isValidSync({ email });
            setEmailIsPassed(false);
            if (emailIsValid) {
                setEmailIsVerifying(true);
                const response = await post<UserVerificationDto>("/auth/user/verification", { email }, {});
                const userVerification = response.data;
                setEmailIsVerifying(false);
                if (!userVerification.isVerifyPass) {
                    formik.setFieldError("email", "This email can't create user.");
                    return;
                }
                if (!userVerification.canCreate) {
                    formik.setFieldError("email", "This email already exists.");
                    return;
                }
                if (userVerification.userDetail) {
                    setEmailIsPassed(true);
                    const { firstName, lastName, email }: UserDto = userVerification.userDetail;
                    await formik.setValues({ firstName, lastName, email, roles: [], isActive: true }, true);
                }
            }
        },
        [post, formik, validationSchema, setEmailIsPassed]
    );

    const emailOnChangeCallback = useCallback(
        async ({ target: { value: email } }: ChangeEvent<HTMLInputElement>) => {
            await formik.setValues({ firstName: "", lastName: "", email, roles: [], isActive: true }, false);
            await validateEmailCallback(email);
        },
        [formik, validateEmailCallback]
    );

    return (
        <Fragment>
            <form
                onSubmit={async (formEvent) => {
                    formEvent.preventDefault();
                    await formik.setTouched({ email: true, roles: true }, true);
                    if (isCreate) await validateEmailCallback(formik.values.email);
                    if (emailIsPassed) formik.handleSubmit(formEvent);
                }}
            >
                <Header
                    withHeaderContent
                    withManagementTools
                    templateOption={templateOption}
                    icon={PersonIcon}
                    pageRouter={pageRouterMemo}
                    top="16px"
                    loading={formik.isSubmitting || emailIsVerifying}
                />
                <Body templateOption={templateOption}>
                    <Fragment>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="user detail tabs"
                            sx={{ borderBottom: 1, borderColor: "divider" }}
                        >
                            <Tab label="User Detail" {...a11yProps("user", 0)} />
                        </Tabs>

                        <TabPanel value={value} index={0} modules="user">
                            {formik.isSubmitting ? (
                                <Loading />
                            ) : (
                                <Fragment>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="email"
                                                name="email"
                                                label="Email"
                                                size="small"
                                                delay={INPUT_DELAY}
                                                value={formik.values.email}
                                                onChange={emailOnChangeCallback}
                                                onClick={() =>
                                                    formik.setTouched({ ...formik.touched, email: true }, false)
                                                }
                                                error={formik.touched.email && Boolean(formik.errors.email)}
                                                helperText={formik.touched.email && formik.errors.email}
                                                disabled={!isCreate || emailIsVerifying}
                                                InputProps={{
                                                    endAdornment: (
                                                        <Stack spacing={2} direction="row" alignItems="center">
                                                            {emailIsVerifying && <CircularProgress size={20} />}
                                                            {!emailIsVerifying && emailIsPassed ? (
                                                                <CheckCircleIcon color="success" />
                                                            ) : undefined}
                                                            <Status
                                                                onClick={() =>
                                                                    formik.setFieldValue(
                                                                        "isActive",
                                                                        !formik.values.isActive
                                                                    )
                                                                }
                                                                status={formik.values.isActive ? "success" : "default"}
                                                                label={formik.values.isActive ? "Active" : "Inactive"}
                                                            />
                                                        </Stack>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="firstName"
                                                name="firstName"
                                                label="First Name"
                                                size="small"
                                                value={formik.values.firstName}
                                                onChange={formik.handleChange}
                                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                                helperText={formik.touched.firstName && formik.errors.firstName}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="lastName"
                                                name="lastName"
                                                label="Last Name"
                                                size="small"
                                                value={formik.values.lastName}
                                                onChange={formik.handleChange}
                                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                                helperText={formik.touched.lastName && formik.errors.lastName}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AutoComplete
                                                textFieldLabel="Roles"
                                                textFieldPlaceholder="roles"
                                                id="roles"
                                                loading={roleOptionsIsLoading}
                                                onSearchString={roleOptionsOnSearch}
                                                options={roleOptions}
                                                finderCallback={setRoleFinder}
                                                getOptions={getRoleOptionsCallback}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={(option: RoleDto) => option.name!}
                                                getOptionDisabled={(option: RoleDto) => !option.isActive}
                                                renderTags={(value: Array<RoleDto>, getTagProps) =>
                                                    value.map((option: RoleDto, index: number) => (
                                                        <Chip
                                                            size="small"
                                                            variant={option.isActive ? "outlined" : "filled"}
                                                            label={option.name}
                                                            {...getTagProps({ index })}
                                                            color={option.isActive ? "success" : "error"}
                                                        />
                                                    ))
                                                }
                                                value={formik.values.roles}
                                                onChange={async (_e, v) => {
                                                    await formik.setFieldValue("roles", v, true);
                                                    if (!emailIsPassed)
                                                        await validateEmailCallback(formik.values.email);
                                                }}
                                                error={formik.touched.roles && Boolean(formik.errors.roles)}
                                                helperText={formik.touched.roles && formik.errors.roles}
                                            />
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}
                        </TabPanel>
                    </Fragment>
                </Body>
            </form>
        </Fragment>
    );
};
export default withAppRouter(
    withDatasource({ url: "/user/:id", keys: ["id"], navigateModule: "user", navigateType: "management" })(
        UserMgtDetail
    )
);
