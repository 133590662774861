import { Context, createContext, PropsWithChildren, useContext, useState } from "react";
import {} from "axios";
import { HttpStatusCode } from "../config/Enum/HttpStatusCode";

export type AlertType = {
    statusCode?: HttpStatusCode;
    message?: string;
    status?: "success" | "warning" | "info" | "error";
};

type AlertContextType = {
    alert?: AlertType;
    setAlert: React.Dispatch<React.SetStateAction<AlertType | undefined>>;
};
const ErrorHandleContext: Context<AlertContextType | null> = createContext<AlertContextType | null>(null);

export const AlertProvider = ({ children }: PropsWithChildren<{}>) => {
    const [alert, setAlert] = useState<AlertType | undefined>(undefined);
    return <ErrorHandleContext.Provider value={{ alert, setAlert }}>{children}</ErrorHandleContext.Provider>;
};

export const useAlert = () => {
    const context = useContext(ErrorHandleContext);
    if (!context) throw new Error("Alert Hooks must be used within Alert Provider");
    return context;
};
