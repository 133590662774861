import { Context, createContext, PropsWithChildren, useContext, useState } from "react";

type LoadingContextType = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
const LoadingContext: Context<LoadingContextType | null> = createContext<LoadingContextType | null>(null);

export const LoadingProvider = ({ children }: PropsWithChildren<{}>) => {
    const [loading, setLoading] = useState<boolean>(false);
    return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>;
};

export const useLoading = () => {
    const context = useContext(LoadingContext);
    if (!context) throw new Error("Loading Hooks must be used within Provider");
    return context;
};
