import { ComponentType, PropsWithChildren, useMemo } from "react";
import { useLocation } from "react-router";
import { useAuthoize } from "../../contexts/authorize.context";
import { useAxios } from "../../contexts/axios.context";
import { JWTUtils } from "../../utils/JWT";
import { CredentialJWT } from "../Authenticate/dto/crendential-jwt.dto";
import { PayloadJWT } from "../Authenticate/dto/payload-jwt.dto";

const withAuthorized = (Component: ComponentType<PropsWithChildren>) => (props: PropsWithChildren) => {
    const location = useLocation();
    const { post } = useAxios();
    const { setIsAuth, setPermissions, setUserProfile } = useAuthoize();
    useMemo(async () => {
        const token = localStorage.getItem("Authorization");
        const ssoLoginPage = process.env.REACT_APP_SSO_LOGIN;
        if (!token) {
            window.location.replace(ssoLoginPage!);
            return;
        }
        const jwt = new JWTUtils(token);
        const jwtIsExpired = jwt.isExpired();
        if (jwtIsExpired) {
            window.location.replace(ssoLoginPage!);
            return;
        }
        const currentToken = (await post<CredentialJWT>("/auth/me")).data.token;
        if (!currentToken) {
            window.location.replace(ssoLoginPage!);
            return;
        }
        localStorage.setItem("Authorization", currentToken);
        const payload: PayloadJWT = new JWTUtils(currentToken).extractPayload<PayloadJWT>();
        setIsAuth(true);
        setPermissions(payload.permissions);
        setUserProfile({ email: payload.email, fullName: payload.fullName, photo: payload.photo });
        console.debug({ pathname: location.pathname });
    }, [setIsAuth, setPermissions, setUserProfile, post, location.pathname]);

    return <Component {...props} />;
};

export default withAuthorized;
