import { PropsWithChildren, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { Permissions, useAuthoize } from "../../../contexts/authorize.context";
import Authenticate from "../../Authenticate/Authenticate";

type ProtectedRouteType = {
    permissions: Permissions;
    children: JSX.Element;
};
const ProtectedRoute = ({ permissions: routePermissions, children }: PropsWithChildren<ProtectedRouteType>) => {
    const navigate = useNavigate();
    const { permissions } = useAuthoize();
    const isAuthorized = useMemo(() => {
        if (!routePermissions) throw new Error("Protected Route is required permission at least 1 code.");
        if (Array.isArray(routePermissions)) {
            return routePermissions.map<boolean>((permission) => routePermissions.includes(permission)).every((r) => r);
        }
        if (permissions?.includes(routePermissions)) {
            return true;
        }
    }, [permissions, routePermissions]);
    useEffect(() => {
        if (!isAuthorized) {
            navigate(process.env.REACT_APP_UNAUTHORIZED_PAGE || "/unauthorized", { replace: true });
        }
    }, [isAuthorized, navigate]);
    if (!isAuthorized) {
        return <Authenticate />;
    }
    return children;
};

export default ProtectedRoute;
