import { createTheme, PaletteMode } from "@mui/material";
import { deepOrange, grey } from "@mui/material/colors";

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    ...(mode === "light" ? lightTheme.palette : darkTheme.palette),
  },
});
// Primary #186FA5
// Warning #FD9B1C

const lightTheme = createTheme({
  palette: {
    primary: {
      light: "#186FA5",
      main: "#186FA5",
      dark: "#0E405E",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FFF",
      main: "#FFF",
      dark: "#5C380A",
      contrastText: "#fff",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    text: {
      primary: "#3a5159",
      secondary: "#FFF",
      disabled: "#3a515980",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    primary: deepOrange,
    divider: deepOrange[700],
    background: {
      default: deepOrange[900],
      paper: deepOrange[900],
    },
    text: {
      primary: "#fff",
      secondary: grey[500],
    },
  },
});
