import { Fragment } from "react";
import Header from "../../Common/Page/Header/Header";
import { WithAppRouterProps } from "../../HoC/withAppRouter";
import { useTemplateOption } from "../../Template/Template";

type UnauthorizedProps = WithAppRouterProps & {};
const Unauthorized = ({ pageRouter }: UnauthorizedProps) => {
    const templateOption = useTemplateOption();
    return (
        <Fragment>
            <Header templateOption={templateOption} pageRouter={pageRouter} />
            <p>Unauthorized</p>
        </Fragment>
        // <div style={{ display: "flex", width: "100%", height: "100%" }}>
        // </div>
    );
};

export default Unauthorized;
