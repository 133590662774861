import { Autocomplete } from "@mui/lab";
import { AutocompleteRenderGetTagProps, CircularProgress } from "@mui/material";
import {
    ChangeEvent,
    Dispatch,
    Fragment,
    ReactNode,
    SetStateAction,
    SyntheticEvent,
    useCallback,
    useState,
} from "react";
import TextField from "../../Common/TextField/TextField";
import Typography from "../Typography/Typography";

type AutoCompleteType<T, C> = {
    id?: string;
    getOptions?: () => void;
    onSearchString?: (event: ChangeEvent<HTMLInputElement>) => void;
    options: Array<T>;
    loading?: boolean;
    isOptionEqualToValue?: (option: T, value: T) => boolean;
    getOptionLabel?: (option: T) => string;
    getOptionDisabled?: (option: T) => boolean;
    renderTags?: (value: Array<T>, getTagProps: AutocompleteRenderGetTagProps, ownerState: any) => ReactNode;
    value?: Array<T> | T | null;
    defaultValue?: Array<T> | T | null;
    finderCallback: Dispatch<SetStateAction<C>>;
    onChange: (event: SyntheticEvent, value: T | Array<T> | null, reason: string) => void;
    error?: boolean;
    helperText?: ReactNode;
    disabled?: boolean;
    textFieldLabel?: string;
    multiple?: boolean;
    textFieldPlaceholder?: string;
};
const AutoComplete = <T extends Object, C extends { limit: number; criteria?: U }, U = any>({
    id,
    getOptions,
    options,
    loading,
    onSearchString,
    isOptionEqualToValue,
    getOptionLabel,
    getOptionDisabled,
    renderTags,
    value,
    defaultValue,
    finderCallback,
    onChange,
    error,
    helperText,
    disabled,
    textFieldLabel,
    textFieldPlaceholder,
    multiple = true,
}: AutoCompleteType<T, C>) => {
    const [scrollCounting, setScrollCouting] = useState<number>(2);
    const onChangeCallback = useCallback(
        (event: SyntheticEvent<Element, Event>, value: T[] | T | null, reason: string) => {
            onChange(event, value, reason);
            finderCallback((p) => ({
                ...p,
                limit:
                    (Number(process.env.REACT_APP_AUTO_COMPLETE_LIMIT) || 10) +
                    (Array.isArray(value) ? value.length : 1),
                criteria: { ...p.criteria, searchString: null },
            }));
        },
        [onChange, finderCallback]
    );

    const onBlurCallback = useCallback(() => {
        finderCallback((p) => ({
            ...p,
            criteria: { ...p.criteria, searchString: null },
        }));
    }, [finderCallback]);

    return (
        <Autocomplete
            onOpen={getOptions}
            loading={loading}
            multiple={multiple}
            id={id}
            size="small"
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel}
            getOptionDisabled={getOptionDisabled}
            value={value}
            defaultValue={defaultValue}
            options={options}
            onChange={onChangeCallback}
            onBlur={onBlurCallback}
            disabled={disabled}
            disableCloseOnSelect
            filterSelectedOptions
            loadingText={<Typography color="black">Loading...</Typography>}
            noOptionsText={<Typography color="black">No Options</Typography>}
            renderTags={renderTags}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={textFieldLabel}
                    placeholder={textFieldPlaceholder}
                    onChange={onSearchString}
                    error={error}
                    helperText={helperText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
            ListboxProps={{
                onScroll: (event) => {
                    const listboxNode = event.currentTarget;
                    const scrollTop = Math.floor(listboxNode.scrollTop + listboxNode.clientHeight);
                    const CONFIG_LIMIT = Number(process.env.REACT_APP_AUTO_COMPLETE_LIMIT);
                    if (scrollTop === listboxNode.scrollHeight || scrollTop === listboxNode.scrollHeight - 1) {
                        setScrollCouting((p) => p + 1);
                        finderCallback((p) => ({ ...p, limit: CONFIG_LIMIT * scrollCounting }));
                    }
                },
            }}
        />
    );
};
export default AutoComplete;
