import AutorenewIcon from "@mui/icons-material/Autorenew";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormLabel,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import axios from "axios";
import { Dayjs } from "dayjs";
import { EventSourcePolyfill } from "event-source-polyfill";
import { FormikHelpers, useFormik } from "formik";
import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { FindDataAutoComplete } from "../../../../classes/find-data-auto-complete.class";
import { ResponseAutoComplete } from "../../../../classes/response-auto-complete.class";
import { AppRouter } from "../../../../config/AppRouter/AppRouters.menu";
import { useAlert } from "../../../../contexts/alert.context";
import { useAuthoize } from "../../../../contexts/authorize.context";
import { useAxios } from "../../../../contexts/axios.context";
import { useLoading } from "../../../../contexts/loading.context";
import { useScreen } from "../../../../contexts/screen.context";
import dayjs from "../../../../utils/dayjs";
import { downloadFile, getColorOfStatusType, getStatusTypeLabel } from "../../../../utils/helper";
import { Shape } from "../../../../utils/ValidateUtils";
import AutoComplete from "../../../Common/AutoComplete/AutoComplete";
import DataTable, { Columns } from "../../../Common/DataTable/DataTable";
import FormControl from "../../../Common/FormControl/FormControl";
import Body from "../../../Common/Page/Body/Body";
import Header from "../../../Common/Page/Header/Header";
import ProtectedComponent, { verifyAuthorize } from "../../../Common/ProtectedComponent/ProtectedComponent";
import TextField from "../../../Common/TextField/TextField";
import Typography from "../../../Common/Typography/Typography";
import { WithAppRouterProps } from "../../../HoC/withAppRouter";
import { useTemplateOption } from "../../../Template/Template";
import { TemplateDownloadDto } from "./dto/template-download.dto";
import { TemplateEventDto } from "./dto/template-event.dto";
import { TemplateRequestDto } from "./dto/template-request.dto";
import { TemplateRevisionCriteriaDto } from "./dto/template-revision-criteria.dto";
import { TemplateRevisionDto } from "./dto/template-revision.dto";
import { TemplateDto } from "./dto/template.dto";

type TemplateProps = WithAppRouterProps & {};
const Template = ({ pageRouter }: TemplateProps) => {
    const { post } = useAxios();
    const { setScreen } = useScreen();
    const { setAlert } = useAlert();
    const { loading } = useLoading();
    const templateOption = useTemplateOption();
    const { permissions } = useAuthoize();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    const [datasource, setDatasource] = useState<Array<TemplateDto>>([]);
    const [datasourceIsLoading, setDatasourceIsLoading] = useState<boolean>(false);
    const [eventSource, setEventSource] = useState<EventSourcePolyfill | null>(null);
    const [openRequestDialog, setOpenRequestDialog] = useState<boolean>(false);
    const [openDownloadTemplateRevisionDialog, setOpenDownloadTemplateRevisionDialog] = useState<boolean>(false);
    const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);
    const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
    const [templateRevisionOptions, setTemplateRevisionOptions] = useState<Array<TemplateRevisionDto>>([]);
    const [templateRevisionOptionsIsLoading, setTemplateRevisionOptionsIsLoading] = useState<boolean>(false);
    const [templateRevisionFinder, setTemplateRevisionFinder] = useState<
        FindDataAutoComplete<TemplateRevisionCriteriaDto>
    >(new FindDataAutoComplete<TemplateRevisionCriteriaDto>({ searchString: "", templateId: undefined }));
    const [refreshDatasource, setRefreshDatasource] = useState<boolean>(false);
    const axiosSource = useMemo(() => axios.CancelToken.source(), []);

    useEffect(() => {
        if (!loading) {
            setTemplateRevisionOptionsIsLoading(false);
            setIsDownloadingFile(false);
            setIsUploadingFile(false);
        }
    }, [loading]);

    useEffect(() => {
        setScreen({ code: "DSMGT01001", name: "Template Function" });
        return () => setScreen(undefined);
    }, [setScreen]);

    const getTemplates = useCallback(async () => {
        const response = await post<Array<TemplateDto>>("/e2e-template/find/exists", null, {
            cancelToken: axiosSource.token,
        });
        setDatasource(response.data);
        setRefreshDatasource(false);
        setDatasourceIsLoading(false);
    }, [post, setDatasource, axiosSource]);
    useEffect(() => {
        if (refreshDatasource) {
            getTemplates();
        }
    }, [getTemplates, refreshDatasource]);
    useMemo(async () => {
        setDatasourceIsLoading(true);
        await getTemplates();
    }, [getTemplates]);

    useEffect(() => {
        if ("EventSource" in window) {
            const jwt = localStorage.getItem("Authorization");
            const url = `${process.env.REACT_APP_API}/e2e-template/find/exists/events`;
            setEventSource(
                new EventSourcePolyfill(url, { headers: { Authorization: `Bearer ${jwt}` }, heartbeatTimeout: 300000 })
            );
        }
    }, []);
    useEffect(() => {
        if (eventSource) {
            eventSource.onmessage = (e) => {
                const refresh = (JSON.parse(e.data) as TemplateEventDto).refresh || false;
                setRefreshDatasource(refresh);
            };
            eventSource.onerror = () => {
                // eventSource.close();
            };
        }

        return () => eventSource?.close();
    }, [eventSource]);
    useEffect(() => {
        return () => axiosSource && axiosSource.cancel();
    }, [axiosSource]);

    const uploadCallback = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>, templateId?: number) => {
            setIsUploadingFile(true);
            const file = event.currentTarget.files?.item(0);
            if (!file || !templateId) return;
            const formData = new FormData();
            formData.append("templateId", templateId.toString());
            formData.append("file", file);
            try {
                await post("/e2e-template/upload", formData);
                setAlert({ status: "success", statusCode: 200, message: "Upload Success!" });
            } catch {
            } finally {
                setIsUploadingFile(false);
            }
        },
        [post, setAlert]
    );
    const downloadCallback = useCallback(
        async (templateId?: number) => {
            setIsDownloadingFile(true);
            const response = await post("/e2e-template/download", { templateId }, { responseType: "arraybuffer" });
            const filename = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
            downloadFile(response.data, filename);
            setIsDownloadingFile(false);
        },
        [post]
    );

    // Request (Renew) Form
    const onRequestFormSubmit = async (
        values: TemplateRequestDto,
        { setSubmitting }: FormikHelpers<TemplateRequestDto>
    ) => {
        const response = await post("/e2e-template/request/latest", values);
        setDatasource(response.data);
        setRefreshDatasource(true);
        setSubmitting(false);
        onRequestFormReset();
    };

    const requestFormik = useFormik<TemplateRequestDto>({
        initialValues: { templateId: undefined, initialDate: dayjs().format("YYYY-MM-DD") },
        onSubmit: onRequestFormSubmit,
    });
    const requestCallback = useCallback(
        async (templateId?: number) => {
            await requestFormik.setFieldValue("templateId", templateId);
            setOpenRequestDialog(true);
        },
        [requestFormik]
    );

    const onRequestFormReset = () => {
        requestFormik.resetForm();
        setOpenRequestDialog(false);
    };

    // Download Template Revision

    const validationSchemaDownloadTemplateRevision = yup.object<Shape<TemplateDownloadDto>>({
        templateRevision: yup.object().nullable(true).required("Please select revision."),
    });

    const onDownloadTemplateRevisionFormSubmit = async (
        values: TemplateDownloadDto,
        { setSubmitting }: FormikHelpers<TemplateDownloadDto>
    ) => {
        const response = await post("/e2e-template/download/revision", values.templateRevision, {
            responseType: "arraybuffer",
        });
        const filename = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
        downloadFile(response.data, filename);
        setSubmitting(false);
        await onDownloadTemplateRevisionFormReset();
    };

    const downloadTemplateRevisionFormik = useFormik<TemplateDownloadDto>({
        initialValues: { templateId: null, templateRevision: null },
        onSubmit: onDownloadTemplateRevisionFormSubmit,
        validationSchema: validationSchemaDownloadTemplateRevision,
    });

    const downloadTemplateRevisionCallback = useCallback(
        async (templateId?: number) => {
            await downloadTemplateRevisionFormik.setFieldValue("templateId", templateId);
            setOpenDownloadTemplateRevisionDialog(true);
        },
        [downloadTemplateRevisionFormik]
    );

    const onDownloadTemplateRevisionFormReset = useCallback(async () => {
        setTemplateRevisionOptions([]);
        await downloadTemplateRevisionFormik.setFieldValue("templateId", null);
        await downloadTemplateRevisionFormik.setFieldValue("templateRevision", null);
        setOpenDownloadTemplateRevisionDialog(false);
    }, [downloadTemplateRevisionFormik, setOpenDownloadTemplateRevisionDialog]);

    const clearDownloadMenuCallback = useCallback(() => {
        setDatasource((prevs) =>
            prevs.map((prev) => ({ ...prev, openDownloadMenu: false, downLoadMenuAnchorEl: null }))
        );
    }, [setDatasource]);

    const openDownloadMenuCallback = useCallback(
        (event: React.MouseEvent<HTMLElement>, template: TemplateDto) => {
            clearDownloadMenuCallback();
            const eventCurrentTarget = event.currentTarget;
            setDatasource((prevs) => {
                const target = prevs.find((prev) => prev.templateId === template.templateId);
                if (!target) return prevs;
                target.openDownloadMenu = true;
                target.downLoadMenuAnchorEl = eventCurrentTarget;
                return prevs;
            });
        },
        [clearDownloadMenuCallback]
    );
    const templateRevisionSetFinder = useCallback(
        async (templateId: number, searchString?: string, cb?: Function) => {
            if (!templateId) return;
            setTemplateRevisionFinder((p) => ({
                ...p,
                limit: Number(process.env.REACT_APP_AUTO_COMPLETE_LIMIT) || 10,
                criteria: { searchString, templateId },
            }));
            if (cb) await cb();
        },
        [setTemplateRevisionFinder]
    );

    const templateRevisionOptionsOnSearch = useCallback(
        ({ target: { value: searchString } }: ChangeEvent<HTMLInputElement>) => {
            const templateId = datasource.find((source) => source.openDownloadMenu)?.templateId;
            if (!templateId) return;
            templateRevisionSetFinder(templateId, searchString);
        },
        [templateRevisionSetFinder, datasource]
    );
    const getTemplateRevisionOptionsCallback = useCallback(async () => {
        setTemplateRevisionOptionsIsLoading(true);
        const response = await post<ResponseAutoComplete<Array<TemplateRevisionDto>>>(
            "/e2e-template/findAutoComplete",
            templateRevisionFinder
        );
        setTemplateRevisionOptions(response.data.datasource);
    }, [post, templateRevisionFinder]);

    useMemo(async () => {
        if (templateRevisionFinder.criteria?.templateId) {
            await getTemplateRevisionOptionsCallback();
        }
    }, [templateRevisionFinder, getTemplateRevisionOptionsCallback]);

    const columns = useMemo<Columns<TemplateDto>>(
        () => [
            {
                dataIndex: "templateName",
                cell: { col: 1, row: 1 },
                label: "Template",
                width: 150,
                textAlign: isSM ? "center" : "left",
                justifyContent: isSM ? "center" : "unset",
            },
            {
                dataIndex: "templateId",
                cell: { col: 2, row: 1 },
                label: "Revision",
                textAlign: "center",
                justifyContent: "center",
                render: (template: TemplateDto) => {
                    return (
                        <Fragment>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                divider={<Divider orientation="vertical" flexItem />}
                            >
                                {isDownloadingFile || isUploadingFile ? (
                                    <LoadingButton loading={isDownloadingFile || isUploadingFile} color="warning">
                                        Upload
                                    </LoadingButton>
                                ) : (
                                    <Button
                                        component="label"
                                        sx={{ textTransform: "none" }}
                                        endIcon={<FileUploadIcon />}
                                        disabled={
                                            !verifyAuthorize("DSMGT01009", permissions) ||
                                            template.status !== "COMPLETED"
                                        }
                                        color="warning"
                                    >
                                        Upload
                                        <input
                                            hidden
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            type="file"
                                            onChange={async (e: React.ChangeEvent<HTMLInputElement>) =>
                                                await uploadCallback(e, template.templateId)
                                            }
                                        />
                                    </Button>
                                )}
                                <Box
                                    sx={{
                                        width: 30,
                                        height: 30,
                                        borderRadius: "50%",
                                        backgroundColor: theme.palette.primary.main + "20",
                                        display: "flex",
                                    }}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Typography
                                        sx={{ textAlign: "center" }}
                                        color={theme.palette.primary.main}
                                        variant="subtitle2"
                                    >
                                        {template.revision}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Fragment>
                    );
                },
            },
            {
                dataIndex: "templateId",
                cell: { col: 3, row: 1 },
                textAlign: "center",
                justifyContent: "center",
                render: (template: TemplateDto) => {
                    return (
                        <Fragment>
                            <LoadingButton
                                id={`download-button-${template.templateId}`}
                                aria-controls={
                                    template.openDownloadMenu ? `download-menu-${template.templateId}` : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={template.openDownloadMenu ? "true" : undefined}
                                onClick={(event) => openDownloadMenuCallback(event, template)}
                                loading={isDownloadingFile || isUploadingFile}
                                sx={{ textTransform: "none" }}
                                endIcon={<DownloadIcon />}
                                disabled={
                                    !verifyAuthorize("DSMGT01010", permissions) || template.status !== "COMPLETED"
                                }
                            >
                                Download
                            </LoadingButton>
                            <Menu
                                id={`download-menu-${template.templateId}`}
                                aria-labelledby={`download-button-${template.templateId}`}
                                open={template.openDownloadMenu || false}
                                onClose={clearDownloadMenuCallback}
                                anchorEl={template.downLoadMenuAnchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                elevation={2}
                            >
                                <MenuItem
                                    onClick={async () => {
                                        await downloadCallback(template.templateId);
                                    }}
                                    disabled={
                                        (template.canRequestDS && template.status !== "COMPLETED") ||
                                        !template.canRequestDS
                                    }
                                >
                                    EBA Template
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    onClick={async () => {
                                        await downloadTemplateRevisionCallback(template.templateId);
                                        templateRevisionSetFinder(template.templateId!);
                                    }}
                                >
                                    Revision Template
                                </MenuItem>
                            </Menu>
                        </Fragment>
                    );
                },
            },
            {
                dataIndex: "templateId",
                cell: { col: 4, row: 1 },
                label: "Last Sync",
                textAlign: "center",
                justifyContent: "center",
                render: (template: TemplateDto) => {
                    return template.canRequestDS ? (
                        <Stack sx={{ position: "relative" }} justifyContent="center" alignItems="center">
                            <Typography variant="caption" sx={{ margin: 0, padding: 0 }}>
                                {dayjs(template.updatedAt).format("DD/MM/YYYY HH:mm:ss")}
                            </Typography>
                            <Typography variant="caption" sx={{ margin: 0, padding: 0 }}>
                                {template.updatedBy}
                            </Typography>
                        </Stack>
                    ) : (
                        <></>
                    );
                },
            },
            {
                dataIndex: "status",
                cell: { col: 5, row: 1 },
                ellipsis: true,
                width: 150,
                render: (template: TemplateDto) => {
                    let color = getColorOfStatusType(theme, template.status);
                    return template.canRequestDS ? (
                        <Typography color={color} fontWeight={600}>
                            {getStatusTypeLabel(template.status!)}
                        </Typography>
                    ) : (
                        <></>
                    );
                },
                label: "Status",
                textAlign: "center",
                justifyContent: "center",
            },
            {
                dataIndex: "templateId",
                cell: { col: 6, row: 1 },
                width: 100,
                label: "Sync",
                textAlign: "center",
                justifyContent: "center",
                render: (template: TemplateDto) => {
                    if (!template.status) return <></>;
                    const loading = !["COMPLETED", "ERROR"].includes(template.status);
                    return (
                        <ProtectedComponent permissions={["DSDRQ01007"]} display={template.canRequestDS}>
                            {loading ? (
                                <IconButton
                                    sx={{
                                        "& svg": { animation: "lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite" },
                                    }}
                                    disabled
                                >
                                    <AutorenewIcon />
                                </IconButton>
                            ) : (
                                <IconButton
                                    onClick={async () => await requestCallback(template.templateId)}
                                    color="success"
                                >
                                    <AutorenewIcon />
                                </IconButton>
                            )}
                        </ProtectedComponent>
                    );
                },
            },
        ],
        [
            requestCallback,
            isDownloadingFile,
            isUploadingFile,
            downloadCallback,
            uploadCallback,
            permissions,
            theme,
            clearDownloadMenuCallback,
            downloadTemplateRevisionCallback,
            openDownloadMenuCallback,
            templateRevisionSetFinder,
            isSM,
        ]
    );
    const pageRouterMemo: AppRouter | undefined = useMemo(() => {
        if (!pageRouter) return undefined;
        return {
            ...pageRouter,
        };
    }, [pageRouter]);
    return (
        <Fragment>
            <Header templateOption={templateOption} pageRouter={pageRouterMemo} />
            <Body templateOption={templateOption} fullMargin>
                <DataTable<TemplateDto>
                    withHeader={!isSM}
                    withPagination={false}
                    datasource={datasource}
                    columns={columns}
                    direction="horizontal"
                    loading={datasourceIsLoading}
                />
            </Body>
            <Dialog
                open={openRequestDialog}
                fullScreen={isSM}
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                    },
                }}
                PaperProps={{ sx: { minWidth: !isSM ? 500 : "unset", maxWidth: !isSM ? 500 : "unset" } }}
            >
                <form
                    onSubmit={async (formEvent) => {
                        formEvent.preventDefault();
                        requestFormik.handleSubmit(formEvent);
                    }}
                >
                    <DialogTitle>Request New Template</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <MobileDatePicker
                                        loading={requestFormik.isSubmitting}
                                        label="Initial Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={requestFormik.values.initialDate}
                                        onChange={async (newValue: Dayjs | null) => {
                                            await requestFormik.setFieldValue(
                                                "initialDate",
                                                newValue?.format("YYYY-MM-DD")
                                            );
                                        }}
                                        disabled={requestFormik.isSubmitting}
                                        renderInput={(params: any) => <TextField {...params} fullWidth />}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onRequestFormReset} disabled={requestFormik.isSubmitting}>
                            Cancel
                        </Button>
                        <LoadingButton type="submit" variant="contained" loading={requestFormik.isSubmitting}>
                            Confirm
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog
                open={openDownloadTemplateRevisionDialog}
                fullScreen={isSM}
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                    },
                }}
                PaperProps={{ sx: { minWidth: !isSM ? 500 : "unset", maxWidth: !isSM ? 500 : "unset" } }}
            >
                <form
                    onSubmit={async (formEvent) => {
                        formEvent.preventDefault();
                        downloadTemplateRevisionFormik.handleSubmit(formEvent);
                    }}
                >
                    <DialogTitle>Revision Template</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel>Reivision</FormLabel>
                                    <AutoComplete<
                                        TemplateRevisionDto,
                                        FindDataAutoComplete<TemplateRevisionCriteriaDto>
                                    >
                                        textFieldPlaceholder="revision"
                                        id="revision"
                                        loading={
                                            templateRevisionOptionsIsLoading ||
                                            downloadTemplateRevisionFormik.isSubmitting
                                        }
                                        disabled={downloadTemplateRevisionFormik.isSubmitting}
                                        onSearchString={templateRevisionOptionsOnSearch}
                                        options={templateRevisionOptions}
                                        finderCallback={setTemplateRevisionFinder}
                                        getOptions={getTemplateRevisionOptionsCallback}
                                        isOptionEqualToValue={(option, value) =>
                                            option.submitTemplateId === value.submitTemplateId
                                        }
                                        getOptionLabel={(option: TemplateRevisionDto) =>
                                            `Revision ${option.revision} - ${option.createdBy} - ${dayjs(
                                                option.createdAt
                                            ).format("DD/MM/YYYY HH:mm")}`
                                        }
                                        multiple={false}
                                        value={downloadTemplateRevisionFormik.values.templateRevision}
                                        onChange={(_e, v) =>
                                            downloadTemplateRevisionFormik.setFieldValue("templateRevision", v)
                                        }
                                        error={
                                            downloadTemplateRevisionFormik.touched.templateRevision &&
                                            Boolean(downloadTemplateRevisionFormik.errors.templateRevision)
                                        }
                                        helperText={
                                            downloadTemplateRevisionFormik.touched.templateRevision &&
                                            downloadTemplateRevisionFormik.errors.templateRevision
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={onDownloadTemplateRevisionFormReset}
                            disabled={downloadTemplateRevisionFormik.isSubmitting}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={downloadTemplateRevisionFormik.isSubmitting}
                        >
                            Confirm
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    );
};

export default Template;
