import { Context, createContext, PropsWithChildren, useContext, useState } from "react";
export type UserProfile = {
    email?: string;
    fullName?: string;
    photo?: string;
} | null;
export type Permissions = Array<string> | string | null;

type AuthorizeContextType = {
    isAuth: boolean;
    setIsAuth: React.Dispatch<React.SetStateAction<boolean>>;
    userProfile?: UserProfile;
    setUserProfile: React.Dispatch<React.SetStateAction<UserProfile>>;
    permissions?: Permissions;
    setPermissions: React.Dispatch<React.SetStateAction<Permissions>>;
};
const AuthorizeContext: Context<AuthorizeContextType | null> = createContext<AuthorizeContextType | null>(null);

export const AuthorizeProvider = ({ children }: PropsWithChildren<{}>) => {
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [userProfile, setUserProfile] = useState<UserProfile>(null);
    const [permissions, setPermissions] = useState<Permissions>(null);
    return (
        <AuthorizeContext.Provider
            value={{ isAuth, setIsAuth, userProfile, setUserProfile, permissions, setPermissions }}
        >
            {children}
        </AuthorizeContext.Provider>
    );
};

export const useAuthoize = () => {
    const context = useContext(AuthorizeContext);
    if (!context) throw new Error("Authorize Hooks must be used within Provider");
    return context;
};
