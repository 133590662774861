import { Box as MuiBox, styled } from "@mui/material";
import { NavigateModule } from "../../../../config/AppRouter/NavigateState";

const Box = styled(
    MuiBox,
    {}
)(({ theme }) => ({
    // boxShadow: theme.shadows[1],
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    modules: NavigateModule;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, modules, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${modules}-tabpanel-${index}`}
            aria-labelledby={`${modules}-tab-${index}`}
            style={{position: "relative"}}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

export default TabPanel;
