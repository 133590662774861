import {Chip, Stack, styled} from "@mui/material";
import {useMemo} from "react";
import {Color} from "../types";

type StatusContainerStyledType = {
    width?: string | number;
    color: Color;
    onClick?: () => void;
};
const StatusContainerStyled = styled(Stack)<StatusContainerStyledType>(({theme, color, onClick}) => ({
    alignItems: "center",
    cursor: "pointer",
    padding: 4,
    paddingRight: 6,
    borderRadius: "16px",
    transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(onClick &&
        {
            backgroundColor: color === "default" ? theme.palette.action.disabled : (theme.palette as any)[color]?.main + "10 !important", //Status Type
            "&:hover": {
                backgroundColor: color === "default" ? theme.palette.action.disabled : (theme.palette as any)[color]?.main + "30 !important", //Status Type
            }
        }
    )
}));

type StatusLabelStyledType = {
    color: Color;
};
const StatusLabelStyled = styled("label")<StatusLabelStyledType>(({theme, color}) => ({
    position: "relative",
    marginLeft: "5px !important",
    color: (color === "default" ? theme.palette.action.disabled : (theme.palette as any)[color]?.main) + " !important", //Status Type
    fontSize: theme.typography.caption.fontSize,
    cursor: "pointer",
}));
const PinStatusContainedStyled = styled("div")(({theme}) => ({
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));
type PinStatusStyledType = {
    color: Color;
};
const PinStatusStyled = styled("div")<PinStatusStyledType>(({theme, color}) => ({
    position: "relative",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: (color === "default" ? theme.palette.action.disabled : (theme.palette as any)[color]?.main) + " !important", //Status Type
}));
type PinStatusAnimateStyledType = {
    color: Color;
};
const PinStatusAnimatedStyled = styled("div")<PinStatusAnimateStyledType>(({theme, color}) => ({
    position: "absolute",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: "transparent",
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: color === "default" ? theme.palette.action.disabled : (theme.palette as any)[color]?.main, //Status Type
    animation: "zoomOutWithOpacity 1.2s infinite",
}));
const StatusStyled = styled("div")(({theme}) => ({}));
type StatusType = {
    status: Color;
    label?: string | undefined;
    withChip?: boolean;
    width?: string | number;
    onClick?: () => void;
};
const Status = ({status, label, width, withChip, onClick}: StatusType) => {
    const statusMemo = useMemo(() => {
        return withChip ? (
            <Chip
                className="status-container"
                color={status}
                size="small"
                variant="outlined"
                label={
                    <StatusContainerStyled direction="row" spacing={5} color={status}>
                        <PinStatusContainedStyled>
                            <PinStatusStyled color={status}></PinStatusStyled>
                            <PinStatusAnimatedStyled color={status}></PinStatusAnimatedStyled>
                        </PinStatusContainedStyled>
                        {label ? <StatusLabelStyled color={status}>{label}</StatusLabelStyled> : undefined}
                    </StatusContainerStyled>
                }
            />
        ) : (
            <StatusContainerStyled direction="row" className="status-container" width={width} color={status}
                                   onClick={onClick}>
                <PinStatusContainedStyled>
                    <PinStatusStyled color={status}></PinStatusStyled>
                    <PinStatusAnimatedStyled color={status}></PinStatusAnimatedStyled>
                </PinStatusContainedStyled>
                {label ? <StatusLabelStyled color={status}>{label}</StatusLabelStyled> : undefined}
            </StatusContainerStyled>
        );
    }, [status, label, withChip, width, onClick]);
    return <StatusStyled>{statusMemo}</StatusStyled>;
};

export default Status;
