import {
    Box,
    Card,
    CardContent,
    CardContentProps as MuiCardContentProps,
    CardProps as MuiCardProps,
    Grid,
    GridProps as MuiGridProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { PropsWithChildren } from "react";
import { Color } from "../types";
import Typography from "../Typography/Typography";
import "./dataTable.css";

export const TheadTypographyStyled = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main + " !important",
}));

export const CardWithStatusContainerStyled = styled(Box)(({ theme }) => ({
    position: "relative",
}));
type CardWithStatusStyledType = {
    width?: number | string;
    color?: Color;
};
export const CardWithStatusStyled = styled(Card)<CardWithStatusStyledType>(({ theme, width, color }) => ({
    alignItems: "center",
    display: "flex",
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: width || 20,
    cursor: "pointer",
    backgroundColor: "transparent",
    // ".status-container": {
    //     backgroundSize: "0px 0px",
    //     backgroundPosition: "0px 0px",
    // },
    "&:hover": {
        ".status-container": {
            backgroundColor: color
                ? color === "default"
                    ? `${theme.palette.action.disabled.replace("0.26", "0.1")}`
                    : `${(theme.palette as any)[color]?.main}30`
                : "unset",
            // opacity: 0,
            // backgroundImage: `linear-gradient(${
            //     color
            //         ? color === "default"
            //             ? `${theme.palette.action.disabled}`
            //             : `${(theme.palette as any)[color]?.main}30`
            //         : "unset"
            // }, ${
            //     color
            //         ? color === "default"
            //             ? `${theme.palette.action.disabled}`
            //             : `${(theme.palette as any)[color]?.main}30`
            //         : "unset"
            // })`, //convert to background image instead of color

            // backgroundSize: "100% 100%",
            // backgroundPosition: "0px 0px",
        },
    },
}));

interface CardStyledProps extends MuiCardProps {
    type: "thead" | "tbody" | "noOptions";
    action?: "actionSlide" | "actionOnCard";
}
export const CardStyled = styled(Card)<CardStyledProps>(({ theme, type, action }) => ({
    backgroundColor: theme.palette.background.paper,
    ...(type === "thead" && {
        // color: theme.palette.info.main + " !important",
        backgroundColor: theme.palette.primary.main + "!important",
        color: theme.palette.text.secondary + " !important",
    }),
    ...(type === "tbody" && {
        padding: "0 !important",
    }),
    ...(type === "noOptions" && {
        padding: "0 !important",
    }),
    position: "relative",
    ...(action === "actionOnCard" && {
        transition: theme.transitions.create(["backgroundColor"], {
            duration: theme.transitions.duration.standard,
        }),
        cursor: "pointer",
        "& .action-on-card": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
            transition: theme.transitions.create(["backgroundColor", "opacity", "animation"], {
                duration: theme.transitions.duration.standard,
            }),
            "& .icon": {
                position: "absolute",
                right: 10,
                // animation: "1s ease-out 0s 1 bounceInRight",
            },
        },
        "&:hover": {
            "& .action-on-card": {
                opacity: 1,
                backgroundColor: theme.palette.primary.main + "10",
                "& .icon": {
                    animation: "0.5s ease-out 0s 1 bounceInRight",
                    animationFillMode: "forwards",
                },
            },
        },
    }),
}));

interface CardContentStyledProps extends MuiCardContentProps {
    type: "thead" | "tbody" | "actionSlide";
    open?: boolean;
}
export const CardContentStyled = styled(CardContent)<CardContentStyledProps>(({ theme, type, open }) => ({
    ...(type !== "actionSlide" && { padding: "16px !important" }),
    ...(type === "thead" && {}),
    ...(type === "tbody" && {}),
    ...(type === "actionSlide" && {
        position: "absolute",
        top: 0,
        right: 0,
        height: "100%",
        padding: "0px !important",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: "translateX(100%)",
        flexWrap: "nowrap",
        transition: theme.transitions.create(["transform", "width", "content"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        "p::after": {
            content: '"keyboard_double_arrow_left"',
            display: "inline-block",
            transition: theme.transitions.create(["transform", "content", "animation"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            ...(open && {
                transform: "scaleX(-1) !important",
            }),
        },
        ...(open && {
            transition: theme.transitions.create(["transform", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            transform: "translateX(40px)",
        }),
        ":hover": {
            "p::after": {
                transition: theme.transitions.create(["transform"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                transform: "scale(1.2)",
            },
        },
    }),
}));

export const CardContentWrapped = ({ children, type, sx }: PropsWithChildren<CardContentStyledProps>) => {
    return (
        <CardContentStyled type={type} sx={sx}>
            <Grid
                container
                spacing={4}
                sx={{
                    paddingTop: 2,
                    "& .MuiGrid-item": {
                        paddingTop: 2,
                    },
                }}
            >
                {children}
            </Grid>
        </CardContentStyled>
    );
};

interface GridStyledProps extends MuiGridProps {
    type: "thead" | "tbody";
}
export const GridStyled = styled(Grid)<GridStyledProps>(({ theme, type }) => ({
    ...(type === "thead" && {
        "& span": {
            color: theme.palette.primary.main,
        },
    }),
}));
