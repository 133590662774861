import { Theme } from "@mui/material";
import { StatusType } from "../config/type";

const hexToHSL = (hex: string) => {
    let result: RegExpExecArray | null = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) return;
    let r: number = parseInt(result[1], 16);
    let g: number = parseInt(result[2], 16);
    let b: number = parseInt(result[3], 16);
    r /= 255;
    g /= 255;
    b /= 255;
    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let h: number = 0;
    let s: number = 0;
    let l: number = (max + min) / 2;
    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }
    let HSL: { h: number; s: number; l: number } = {
        h: Math.round(360 * h),
        s: Math.round(100 * s),
        l: Math.round(100 * l),
    };
    return HSL;
};


export const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export const stringAvatar = (name: string) => {
    const hsl = hexToHSL(stringToColor(name));
    return {
        sx: {
            bgcolor: hsl?.h ? `hsl(${hsl?.h}, 50%, 50%)` : `hsl(${Math.round(Math.random() * 360)}, 70%, 80%)`,
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export const reduceToPrimitiveArray = <S, D>(array: Array<S>, key: keyof S): Array<D> =>
    array.reduce<Array<D>>((prev, current) => current[key] ? prev.concat((current as any)[key]) : prev, []);


export const checkStatusValueToBoolean = (status?: "A" | "I" | "" | any) =>
    status === "A" ? true : status === "" ? "" : false

export const checkStatusBooleanToValue = (status?: boolean | ""): "A" | "I" | "" =>
    status ? "A" : status === "" ? "" : "I"

export const checkStatusLabel = (status?: boolean | "", activeLabel: string = "Active", inactiveLabel: string = "Inactive") =>
    status ? activeLabel : (status === null || status === undefined || status === "") ? "All" : inactiveLabel;

export function toSentence(str: string) {
    const interim = str.replace(/_/g, ' ').toLocaleLowerCase()
    return interim.slice(0, 1).toUpperCase() + interim.slice(1)
}
export const getStatusTypeLabel = (statusType?: StatusType) => statusType && toSentence(statusType.toString())

export const downloadFile = (buffer: BlobPart, filename: string, type: string = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") => {
    const blob = new Blob([buffer], {
        type,
    });
    const csvURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", filename);
    tempLink.click();
};
export const getColorOfStatusType = (theme: Theme, status?: StatusType) => {
    switch (status) {
        case "COMPLETED":
            return theme.palette.success.main;
        case "ERROR":
        case "CANCELLED":
            return theme.palette.error.main;
        case "WAITING":
        case "IN_PROGRESS":
            return theme.palette.warning.main;
        default:
            return "";
    }
}


export const millisecondToMinute = (millisecond: number) => (millisecond / 1000) / 60