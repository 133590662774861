import {
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    Button,
    useTheme,
    useMediaQuery,
    Stepper,
    Step,
    StepLabel as MuiStepLabel,
    StepLabelProps as MuiStepLabelProps,
    styled,
} from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { millisecondToMinute } from "../../../../utils/helper";
import Typography from "../../../Common/Typography/Typography";
import { ModelStepDto } from "./dto/model-step.dto";

const StepLabel = styled(
    MuiStepLabel,
    {}
)<MuiStepLabelProps>(({ theme }) => ({
    color: theme.palette.primary.main,
    "& .MuiStepLabel-label": {
        color: theme.palette.primary.main + " !important",
    },
}));
type ModelProgressType = {
    open: boolean;
    setOpen?: Dispatch<SetStateAction<boolean>>;
    steps?: Array<ModelStepDto> | null;
    onClose?: () => void;
};
const ModelProgress = ({ open, setOpen, onClose, steps }: ModelProgressType) => {
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    const onCloseCallback = useCallback(() => {
        setOpen && setOpen(false);
        onClose && onClose();
    }, [setOpen, onClose]);
    const stepsMemo = useMemo(() => steps || [], [steps]);
    const findActiveStepMemo = useMemo(
        () =>
            Math.max(
                ...stepsMemo.reduce<Array<number>>(
                    (prev, current) => (current.status === "COMPLETED" ? prev.concat(Number(current.sequence)) : prev),
                    [0]
                )
            ),
        [stepsMemo]
    );
    return (
        <Dialog
            open={open}
            fullScreen={isSM}
            sx={{
                "& .MuiDialog-container": {
                    alignItems: "flex-start",
                },
            }}
            onClose={onCloseCallback}
            PaperProps={{ sx: { minWidth: !isSM ? 500 : "unset", maxWidth: !isSM ? 500 : "unset" } }}
        >
            <DialogTitle>Queue Progress</DialogTitle>
            <Divider />
            <DialogContent>
                <Stepper activeStep={findActiveStepMemo} alternativeLabel>
                    {stepsMemo.map((step) => {
                        const estimatedMinute =
                            step.estimatedRuntime && step.status !== "COMPLETED"
                                ? millisecondToMinute(step.estimatedRuntime)
                                : null;
                        return (
                            <Step key={step.sequence}>
                                <StepLabel color={theme.palette.primary.main}>
                                    <Typography>{step.stepName}</Typography>
                                    {estimatedMinute && (
                                        <Typography variant="caption">{estimatedMinute} minutes</Typography>
                                    )}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseCallback}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModelProgress;
