import { Card as MuiCard, CardHeader as MuiCardHeader, Divider, styled } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ChangeEvent, Dispatch, ReactNode, SetStateAction, useCallback, useState } from "react";
import TextField from "../TextField/TextField";

const TransferListCard = styled(MuiCard)(({ theme }) => ({
    "& .MuiCheckbox-root": {
        color: "unset",
    },
    "& .Mui-checked": {
        color: theme.palette.primary.main,
    },
}));
const TransferListCardHeader = styled(MuiCardHeader)(({ theme }) => ({
    // backgroundColor: theme.palette.primary.main,
}));

const not = <T extends Object>(a: T[], b: T[]) => a.filter((value) => b.indexOf(value) === -1);

const intersection = <T extends Object>(a: T[], b: T[]): T[] => a.filter((value) => b.indexOf(value) !== -1);

const union = <T extends Object>(a: T[], b: T[]) => [...a, ...not(b, a)];

const diff = <T extends Object>(a: T[] = [], b: T[] = [], keyIndex: keyof T): T[] =>
    a.filter((value) => !b.some((bValue) => bValue[keyIndex] === value[keyIndex]));

type TransferListProps<T, C> = {
    renderOption?: (t: T) => ReactNode;
    datasource?: Array<T>;
    selected?: Array<T>;
    setSelected?: Dispatch<SetStateAction<C>>;
    loading?: boolean;
    keyIndex: keyof T;
    searchKey?: Array<keyof T>;
    onChange?: (selected: Array<T>) => void;
};

const TransferList = <T extends Object, C>({
    datasource,
    selected,
    loading,
    renderOption,
    keyIndex,
    searchKey,
    onChange,
}: TransferListProps<T, C>) => {
    const [checked, setChecked] = useState<Array<T>>([]);
    const [left, setLeft] = useState<Array<T>>(diff(datasource, selected, keyIndex));
    const [right, setRight] = useState<Array<T>>(selected || []);
    const [leftBucket, setLeftBucket] = useState<Array<T>>(left);
    const [rightBucket, setRightBucket] = useState<Array<T>>(right);

    const leftChecked = intersection<T>(checked, left);
    const rightChecked = intersection<T>(checked, right);

    const handleToggle = (value: T) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: Array<T>) => intersection(checked, items).length;

    const handleToggleAll = (items: Array<T>) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        const currentRight = right.concat(leftChecked);
        const currentLeft = not(left, leftChecked);
        setRight(currentRight);
        setLeft(currentLeft);
        setRightBucket(currentRight);
        setLeftBucket(currentLeft);
        setChecked(not(checked, leftChecked));
        onChange && onChange(currentRight);
    };

    const handleCheckedLeft = () => {
        const currentLeft = left.concat(rightChecked);
        const currentRight = not(right, rightChecked);
        setLeft(currentLeft);
        setRight(currentRight);
        setLeftBucket(currentLeft);
        setRightBucket(currentRight);
        setChecked(not(checked, rightChecked));
        onChange && onChange(currentRight);
    };

    const onLeftSearch = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            if (!searchKey?.length) return;
            setLeft(leftBucket.filter((v) => searchKey.some((key) => String(v[key]).includes(value))));
        },
        [setLeft, searchKey, leftBucket]
    );

    const onRightSearch = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            if (!searchKey?.length) return;
            setRight(rightBucket.filter((v) => searchKey.some((key) => String(v[key]).includes(value))));
        },
        [setRight, searchKey, rightBucket]
    );

    const customList = (title: ReactNode, items: Array<T>, onSearch: (e: ChangeEvent<HTMLInputElement>) => void) => (
        <TransferListCard>
            <TransferListCardHeader
                sx={{ px: 2, py: 0, paddingTop: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{
                            "aria-label": "all items selected",
                        }}
                    />
                }
                title={title}
            />
            <Divider />
            <TransferListCardHeader
                sx={{ px: 2, py: 1 }}
                title={<TextField fullWidth variant="outlined" size="small" placeholder="Search" onChange={onSearch} />}
            />
            <List
                sx={{
                    width: "100%",
                    height: 230,
                    bgcolor: "background.paper",
                    overflow: "auto",
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value: T, index: number) => {
                    const labelId = `transfer-list-all-item-${value}-label`;
                    return (
                        <ListItem key={index} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        "aria-labelledby": labelId,
                                    }}
                                />
                            </ListItemIcon>
                            {renderOption && renderOption(value)}
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </TransferListCard>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" columns={7}>
            <Grid item xs={3}>
                {customList("Choices", left, onLeftSearch)}
            </Grid>
            <Grid item xs={1}>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={3}>
                {customList("Chosen", right, onRightSearch)}
            </Grid>
        </Grid>
    );
};

export default TransferList;
