import { Typography } from "@mui/material";
import { styled } from "@mui/material";
const AvatarProfileStyled = styled("div")(({ theme }) => ({
  color: theme.palette.text.secondary,
  flexDirection: "column",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

type AvatarProfileProps = {
  name: string;
  email: string;
};
const NameTypoStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
const EmailTypoStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const AvatarProfile = ({ name, email }: AvatarProfileProps) => {
  return (
    <AvatarProfileStyled>
      <NameTypoStyled variant="subtitle1">{name}</NameTypoStyled>
      <EmailTypoStyled variant="caption">{email}</EmailTypoStyled>
    </AvatarProfileStyled>
  );
};

export default AvatarProfile;
