import { styled } from "@mui/material/styles";
import "./Loading.css";

const LoadingStlyed = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    marginTop: "10rem",
    width: "100%",
}));

type LoadingType = {
    count?: number;
};
const Loading = ({ count }: LoadingType) => {
    return (
        <LoadingStlyed>
            <div className="data-table-lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="loader">
                <div className="letter-holder">
                    <div className="l-1 letter">L</div>
                    <div className="l-2 letter">o</div>
                    <div className="l-3 letter">a</div>
                    <div className="l-4 letter">d</div>
                    <div className="l-5 letter">i</div>
                    <div className="l-6 letter">n</div>
                    <div className="l-7 letter">g</div>
                </div>
            </div>
        </LoadingStlyed>
    );
};

export default Loading;
