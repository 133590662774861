import { Pagination } from "./pagination.class";
export class FindDataPagination<T> {
    criteria?: T;
    pagination?: Pagination;

    constructor(pagination?: Pagination, criteria?: T) {
        this.pagination = pagination || new Pagination();
        this.criteria = criteria || undefined;
    }
}
