import { styled } from "@mui/material/styles";
import { useCallback, useState } from "react";
import { CardContentStyled } from "../dataTable.style";

const ActionTabStyled = styled("div")(({ theme }) => ({
    height: "100%",
    padding: "0 8px 0 8px",
    display: "flex",
    alignItems: "center",
    transform: "translateX(-100%)",
    width: "40px",
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[3],
}));
const ActionTabItemStyled = styled("div")(() => ({
    height: "100%",
    displa: "flex",
    alignItems: "center",
    transform: "translateX(-40px)",
    zIndex: 1,
}));

type ActionProps<T> = {
    render: (t: T) => JSX.Element | Array<JSX.Element> | string | undefined;
    source: T;
};
const Action = <T extends unknown>({ render, source }: ActionProps<T>) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleActionOpen = useCallback(() => setOpen(true), []);
    const handleActionMouseLeaveTriggerClose = useCallback(() => open && setOpen(false), [open]);
    return (
        <CardContentStyled
            type="actionSlide"
            className="actionTabIcon"
            open={open}
            onMouseLeave={handleActionMouseLeaveTriggerClose}
        >
            <ActionTabStyled onClick={handleActionOpen}>
                <p className="material-icons"></p>
            </ActionTabStyled>
            <ActionTabItemStyled>{render(source)}</ActionTabItemStyled>
        </CardContentStyled>
    );
};

export default Action;
