import jwtDecode from "jwt-decode";
export class JWTUtils {
    token: string;
    constructor(token: string) {
        this.token = token;
    }
    extractPayload<T>(): T {
        const payload: T = jwtDecode(this.token);
        return payload;
    }
    isExpired() {
        const payload: JWTStdType = this.extractPayload();
        return payload.exp * 1000 < new Date().getTime();
    }
}

type JWTStdType = {
    exp: number;
};
