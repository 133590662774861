import { Pagination as MuiPagination, styled, PaginationProps as MuiPaginationProps } from "@mui/material";

interface PaginationProps extends MuiPaginationProps {
    open?: boolean;
}

const Pagination = styled(
    MuiPagination,
    {}
)<PaginationProps>(({ theme, open }) => ({
    position: "fixed",
    bottom: 10,
    backgroundColor: theme.palette.background.paper,
    padding: 5,
    borderRadius: 50,
    boxShadow: theme.shadows[1],
    alignSelf: "center",
    opacity: 0.8,
    transition: theme.transitions.create(["opacity"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    ":hover": {
        opacity: 1,
    },
    "& .MuiPaginationItem-root": {
        transition: theme.transitions.create(["transform", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    "& .MuiPaginationItem-root:hover": {
        transform: "scale(1.5)",
        marginLeft: 10,
        marginRight: 10,
    },
}));

export default Pagination;
