import { } from "axios";
import { Context, createContext, PropsWithChildren, useContext, useState } from "react";

export type ScreenType = {
    name?: string;
    code?: string;
};

type ScreenContextType = {
    screen?: ScreenType;
    setScreen: React.Dispatch<React.SetStateAction<ScreenType | undefined>>;
};
const ScreenContext: Context<ScreenContextType | null> = createContext<ScreenContextType | null>(null);

export const ScreenProvider = ({ children }: PropsWithChildren<{}>) => {
    const [screen, setScreen] = useState<ScreenType | undefined>(undefined);
    return <ScreenContext.Provider value={{ screen, setScreen }}>{children}</ScreenContext.Provider>;
};

export const useScreen = () => {
    const context = useContext(ScreenContext);
    if (!context) throw new Error("Screen Hooks must be used within Screen Provider");
    return context;
};
