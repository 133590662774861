import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box, BoxProps, Stack, styled } from "@mui/material";
import { useMemo } from "react";
import { AppRouter } from "../../../../config/AppRouter/AppRouters.menu";
import ProtectedComponent from "../../ProtectedComponent/ProtectedComponent";

interface LoadingButtonStyledProps extends LoadingButtonProps {
    breakpoint?: "sm";
}
const LoadingButtonStyled = styled(LoadingButton)<LoadingButtonStyledProps>(({ theme, breakpoint }) => ({
    ...(breakpoint === "sm" && { backgroundColor: "white" }),
}));
interface HeaderBoxSearchStyledProps extends BoxProps {
    open: boolean;
}
const HeaderBoxManagementStyled = styled(Box)<HeaderBoxSearchStyledProps>(({ theme, open }) => ({
    animation: "1s ease-out 0s 1 bounceInRight",
}));

type HeaderManagementProps = {
    open: boolean;
    loading?: boolean;
    pageRouter?: AppRouter;
    isSM?: boolean;
};
const HeaderManagement = ({ open, loading, pageRouter, isSM, ...props }: HeaderManagementProps) => {
    const updateMemo = useMemo(() => pageRouter?.update, [pageRouter]);
    const createMemo = useMemo(() => pageRouter?.create, [pageRouter]);
    const removeMemo = useMemo(() => pageRouter?.remove, [pageRouter]);
    return (
        <HeaderBoxManagementStyled open={open}>
            <Stack direction="row" spacing={2}>
                {removeMemo?.canAccess && (
                    <ProtectedComponent permissions={removeMemo.permissions}>
                        <LoadingButtonStyled
                            variant="contained"
                            color="error"
                            loading={loading}
                            onClick={removeMemo?.onClick}
                        >
                            {removeMemo?.label ? removeMemo.label : "Remove"}
                        </LoadingButtonStyled>
                    </ProtectedComponent>
                )}
                {updateMemo?.canAccess && (
                    <LoadingButtonStyled
                        type="submit"
                        variant="contained"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        color={isSM ? "info" : "primary"}
                    >
                        {updateMemo?.label ? updateMemo.label : "Update"}
                    </LoadingButtonStyled>
                )}
                {createMemo?.canAccess && (
                    <LoadingButtonStyled
                        type="submit"
                        variant="contained"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        color={isSM ? "info" : "primary"}
                    >
                        {createMemo?.label ? createMemo.label : "Create"}
                    </LoadingButtonStyled>
                )}
            </Stack>
        </HeaderBoxManagementStyled>
    );
};

export default HeaderManagement;
