import { Fragment, PropsWithChildren, useMemo } from "react";
import { Permissions, useAuthoize } from "../../../contexts/authorize.context";

type ProtectedComponentType = {
    permissions?: Permissions;
    display?: boolean;
    children: JSX.Element;
};
const ProtectedComponent = ({
    permissions: routePermissions,
    children,
    display = true,
}: PropsWithChildren<ProtectedComponentType>) => {
    const { permissions } = useAuthoize();
    const isAuthorized = useMemo(
        () => verifyAuthorize(routePermissions, permissions),
        // {
        //     if (!routePermissions) return true;
        //     if (!permissions || !permissions?.length) return false;
        //     if (Array.isArray(routePermissions)) {
        //         return routePermissions.some((r) => permissions.includes(r));
        //     }
        //     if (permissions?.includes(routePermissions)) {
        //         return true;
        //     }
        // }
        [permissions, routePermissions]
    );
    if (!isAuthorized || !display) return <Fragment></Fragment>;
    return children;
};

export const verifyAuthorize = (routePermissions?: Permissions, permissions?: Permissions): boolean => {
    if (!routePermissions) return true;
    if (!permissions || !permissions?.length) return false;
    if (Array.isArray(routePermissions)) {
        return routePermissions.some((r) => permissions.includes(r));
    }
    if (permissions?.includes(routePermissions)) {
        return true;
    }
    return false;
};

export default ProtectedComponent;
