import { styled, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

const TextFieldStyle = styled(
    MuiTextField,
    {}
)<MuiTextFieldProps>(({ theme }) => ({
    color: theme.palette.primary.main,
    "& label": {
        color: theme.palette.primary.main,
        fontWeight: 300,
    },
}));

type TextFieldProps = MuiTextFieldProps & {
    delay?: number;
};

const TextField = ({ delay = 0, ...props }: TextFieldProps) => {
    const [inputValue, setInputValue] = useState(props.value);
    const [timer, setTimer] = useState<NodeJS.Timer | undefined>(undefined);
    useEffect(() => {
        setInputValue(props.value);
    }, [setInputValue, props.value]);
    const onChangeCallback = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
            if (!event) return;
            setInputValue(event.target.value);
            clearTimeout(timer);
            const newTimer = setTimeout(() => {
                if (props.onChange && event) props.onChange(event);
            }, delay);
            setTimer(newTimer);
        },
        [setInputValue, setTimer, delay, props, timer]
    );
    return <TextFieldStyle {...props} value={inputValue} onChange={onChangeCallback} />;
};

export default TextField;
