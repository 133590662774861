import { Box, BoxProps, styled, StandardTextFieldProps as MuiStandardTextFieldProps } from "@mui/material";
import SearchTextField from "./SearchTextField/SearchTextField";

interface HeaderBoxSearchStyledProps extends BoxProps {
    open: boolean;
    searching?: "onSearch";
}
const HeaderBoxSearchStyled = styled(Box)<HeaderBoxSearchStyledProps>(({ theme, open, searching }) => ({
    width: "100%",
    // animation: "1s ease-out 0s 1 bounceInRight",
    // backgroundColor: theme.palette.primary.main,
    // // padding: "4px 8px 4px 8px",
    // height: 40,
    // width: searching ? 200 : 40,
    // borderRadius: "20px",
    // transition: theme.transitions.create(["width"], {
    //     easing: theme.transitions.easing.easeIn,
    //     duration: theme.transitions.duration.leavingScreen,
    // }),
    // overflow: "hidden",
    // ...(searching
    //     ? {
    //           "& .MuiInputAdornment-root": {
    //               transform: "translateX(0)",
    //           },
    //       }
    //     : {
    //           "& .MuiInputBase-input": {},
    //           "& .MuiInputAdornment-root": {
    //               transition: theme.transitions.create(["transform"], {
    //                   easing: theme.transitions.easing.easeIn,
    //                   duration: theme.transitions.duration.leavingScreen,
    //               }),
    //               transform: "translateX(-5px)",
    //           },
    //           "&:hover": {
    //               "& .MuiInputAdornment-root": {
    //                   transform: "translateX(0)",
    //               },
    //               width: 200,
    //           },
    //       }),
}));

type HeaderSearchProps = MuiStandardTextFieldProps & {
    open: boolean;
    isSearching?: boolean;
};
const HeaderSearch = ({ open, isSearching, ...props }: HeaderSearchProps) => {
    return (
        <HeaderBoxSearchStyled open={open} searching={isSearching ? "onSearch" : undefined}>
            <SearchTextField {...props} />
        </HeaderBoxSearchStyled>
    );
};

export default HeaderSearch;
