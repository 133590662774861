import { styled, FormControl as MuiFormControl, FormControlProps as MuiFormControlProps } from "@mui/material";

const FormControl = styled(
    MuiFormControl,
    {}
)<MuiFormControlProps>(({ theme }) => ({
    "& .MuiFormLabel-root ": {
        color: theme.palette.primary.main + " !important",
        fontWeight: 300,
    },
}));

export default FormControl;
