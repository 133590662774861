import { styled } from "@mui/material";
import MuiListItemButton, { ListItemButtonProps as MuiListItemButtonProps } from "@mui/material/ListItemButton";

interface ListItemButtonProps extends MuiListItemButtonProps {}

const ListItemButton = styled(
  MuiListItemButton,
  {}
)<ListItemButtonProps>(({ theme }) => ({
  //   backgroundImage: "transparent",

  //   borderLeftStyle: "solid",
  //   borderLeftColor: "transparent",
  //   borderLeftWidth: 0,
//   transition: theme.transitions.create([], {
//     duration: theme.transitions.duration.standard,
//     easing: theme.transitions.easing.easeInOut,
//   }),
//   ":hover": {
//     backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 8%, transparent 0%)`,
//   },
}));

export default ListItemButton;
