import { TemplateDto } from "./template.dto";

export class TemplateRevisionDto {
    submitTemplateId?: number;
    templateId?: number;
    template?: TemplateDto;
    revision?: number;
    createdAt?: string;
    createdBy?: string;

    constructor(submitTemplateId?: number) {
        this.submitTemplateId = submitTemplateId
    }
}