import { Link as ReactRouterLink, LinkProps } from "react-router-dom";
import { styled } from "@mui/material";

const Link = styled(ReactRouterLink, {
  shouldForwardProp: (prop) => prop !== "open",
})<LinkProps & React.RefAttributes<HTMLAnchorElement>>(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.main,
}));

export default Link;
