import PersonIcon from "@mui/icons-material/Person";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { compose } from "ramda";
import { ChangeEvent, FocusEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FindDataPagination } from "../../../../classes/find-data-pagination.class";
import { ResponsePagination } from "../../../../classes/response-pagination.class";
import { AppRouter, getAppRouterPath } from "../../../../config/AppRouter/AppRouters.menu";
import { NavigateStateType } from "../../../../config/AppRouter/NavigateState";
import { useAxios } from "../../../../contexts/axios.context";
import { useLoading } from "../../../../contexts/loading.context";
import { useScreen } from "../../../../contexts/screen.context";
import { checkStatusBooleanToValue, checkStatusValueToBoolean, stringAvatar } from "../../../../utils/helper";
import AvatarGroup from "../../../Common/AvatarGroup/AvatarGroup";
import DataTable, { Columns } from "../../../Common/DataTable/DataTable";
import FormControl from "../../../Common/FormControl/FormControl";
import Body from "../../../Common/Page/Body/Body";
import Header from "../../../Common/Page/Header/Header";
import { HeaderFilterStatus } from "../../../Common/Page/Header/HeaderFilter";
import RadioGroup from "../../../Common/RadioGroup/RadioGroup";
import withAppRouter, { WithAppRouterProps } from "../../../HoC/withAppRouter";
import { useTemplateOption } from "../../../Template/Template";
import { RoleDto } from "../RoleMgt/dto/role.dto";
import { UserDto } from "../UserMgt/dto/user.dto";
import { RoleCriteriaDto } from "./dto/role-criteria.dto";
import { RoleFilterDto } from "./dto/role-filter.dto";

type RoleMgtProps = WithAppRouterProps & {};
const RoleMgt = ({ pageRouter }: RoleMgtProps) => {
    const { post } = useAxios();
    const { setScreen } = useScreen();
    const templateOption = useTemplateOption();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    const { loading } = useLoading();
    const navigate = useNavigate();
    const initialFiltersMemo: RoleFilterDto = useMemo(() => ({ isActive: "", userExists: "" }), []);
    const [finder, setFinder] = useState<FindDataPagination<RoleCriteriaDto>>(
        new FindDataPagination<RoleCriteriaDto>(undefined, {
            filters: [
                {
                    key: "isActive",
                    label: "Status",
                    value: "",
                    render: (status) => <HeaderFilterStatus status={status.value} />,
                },
                {
                    key: "userExists",
                    label: "User exists",
                    value: "",
                    render: (status) => (
                        <HeaderFilterStatus status={status.value} activeLabel="Only" inactiveLabel="Never" />
                    ),
                },
            ],
        })
    );
    const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
    const [datasource, setDatasource] = useState<Array<RoleDto>>([]);
    const [datasourceIsLoading, setDatasourceIsLoading] = useState<boolean>(false);
    const [totalRecord, setTotalRecord] = useState<number>(0);

    useEffect(() => {
        setScreen({ code: "CSMGT02001", name: "Role Management" });
        return () => setScreen(undefined);
    }, [setScreen]);
    const getRoles = useCallback(async () => {
        setDatasourceIsLoading(true)
        const response = await post<ResponsePagination<Array<RoleDto>>>("/role/findByCriteria", finder);
        setDatasource(response.data.datasource);
        setTotalRecord(response.data.totalRecord);
        setDatasourceIsLoading(false)
    }, [post, finder, setDatasource, setTotalRecord]);
    useEffect(() => {
        getRoles();
    }, [finder, getRoles]);

    const onFilterFormSubmit = (values: RoleFilterDto, { setSubmitting }: FormikHelpers<RoleFilterDto>) => {
        const isActive = checkStatusValueToBoolean(values.isActive);
        const userExists = checkStatusValueToBoolean(values.userExists);
        setFinder((p) => ({
            ...p,
            pagination: { ...p.pagination!, currentPage: 1 },
            criteria: {
                ...p.criteria,
                filters: p.criteria?.filters?.map((filter) => {
                    if (filter.key === "isActive") filter.value = isActive;
                    if (filter.key === "userExists") filter.value = userExists;
                    return filter;
                }),
            },
        }));
        setSubmitting(false);
        setOpenFilterDialog(false);
    };

    const onFilterFormReset = () => {
        const filters = finder.criteria?.filters;
        const captureFilter: RoleFilterDto = {
            ...initialFiltersMemo,
            ...filters?.reduce<RoleFilterDto>(
                (prev, { key, value }) => ({
                    ...prev,
                    [key]: ["isActive", "userExists"].includes(key) ? checkStatusBooleanToValue(value) : value,
                }),
                {}
            ),
        };
        filterFormik.resetForm({ values: captureFilter });
        setOpenFilterDialog(false);
    };

    const filterFormik = useFormik<RoleFilterDto>({
        initialValues: initialFiltersMemo,
        onSubmit: onFilterFormSubmit,
    });

    const columns = useMemo<Columns<RoleDto>>(
        () => [
            {
                dataIndex: "isActive",
                render: (role: RoleDto) => (role.isActive ? "success" : "default"),
                label: "Status",
                exceptMobile: true,
                isCardStatus: true,
                cardStatusLabelRender: (user: RoleDto) => (user.isActive ? "Active" : "Inactive"),
            },
            {
                dataIndex: "name",
                cell: { col: 1, row: 1 },
                label: "Role Name",
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "description",
                cell: { col: 2, row: 1 },
                label: "Description",
                ellipsis: true,
            },
            {
                dataIndex: "users",
                cell: { col: 3, row: 1 },
                label: "Users",
                render: (roleDto: RoleDto) => {
                    return (
                        <AvatarGroup max={5} sx={{ justifyContent: "start" }}>
                            {roleDto.users?.map((user: UserDto, index: number) => (
                                <Avatar key={index} {...stringAvatar(user.fullName!)} src={user.photo} />
                            ))}
                        </AvatarGroup>
                    );
                },
            },
            {
                dataIndex: "actionOnCard",
                callback: (role: RoleDto) => {
                    const { id, name } = role;
                    const navigateStateType: NavigateStateType = {
                        modules: "role",
                        type: "management",
                        screenType: "detail",
                        label: name,
                        update: { canAccess: true, permissions: ["CSMGT02004"] },
                        remove: { canAccess: true, permissions: ["CSMGT02005"] },
                    };
                    const path = getAppRouterPath(navigateStateType);
                    navigate(`${path}${id}`, { state: navigateStateType });
                },
            },
        ],
        [navigate]
    );
    useEffect(() => {
        if (loading) {
        }
    }, [loading]);

    const onSearchString = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setFinder({
            ...finder,
            pagination: { ...finder.pagination!, currentPage: 1 },
            criteria: { ...finder.criteria, searchString: value },
        });
    };

    const onBlur = ({ target: { value } }: FocusEvent<HTMLInputElement>) => {};

    const onPageChange = useCallback(
        (page: number) => setFinder((f) => ({ ...f, pagination: { ...f.pagination!, currentPage: page } })),
        [setFinder]
    );
    const pageRouterMemo: AppRouter | undefined = useMemo(() => {
        if (!pageRouter) return undefined;
        return {
            ...pageRouter,
            search: { canAccess: true, permissions: ["CSMGT02001"] },
            filter: {
                canAccess: true,
                permissions: ["CSMGT02001"],
                onClick: () => setOpenFilterDialog(true),
                filters: finder.criteria?.filters || [],
            },
            create: {
                canAccess: true,
                permissions: ["CSMGT02003"],
                onClick: () => {
                    const navigateStateType: NavigateStateType = {
                        modules: "role",
                        type: "management",
                        screenType: "create",
                        label: "Create Role",
                        create: { canAccess: true, permissions: ["CSMGT02003"] },
                    };
                    const path = getAppRouterPath(navigateStateType);
                    navigate(`${path}`, { state: navigateStateType });
                },
            },
        };
    }, [pageRouter, navigate, finder]);
    return (
        <Fragment>
            <Header
                templateOption={templateOption}
                icon={PersonIcon}
                pageRouter={pageRouterMemo}
                searchProps={{ onChange: onSearchString, onBlur, isSearching: !!finder.criteria?.searchString }}
            />
            <Body templateOption={templateOption}>
                <DataTable<RoleDto>
                    withHeader={!isSM}
                    datasource={datasource}
                    columns={columns}
                    direction="horizontal"
                    loading={datasourceIsLoading}
                    paginationOption={{
                        totalRecord,
                        currentPage: finder.pagination?.currentPage,
                        onChange: (_, page) => onPageChange(page),
                    }}
                />
            </Body>
            <Dialog
                open={openFilterDialog}
                fullScreen={isSM}
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                    },
                }}
                PaperProps={{ sx: { minWidth: !isSM ? 500 : "unset", maxWidth: !isSM ? 500 : "unset" } }}
            >
                <form
                    onSubmit={async (formEvent) => {
                        formEvent.preventDefault();
                        filterFormik.handleSubmit(formEvent);
                    }}
                >
                    <DialogTitle>Filters</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel>Status</FormLabel>
                                    <RadioGroup
                                        row
                                        name="isActive"
                                        value={filterFormik.values.isActive}
                                        onChange={filterFormik.handleChange}
                                    >
                                        <FormControlLabel value="" control={<Radio size="small" />} label="All" />
                                        <FormControlLabel value="A" control={<Radio size="small" />} label="Active" />
                                        <FormControlLabel value="I" control={<Radio size="small" />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel>User exists</FormLabel>
                                    <RadioGroup
                                        row
                                        name="userExists"
                                        value={filterFormik.values.userExists}
                                        onChange={filterFormik.handleChange}
                                    >
                                        <FormControlLabel value="" control={<Radio size="small" />} label="All" />
                                        <FormControlLabel value="A" control={<Radio size="small" />} label="Only" />
                                        <FormControlLabel value="I" control={<Radio size="small" />} label="Never" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onFilterFormReset}>Cancel</Button>
                        <Button type="submit" variant="contained">
                            Confirm
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    );
};

const RoleMgtWrapped = compose(withAppRouter);
export default RoleMgtWrapped(RoleMgt);
