import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, styled, Theme } from "@mui/material/styles";

const drawerWidth = Number(process.env.REACT_APP_DRAWER_WIDTH || 240);

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    backgroundColor: theme.palette.secondary.main,
    borderRightWidth: 0,
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,
    backgroundColor: theme.palette.secondary.main,
    //   width: `calc(${theme.spacing(7)} + 1px)`,
    //   [theme.breakpoints.up("sm")]: {
    //     width: `calc(${theme.spacing(9)} + 1px)`,
    //   },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    // width: theme.breakpoints.down("sm") ? "100%" : drawerWidth,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": { ...openedMixin(theme), boxShadow: "1px 0px 5px 0px rgb(0 0 0 / 40%)" },
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default Drawer;
