import { Card, CardContent, FormHelperText, Grid, Grow, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { FieldArray, FieldArrayRenderProps, FormikHelpers, FormikProps, useFormik } from "formik";
import { useConfirm } from "material-ui-confirm";
import { Fragment, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { AppRouter } from "../../../../../config/AppRouter/AppRouters.menu";
import { useAlert } from "../../../../../contexts/alert.context";
import { useAxios } from "../../../../../contexts/axios.context";
import { useLoading } from "../../../../../contexts/loading.context";
import { Shape } from "../../../../../utils/ValidateUtils";
import FormControl from "../../../../Common/FormControl/FormControl";
import InputLabel from "../../../../Common/InputLabel/InputLabel";
import SourceLoading from "../../../../Common/Loading/Loading";
import Loading from "../../../../Common/Loading/Loading";
import Body from "../../../../Common/Page/Body/Body";
import Header from "../../../../Common/Page/Header/Header";
import Select from "../../../../Common/Select/Select";
import Tab, { a11yProps } from "../../../../Common/Tabs/Tab/Tab";
import TabPanel from "../../../../Common/Tabs/TabPanel/TabPanel";
import Tabs from "../../../../Common/Tabs/Tabs";
import TextField from "../../../../Common/TextField/TextField";
import Typography from "../../../../Common/Typography/Typography";
import withAppRouter from "../../../../HoC/withAppRouter";
import withDatasource, { DatasourceHoCPropsType } from "../../../../HoC/withDatasource";
import { useTemplateOption } from "../../../../Template/Template";
import { TemplateRevisionDto } from "../../Template/dto/template-revision.dto";
import { TemplateDto } from "../../Template/dto/template.dto";
import { ModelConfigDto } from "../dto/model-config.dto";
import { ModelRunnerDto } from "../dto/model-runner.dto";
import { ModelDto } from "../dto/model.dto";

type TemplateSelectElementProps = TemplatesElementProps & {
    template: TemplateDto;
    index: number;
    value: number;
};
const TemplateSelectElement = ({ formik, value, index, template }: TemplateSelectElementProps) => {
    const { post } = useAxios();
    const [datasource, setDatasource] = useState<Array<TemplateRevisionDto>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const getTemplateRevisionOptions = useCallback(
        async (templateId: number, initialDate: string) => {
            setLoading(true);
            const response = await post<Array<TemplateRevisionDto>>("/e2e-template/find/template/revision/options", {
                templateId,
                initialDate,
            });
            setDatasource(response.data);
            setLoading(false);
        },
        [post]
    );
    useMemo(
        () => getTemplateRevisionOptions(template.templateId!, formik.values.initialDate!),
        [formik.values.initialDate, template.templateId, getTemplateRevisionOptions]
    );
    useEffect(() => {
        if (!value && !loading && datasource.length) {
            const defaultValue = datasource[0].submitTemplateId!;
            formik.setFieldValue(`templatesSelectedIds.${index}`, defaultValue);
        }
    }, [datasource, value, formik, index, loading]);

    return loading ? (
        <Box sx={{ height: 41, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography>Loading...</Typography>
        </Box>
    ) : datasource.length ? (
        <Select
            size="small"
            fullWidth
            value={value}
            onChange={({ target: { value } }) => formik.setFieldValue(`templatesSelectedIds.${index}`, value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            id={`templatesSelectedIds.${index}`}
            name={`templatesSelectedIds.${index}`}
            disabled={loading}
        >
            {datasource.map((source) => (
                <MenuItem value={source.submitTemplateId} key={source.submitTemplateId}>
                    <Typography>
                        <b>Revision {source.revision}</b>
                        {` - ${source.createdBy} - ${dayjs(source.createdAt).format("DD/MM/YYYY HH:mm")}`}
                    </Typography>
                </MenuItem>
            ))}
        </Select>
    ) : (
        <Box sx={{ height: 41, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography>No Data</Typography>
        </Box>
    );
};
type TemplatesElementProps = {
    arrayHelpers: FieldArrayRenderProps;
    formik: FormikProps<ModelRunnerDto>;
};
const TemplatesElement = ({ modelConfig, ...props }: TemplatesElementProps & { modelConfig: ModelConfigDto }) => {
    return (
        <Fragment>
            {modelConfig?.templates?.map((template: TemplateDto, index: number) => (
                <Grow in key={index} timeout={index * 400}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent sx={{ padding: "16px !important" }}>
                                <Grid container>
                                    <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography>{template.templateName}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl
                                            fullWidth
                                            size="small"
                                            sx={{ display: "flex", alignItems: "center", minWidth: 120 }}
                                        >
                                            <TemplateSelectElement
                                                {...props}
                                                index={index}
                                                value={props.formik.values.templatesSelectedIds[index]}
                                                template={template}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grow>
            ))}
        </Fragment>
    );
};

type ModelRunnerDetailProps = DatasourceHoCPropsType<ModelRunnerDto> & {
    datasource?: ModelRunnerDto;
    isCreate?: boolean;
};

const ModelRunner = ({ pageRouter }: ModelRunnerDetailProps) => {
    const templateOption = useTemplateOption();
    const navigate = useNavigate();
    const { setAlert } = useAlert();
    const { loading } = useLoading();
    const { post, get } = useAxios();
    const [value, setValue] = useState(0);
    const confirm = useConfirm();

    const [initialDate, setInitialDate] = useState<Dayjs | null>(null);
    const [modelConfig, setModelConfig] = useState<ModelConfigDto | null>(null);
    const [modelConfigIsLoading, setModelConfigIsLoading] = useState<boolean>(false);

    const [modelOptions, setModelOptions] = useState<Array<ModelDto>>([]);
    const [modelOptionsIsLoading, setModelOptionsIsLoading] = useState<boolean>(false);
    const getModelOptions = useCallback(async () => {
        setModelOptionsIsLoading(true);
        const response = await post<Array<ModelDto>>("/e2e-model/find/model/options");
        setModelOptions(response.data);
        setModelOptionsIsLoading(false);
    }, [post, setModelOptionsIsLoading, setModelOptions]);

    useMemo(() => getModelOptions(), [getModelOptions]);
    useEffect(() => {
        if (!loading) {
        }
    }, [loading]);

    const onFormSubmit = async (
        values: ModelRunnerDto,
        { setSubmitting, setTouched }: FormikHelpers<ModelRunnerDto>
    ) => {
        setTouched({ initialDate: true });
        setTouched({ templatesSelectedIds: true });
        const { modelId, initialDate, templatesSelectedIds } = values;
        const params = new ModelRunnerDto();
        params.model = modelOptions.find((option) => option.modelId === modelId);
        params.initialDate = initialDate;
        params.templatesSelected = templatesSelectedIds.map((id) => new TemplateRevisionDto(id));
        confirm({ title: "Request Model Runner", description: "Are you sure?" })
            .then(async () => {
                setSubmitting(true);
                await post("/e2e-model/request/model/runner", params);
                setSubmitting(false);
                setAlert({ status: "success", statusCode: 200, message: "Request Model Runner Success!" });
                navigate("/e2e/model", { replace: true });
            })
            .catch((error) => {
                setSubmitting(false);
            });
    };

    const validationSchema = yup.object<Shape<ModelRunnerDto>>({
        modelId: yup.number().required("Please select model type"),
        initialDate: yup.string().nullable(true).required("Please select initial date"),
        templatesSelectedIds: yup.array().test({
            message: "Please select a valid template",
            test: (ids) => ids?.every((id) => Number(id) > 0) === true,
        }),
    });
    const formik = useFormik<ModelRunnerDto>({
        initialValues: {
            modelId: "",
            initialDate: null,
            templatesSelectedIds: [],
            templatesSelected: [],
            templates: [],
        },
        onSubmit: onFormSubmit,
        validationSchema,
    });
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const pageRouterMemo: AppRouter | undefined = useMemo(() => {
        if (pageRouter) {
            return {
                ...pageRouter,
            };
        }
    }, [pageRouter]);

    return (
        <Fragment>
            <form
                onSubmit={async (formEvent) => {
                    formEvent.preventDefault();
                    formik.handleSubmit(formEvent);
                }}
            >
                <Header
                    withHeaderContent
                    withManagementTools
                    templateOption={templateOption}
                    pageRouter={pageRouterMemo}
                    top="16px"
                    loading={formik.isSubmitting}
                />
                <Body templateOption={templateOption}>
                    <Fragment>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="model runner details"
                            sx={{ borderBottom: 1, borderColor: "divider" }}
                        >
                            <Tab label="Detail" {...a11yProps("model", 0)} />
                        </Tabs>

                        <TabPanel value={value} index={0} modules="model">
                            {formik.isSubmitting || modelOptionsIsLoading ? (
                                <Loading />
                            ) : (
                                <Fragment>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} justifyContent="center">
                                            <FormControl
                                                fullWidth
                                                size="small"
                                                error={formik.touched.modelId && Boolean(formik.errors.modelId)}
                                            >
                                                <InputLabel id="modelId">Model Type</InputLabel>
                                                <Select
                                                    labelId="modelId"
                                                    id="modelId"
                                                    name="modelId"
                                                    value={formik.values.modelId}
                                                    label="Model Type"
                                                    onChange={async ({ target: { value } }) => {
                                                        await formik.setFieldValue("modelId", value);
                                                        setModelConfigIsLoading(true);
                                                        const response = await get<ModelConfigDto>(
                                                            `/e2e-model/get/model/config/${value}`
                                                        );
                                                        setModelConfig(response.data);
                                                        setModelConfigIsLoading(false);
                                                        formik.setFieldValue(
                                                            "templatesSelectedIds",
                                                            response.data.templates.flatMap(() => 0)
                                                        );
                                                    }}
                                                >
                                                    {modelOptions.map((option) => (
                                                        <MenuItem value={option.modelId} key={option.modelId}>
                                                            {option.modelName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {formik.touched.modelId && formik.errors.modelId && (
                                                    <FormHelperText>
                                                        {formik.touched.modelId && formik.errors.modelId}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth size="small">
                                                <MobileDatePicker
                                                    loading={formik.isSubmitting}
                                                    label="Initial Date"
                                                    inputFormat="DD/MM/YYYY"
                                                    value={initialDate}
                                                    onAccept={async (newValue: Dayjs | null) => {
                                                        setInitialDate(newValue);
                                                        await formik.setFieldValue(
                                                            "initialDate",
                                                            newValue?.format("YYYY-MM-DD")
                                                        );
                                                    }}
                                                    onClose={() =>
                                                        setInitialDate(
                                                            dayjs(formik.values.initialDate, "YYYY-MM-DD", true) || null
                                                        )
                                                    }
                                                    onChange={(newValue: Dayjs | null) => setInitialDate(newValue)}
                                                    disabled={formik.isSubmitting}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            size="small"
                                                            error={
                                                                formik.touched.initialDate &&
                                                                Boolean(formik.errors.initialDate)
                                                            }
                                                            helperText={
                                                                formik.touched.initialDate && formik.errors.initialDate
                                                            }
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {modelConfigIsLoading && formik.values.initialDate ? (
                                            <SourceLoading />
                                        ) : (
                                            modelConfig?.templates?.length &&
                                            formik.values.initialDate && (
                                                <Grid item xs={12}>
                                                    <FieldArray
                                                        validateOnChange={false}
                                                        name="templatesSelectedIds"
                                                        render={(arrayHelpers: FieldArrayRenderProps) => (
                                                            <Grid container spacing={2}>
                                                                <TemplatesElement
                                                                    formik={formik}
                                                                    modelConfig={modelConfig}
                                                                    arrayHelpers={arrayHelpers}
                                                                />
                                                            </Grid>
                                                        )}
                                                    />
                                                    {formik.touched.templatesSelectedIds &&
                                                        formik.errors.templatesSelectedIds && (
                                                            <FormHelperText>
                                                                {formik.touched.templatesSelectedIds &&
                                                                    formik.errors.templatesSelectedIds}
                                                            </FormHelperText>
                                                        )}
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                </Fragment>
                            )}
                        </TabPanel>
                    </Fragment>
                </Body>
            </form>
        </Fragment>
    );
};

export default withAppRouter(withDatasource({ url: "", navigateModule: "model", navigateType: "e2e" })(ModelRunner));
