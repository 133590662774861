import { ComponentType } from "react";
import ProtectedRoute from "../../components/Common/ProtectedRoute/ProtectedRoute";
import Model from "../../components/pages/E2E Function/Model/Model";
import ModelRunner from "../../components/pages/E2E Function/Model/runner/ModelRunner";
import Template from "../../components/pages/E2E Function/Template/Template";
import RoleMgtDetail from "../../components/pages/Management/RoleMgt/management/RoleMgt.detail";
import RoleMgt from "../../components/pages/Management/RoleMgt/RoleMgt";
import UserMgtDetail from "../../components/pages/Management/UserMgt/management/UserMgt.detail";
import UserMgt from "../../components/pages/Management/UserMgt/UserMgt";
import { Permissions } from "../../contexts/authorize.context";
import { NavigateStateType } from "./NavigateState";

export type ScreenType = "main" | "detail" | "create";

/**
 * 
    @type {AppRouter} - {RouterIcon}
    example:
    RouterIcon: ({ open }: RouterType) => (
        <ListItemIcon open={open}>
            <HomeIcon color="primary" />
        </ListItemIcon>
    ),
 */
export type AppRouter = NavigateStateType & {
    thumbsLabel?: string;
    to: string;
    path: string;
    RouterIcon?: ComponentType<RouterType>;
    testId: string;
    displayOnMenu?: boolean;
    Component: JSX.Element;
    permissions?: Permissions;
};

export type RouterType = {
    open?: boolean;
};

export const AppRouters: Array<AppRouter> = [
    {
        type: "management",
        modules: "user",
        screenType: "main",
        label: "User Management",
        thumbsLabel: "Users",
        to: "/management/user",
        path: "/management/user",
        testId: "test-nav-user-mgt",
        permissions: "CSMGT01001",
        displayOnMenu: true,
        Component: (
            <ProtectedRoute permissions={"CSMGT01001"}>
                <UserMgt />
            </ProtectedRoute>
        ),
    },
    {
        type: "management",
        modules: "user",
        screenType: "detail",
        to: "/management/user/detail/:id",
        path: "/management/user/detail/",
        testId: "test-nav-user-mgt-detail",
        displayOnMenu: false,
        permissions: "CSMGT01002",
        Component: <UserMgtDetail />,
    },
    {
        type: "management",
        modules: "user",
        screenType: "create",
        to: "/management/user/create",
        path: "/management/user/create",
        testId: "test-nav-user-mgt-create-detail",
        displayOnMenu: false,
        permissions: "CSMGT01003",
        Component: <UserMgtDetail />,
    },
    {
        type: "management",
        modules: "role",
        screenType: "main",
        label: "Role Management",
        thumbsLabel: "Roles",
        to: "/management/role",
        path: "/management/role",
        testId: "test-nav-role-mgt",
        permissions: "CSMGT02001",
        Component: <RoleMgt />,
        displayOnMenu: true,
    },
    {
        type: "management",
        modules: "role",
        screenType: "detail",
        to: "/management/role/detail/:id",
        path: "/management/role/detail/",
        testId: "test-nav-role-mgt-detail",
        displayOnMenu: false,
        permissions: "CSMGT02002",
        Component: <RoleMgtDetail />,
    },
    {
        type: "management",
        modules: "role",
        screenType: "create",
        to: "/management/role/create",
        path: "/management/role/create",
        testId: "test-nav-role-mgt-create-detail",
        displayOnMenu: false,
        permissions: "CSMGT02003",
        Component: <RoleMgtDetail />,
    },
    {
        type: "e2e",
        modules: "template",
        screenType: "main",
        label: "Template",
        thumbsLabel: "Template",
        to: "/e2e/template",
        path: "/e2e/template",
        testId: "test-nav-e2e-template",
        permissions: "DSMGT01001",
        Component: (
            <ProtectedRoute permissions={"DSMGT01001"}>
                <Template />
            </ProtectedRoute>
        ),
        displayOnMenu: true,
    },
    {
        type: "e2e",
        modules: "model",
        screenType: "main",
        label: "Model",
        thumbsLabel: "Model",
        to: "/e2e/model",
        path: "/e2e/model",
        testId: "test-nav-e2e-model",
        permissions: "DSMGT02001",
        Component: (
            <ProtectedRoute permissions={"DSMGT02001"}>
                <Model />
            </ProtectedRoute>
        ),
        displayOnMenu: true,
    },
    {
        type: "e2e",
        modules: "model",
        screenType: "create",
        to: "/e2e/model/runner",
        path: "/e2e/model/runner",
        testId: "test-nav-e2e-model-runner",
        permissions: "DSDRQ02008",
        Component: (
            <ProtectedRoute permissions={"DSDRQ02008"}>
                <ModelRunner />
            </ProtectedRoute>
        ),
        displayOnMenu: false,
    },
];
const strCompareEqual = (a: string, b: string): boolean => a === b;
export const getAppRouter = (navigateStateType: NavigateStateType) => {
    return AppRouters.find(
        ({ type, screenType, modules }) =>
            strCompareEqual(type, navigateStateType.type) &&
            strCompareEqual(screenType, navigateStateType.screenType) &&
            strCompareEqual(modules, navigateStateType.modules)
    );
};
export const getAppRouterPath = (navigateStateType: NavigateStateType) => {
    const appRouter: AppRouter | undefined = getAppRouter(navigateStateType);
    if (!appRouter) throw new Error("AppRouter not found, Hint recheck navigate state type and AppRouters.");
    return appRouter.path;
};
