import {
    Box,
    BoxProps,
    Card,
    Collapse,
    Divider,
    Fade,
    Slide,
    Stack,
    StandardTextFieldProps,
    SvgIconTypeMap,
    Typography,
    useMediaQuery,
    useScrollTrigger,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { CSSObject, duration, styled, Theme, useTheme } from "@mui/material/styles";
import { Fragment, PropsWithChildren, useMemo } from "react";
import { AppRouter } from "../../../../config/AppRouter/AppRouters.menu";
import { TemplateOption } from "../../../Template/Template";
import ProtectedComponent from "../../ProtectedComponent/ProtectedComponent";
import HeaderCard from "./HeaderCard";
import HeaderCreate from "./HeaderCreate";
import HeaderFilter from "./HeaderFilter";
import HeaderManagement from "./HeaderManagement";
import HeaderSearch from "./HeaderSearch";

const responsiveContainer = (theme: Theme): CSSObject => ({
    padding: 3,
    // paddingTop: "64px",
    // [theme.breakpoints.down("sm")]: {
    //     paddingTop: "56px",
    // },
});

interface HeaderContentContainerProps extends BoxProps {
    media?: "sm";
}

const HeaderContentContainerStyled = styled(Box)<HeaderContentContainerProps>(({ theme, media }) => ({
    ...responsiveContainer(theme),
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    height: 150,
    ...(media === "sm" && {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "100vw",
    }),
}));

interface HeaderManagementContainerProps extends BoxProps {}

const HeaderManagementContainerStyled = styled(Box)<HeaderManagementContainerProps>(({ theme }) => ({
    ...responsiveContainer(theme),
    position: "absolute",
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    top: 56,
    right: 0,
    height: 150,
}));

interface HeaderSearchContainerProps extends BoxProps {}

const HeaderSearchContainerStyled = styled(Box)<HeaderSearchContainerProps>(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    margin: "0px 32px 0px 32px",
    height: 150,
}));
const HeaderSearchCardStyled = styled(Card)(({ theme }) => ({
    width: "100%",
    padding: 6,
}));
type TitleContentContainerProps = {
    media?: "sm";
};

const TypographyStyled = styled(Typography)<TitleContentContainerProps>(({ theme, media }) => ({
    display: "flex",
    fontSize: theme.typography.h4.fontSize,
    alignItems: "center",
    "& label": {
        paddingLeft: 6,
        ...(media === "sm" && {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "80vw",
        }),
    },
    "& .MuiSvgIcon-root": {
        fontSize: theme.typography.h4.fontSize,
    },
}));
export type SearchProps = {
    isSearching?: boolean;
    onChange?: StandardTextFieldProps["onChange"];
    onBlur?: StandardTextFieldProps["onBlur"];
};

type HeaderProps = {
    templateOption: TemplateOption;
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    withSearch?: boolean;
    pageRouter?: AppRouter;
    searchProps?: SearchProps;
    top?: string;
    loading?: boolean;
    withHeaderContent?: boolean;
    withManagementTools?: boolean;
};
const ShowOnScroll = ({ children }: PropsWithChildren<{}>) => {
    const trigger = useScrollTrigger({ disableHysteresis: true });
    const theme = useTheme();
    return (
        <Slide in={trigger}>
            <div
                style={{
                    width: "100%",
                    position: "fixed",
                    top: 64,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    zIndex: 99,
                }}
            >
                {children}
            </div>
        </Slide>
    );
};
const Header = ({
    icon: Icon,
    templateOption,
    pageRouter,
    searchProps,
    top,
    loading,
    withHeaderContent,
    withManagementTools,
}: HeaderProps) => {
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    const searchMemo = useMemo(() => pageRouter?.search, [pageRouter]);
    const createMemo = useMemo(() => pageRouter?.create, [pageRouter]);
    const updateMemo = useMemo(() => pageRouter?.update, [pageRouter]);
    const filterMemo = useMemo(() => pageRouter?.filter, [pageRouter]);

    return (
        <Fragment>
            <ShowOnScroll />
            {withHeaderContent && (
                <>
                    <HeaderCard templateOption={templateOption} top={top} />
                    <HeaderContentContainerStyled sx={{ paddingLeft: 4 }} media={isSM ? "sm" : undefined}>
                        <TypographyStyled media={isSM ? "sm" : undefined}>
                            {Icon && <Icon />}
                            <label>{isSM ? pageRouter?.thumbsLabel || pageRouter?.label : pageRouter?.label}</label>
                        </TypographyStyled>
                    </HeaderContentContainerStyled>
                </>
            )}
            {searchMemo?.canAccess && (
                <ProtectedComponent permissions={searchMemo?.permissions}>
                    <Fade in timeout={{ enter: 600, exit: duration.leavingScreen, appear: duration.standard }}>
                        <Fade in>
                            <HeaderSearchContainerStyled>
                                <HeaderSearchCardStyled elevation={3}>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <ProtectedComponent permissions={searchMemo?.permissions}>
                                            <HeaderSearch open={templateOption.open} {...searchProps} />
                                        </ProtectedComponent>
                                        {createMemo?.canAccess && (
                                            <ProtectedComponent permissions={createMemo?.permissions}>
                                                <HeaderCreate open={templateOption.open} pageRouter={pageRouter} />
                                            </ProtectedComponent>
                                        )}
                                    </Stack>
                                    {filterMemo?.canAccess && (
                                        <ProtectedComponent permissions={filterMemo?.permissions}>
                                            <>
                                                <Divider sx={{ margin: "4px 0px 4px 0px" }} />
                                                <Collapse in={true} timeout="auto" unmountOnExit>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <HeaderFilter
                                                            open={templateOption.open}
                                                            pageRouter={pageRouter}
                                                            filters={filterMemo.filters}
                                                        />
                                                    </Stack>
                                                </Collapse>
                                            </>
                                        </ProtectedComponent>
                                    )}
                                </HeaderSearchCardStyled>
                            </HeaderSearchContainerStyled>
                        </Fade>
                    </Fade>
                </ProtectedComponent>
            )}
            {withManagementTools && (
                <HeaderManagementContainerStyled sx={{ paddingRight: 4 }}>
                    {updateMemo?.canAccess && (
                        <ProtectedComponent permissions={updateMemo?.permissions}>
                            <HeaderManagement
                                open={templateOption.open}
                                loading={loading}
                                pageRouter={pageRouter}
                                isSM={isSM}
                            />
                        </ProtectedComponent>
                    )}
                    {createMemo?.canAccess && (
                        <ProtectedComponent permissions={createMemo?.permissions}>
                            <HeaderManagement
                                open={templateOption.open}
                                loading={loading}
                                pageRouter={pageRouter}
                                isSM={isSM}
                            />
                        </ProtectedComponent>
                    )}
                </HeaderManagementContainerStyled>
            )}
        </Fragment>
    );
};

export default Header;
