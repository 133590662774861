import { styled, Tabs as MuiTabs, TabsProps as MuiTabsProps } from "@mui/material";
import "./tabs.css";

const Tabs = styled(
    MuiTabs,
    {}
)<MuiTabsProps>(({ theme }) => ({
    borderTop: "2px solid",
    borderTopColor: `${theme.palette.primary.main} !important`,
    // borderRight: "2px solid",
    // borderRightColor: `${theme.palette.primary.main} !important`,
    // borderTopLeftRadius: 5,
    // borderTopRightRadius: 5,
}));

export default Tabs;
