import { styled, Typography as MuiTypography, TypographyProps as MuiTypographyProps } from "@mui/material";
import { useMemo } from "react";

type TypographyType = MuiTypographyProps & {
    ellipsis?: boolean | "withEllipsis";
};
const TypographyStyled = styled(
    MuiTypography,
    {}
)<TypographyType>(({ theme, ellipsis }) => ({
    ...(ellipsis === "withEllipsis" && {
        // textOverflow: "ellipsis",
        // overflow: "hidden",
        // whiteSpace: "nowrap",
        // width: "200px",
    }),
}));

const Typography = ({ children, ellipsis, ...props }: TypographyType) => {
    const ellipsisMemo = useMemo(() => ellipsis && "withEllipsis", [ellipsis]);
    return (
        <TypographyStyled ellipsis={ellipsisMemo} {...props}>
            {children}
        </TypographyStyled>
    );
};

export default Typography;
