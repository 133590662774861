import { TemplateRevisionDto } from "../../Template/dto/template-revision.dto";
import { TemplateDto } from "../../Template/dto/template.dto";
import { ModelDto } from "./model.dto";

export class ModelRunnerDto {
    modelId?: number | "";
    model?: ModelDto;
    initialDate?: string | null;
    templatesSelectedIds: Array<number> = [];
    templatesSelected?: Array<TemplateRevisionDto>;
    templates?: Array<TemplateDto>;
}